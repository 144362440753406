import {action, computed, observable} from 'mobx';

class ErrorStore {
  @observable errors: string[] = [];

  @computed get hasErrors() {
    return this.errors.length > 0;
  }

  @action
  addError(msg: string) {
    this.errors.push(msg);
  }
}

export const errorStore: ErrorStore = new ErrorStore();
