import * as React from "react";
import {ReactElement, ReactNode, useContext, useEffect, useState} from "react";
import {WebpushSubscription} from "../api/notificationTokenTypes";
import {registerWebpush} from "../util/registerWebpush";
import * as Sentry from "@sentry/browser";
import * as androidApi from '../androidApi';
import {ServiceWorkerContext} from "./ServiceWorkerContext";

export const WebpushContext = React.createContext<WebpushSubscription | null>(null);

export const WebpushContextProvider: React.FC = (props: { children?: ReactNode }): ReactElement | null => {
  const serviceWorkerRegistration: ServiceWorkerRegistration | null = useContext(ServiceWorkerContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [webpush, setWebpush] = useState<WebpushSubscription | null>(null);
  useEffect(() => {
    if (!serviceWorkerRegistration) {
      // When no service worker was registered, webpush does not make sense because notifications won't be received
      console.info("Skipping webpush registration, because serviceworker is unavailable.");
      setLoading(false);
      return;
    }
    if (androidApi.getAndroidApi()) {
      // When on Android, will use native firebase push service
      setLoading(false);
      return;
    }
    registerWebpush(serviceWorkerRegistration).then(subscription => {
      if (subscription !== null) {
        console.info(`Registered WebPush`);
        setWebpush(subscription);
        Sentry.configureScope(scope => {
          scope.setTag("push_mode", "webpush");
        });
      } else {
        console.info(`WebPush not available`);
      }
      setLoading(false);
    }).catch(reason => {
      console.warn(`Error while registering WebPush: ${reason}`);
      setLoading(false);
    });
  }, []);
  // While still loading, don't render the children
  // That way any children of `<WebpushContextProvider>` know if web push was registered (relevant for PollingHandler)
  if (loading) return null;
  else return (
    <WebpushContext.Provider value={webpush}>
      {props.children}
    </WebpushContext.Provider>);
};
