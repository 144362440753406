import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import * as React from "react";

export function NoMoreUsersDialog(props: {
  open: boolean,
  onClose(): void
}) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>Too many users!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          There cannot be more than 4 users in a call!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
