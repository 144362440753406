import {EntityRef, HasLinks} from './restApi';
import {Client} from './clientTypes';
import {FileDto} from './fileApi';
import {User} from "./userTypes";

export interface ItemRequest {
  client: EntityRef;
  name: string;
  description: string;
  supportAgent: EntityRef;
  imageId: number | null;
  attachments: FileDto[];
  internalNote: string | null;
}

export interface Item extends ItemRequest, HasLinks {
  id: number;
  client: Client;
  supportAgent: User;
  itemCode: string;
  lastActivity: string;
  openSupportCases: number;
  missedCalls: number;
  attachments: FileDto[];
  _links: {
    self: string;
    image: string | null;
    qrImage: string;
    qrImageDownload: string;
    qrImageDownloadPdf: string;
  };
  internalNote: string | null;
}

export function emptyItemRequest(): ItemRequest {
  return {
    client: {
      id: 0,
    },
    name: '',
    description: '',
    supportAgent: {
      id: 0,
    },
    imageId: null,
    attachments: [],
    internalNote: null
  };
}
