import {closeStream} from "./MediaStreamUtils";

type Props = {
  onMediaStreamChange(stream?: MediaStream): void,
  onFailedToAccessMediaDevice(error: any): void,
}

export class LocalStreamHelper {

  private props: Props;

  private selectedDeviceIndex = 0;

  private _devices: MediaDeviceInfo[] = [];
  public get devices() {
    return this._devices;
  }

  private _stream?: MediaStream;
  public get stream() {
    return this._stream;
  }

  public set stream(stream: MediaStream | undefined) {
    this._stream = stream;
    this.props.onMediaStreamChange(stream);
  }

  private _audioEnabled = true;
  public get audioEnabled() {
    return this._audioEnabled;
  }

  public set audioEnabled(value: boolean) {
    this._audioEnabled = value;
    if (this.stream) {
      this.stream.getAudioTracks().forEach(t => t.enabled = value);
    }
  }

  private _videoEnabled = true;
  public get videoEnabled() {
    return this._videoEnabled;
  }

  public set videoEnabled(value: boolean) {
    this._videoEnabled = value;
    if (this.stream) {
      this.stream.getVideoTracks().forEach(t => t.enabled = value);
    }
  }

  private _closed = false;
  public get closed() {
    return this._closed;
  }

  private _screenShareEnabled = false;
  public get screenShareEnabled() {
    return this._screenShareEnabled;
  }
  public set screenShareEnabled(value: boolean) {
    if (this._screenShareEnabled === value) return;
    this._screenShareEnabled = value
    this.updateMediaStream().catch(error => this.props.onFailedToAccessMediaDevice(error))
  }

  constructor(props: Props) {
    this.props = props;
    navigator.mediaDevices.enumerateDevices()
      .then(devices => this._devices = devices.filter(device => device.kind == 'videoinput'))
      .then(() => this._devices.forEach((device, i) => console.log(`Device ${i}: `, device.label)))
      .then(() => this.updateMediaStream())
      .catch(error => this.props.onFailedToAccessMediaDevice(error))
  }

  /**
   * Checks if the current video track is most likely from a front facing camera.
   */
  public currentVideoTrackIsMostLikelyFrontFacing() {
    if (this._screenShareEnabled) {
      // A screen share is never front facing and should not be mirrored
      return false;
    }
    if (this._stream && this._stream.getVideoTracks().length > 0 && this._stream.getVideoTracks()[0].getCapabilities) {
      const facingMode = this._stream.getVideoTracks()[0].getCapabilities().facingMode;
      if (facingMode != undefined && typeof (facingMode as any) === 'string') {
        if (facingMode.length != 0) {
          return facingMode[0] === 'user';
        }
      }
    }
    // You might think, that it is very easy to check which facing mode a video track has, but you thought wrong!
    // While in theory, you can just check the facingMode of MediaTrack#getCapabilities(), this does not work in
    // any major browser (it returns an empty array). As the first device is usually the front facing camera, we can use
    // this for now. Let's hope, that there will be better options in the future and the above check will work.
    const {_devices, selectedDeviceIndex} = this;
    return selectedDeviceIndex % _devices.length == 0;
  }

  /**
   * Switches to the next available video track.
   */
  public switchToNextVideoTrack() {
    this.selectedDeviceIndex++;
    this.updateMediaStream();
  }

  /**
   * Should be called when this class is no longer needed.
   */
  public close() {
    this._closed = true;
    this.stream = closeStream(this.stream);
  }

  private async updateMediaStream() {
    const {_devices, selectedDeviceIndex} = this;
    if (_devices.length <= 0) {
      return;
    }

    let stream: MediaStream;
    try {
      if(this._screenShareEnabled) {
        // @ts-ignore
        stream = await navigator.mediaDevices.getDisplayMedia();

        // Add a listener when the user clicks on the "cancel sharing" button
        stream.getVideoTracks()[0].onended = () => this.screenShareEnabled = false;

        /**
         * The getDisplayMedia() MediaStream does not have an audio track.
         * That's why we have to get the audio track from the getUserMedia() method and add it to the Media Stream
         */
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        });
        audioStream.getAudioTracks().forEach(audioTrack => stream.addTrack(audioTrack))
      } else {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            width: 640,
            height: 480,
            deviceId: {exact: _devices[selectedDeviceIndex % _devices.length].deviceId}
          }
        });
      }
      console.log(selectedDeviceIndex)
      stream.getVideoTracks().forEach((track, i) => {
        console.log(`Local video track ${i} settings: `, track.getSettings());
      });
    } catch (error) {
      this.screenShareEnabled = false;
      this.props.onFailedToAccessMediaDevice(error);
      return;
    }

    if (this.closed) {
      this.stream = closeStream(stream);
    } else {
      stream.getAudioTracks().forEach(track => track.enabled = this.audioEnabled);
      stream.getVideoTracks().forEach(track => track.enabled = this.videoEnabled);
      // Close the previous stream if there was one
      closeStream(this.stream);
      this.stream = stream;
    }
  }

}
