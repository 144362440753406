import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import {IObservableObject, IObservableValue} from 'mobx';
import {SupportAgentRequest, User} from '../../../api/userTypes';
import {observer} from 'mobx-react';
import {FieldError} from '../../../api/restApi';
import {createPicker} from '../../../util/picker';
import {renderRemainingErrors, textFieldErrorProps} from '../../../util/formUtils';
import ImageInputForSubject from '../../../components/ImageInputForSubject';
import {WithStyles} from '@material-ui/core';
import {MyDialogStyles} from '../../../styles/myDialogStyles';

type Props = {
  user: SupportAgentRequest & IObservableObject;
  /** The existing user to be edited. Is undefined when creating a new one. */
  existingUser?: User;
  dirtyState: IObservableValue<boolean>;
  inProgressCounter: IObservableValue<number>;
  fieldErrors: FieldError[];
} & WithStyles<MyDialogStyles>;

@observer
export class UserForm extends React.Component<Props> {
  render() {
    const errorPicker = createPicker(this.props.fieldErrors);
    return (
      <>
        <ImageInputForSubject
          classes={{root: this.props.classes.imageInput}}
          dirtyState={this.props.dirtyState}
          inProgressCounter={this.props.inProgressCounter}
          newSubject={this.props.user}
          existingSubject={this.props.existingUser}
        />
        <div className={this.props.classes.nextToImageInput}>
          <TextField
            id="userForm_input_firstName"
            className={this.props.classes.noMarginTop}
            label="First name"
            margin="dense"
            fullWidth={true}
            value={this.props.user.firstName}
            onChange={ev => {
              this.props.user.firstName = ev.target.value;
              this.props.dirtyState.set(true);
            }}
            required={true}
            {...textFieldErrorProps(errorPicker.pick(e => e.field === 'firstName'))}
          />
          <TextField
            id="userForm_input_lastName"
            label="Last name"
            margin="dense"
            fullWidth={true}
            value={this.props.user.lastName}
            onChange={ev => {
              this.props.user.lastName = ev.target.value;
              this.props.dirtyState.set(true);
            }}
            required={true}
            {...textFieldErrorProps(errorPicker.pick(e => e.field === 'lastName'))}
          />
        </div>
        <TextField
          id="userForm_input_phone"
          label="Phone"
          margin="dense"
          fullWidth={true}
          value={this.props.user.phone}
          onChange={ev => {
            this.props.user.phone = ev.target.value;
            this.props.dirtyState.set(true);
          }}
          {...textFieldErrorProps(errorPicker.pick(e => e.field === 'phone'))}
        />
        <TextField
          id="userForm_input_email"
          label="E-mail"
          // type="email" (Commented out due to REM-641 and REM-642)
          margin="dense"
          fullWidth={true}
          value={this.props.user.email}
          onChange={ev => {
            this.props.user.email = ev.target.value;
            this.props.dirtyState.set(true);
          }}
          required={true}
          {...textFieldErrorProps(errorPicker.pick(e => e.field === 'email'))}
        />
        {renderRemainingErrors(errorPicker.remaining())}
      </>
    );
  }
}
