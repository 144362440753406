export const SHOW_WAVES_BACKGROUND = true;

export const PRIMARY_COLOR = '#31EE9B';
export const PRIMARY_COLOR_CONTRAST_TEXT = '#434343';
export const SECONDARY_COLOR = '#31EE9B';
export const SECONDARY_COLOR_CONTRAST_TEXT = '#434343';

export const TEXT_COLOR = '#434343';

export const GRADIENT_1 = '#74F3BA';
export const GRADIENT_2 = '#48BBFF';

export const LANDING_PAGE_BACKGROUND_COLOR = '#9e9e9e';
export const CALL_PAGE_BACKGROUND_COLOR = '#303030';
