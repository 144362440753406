import * as React from 'react';
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import Typography from "@material-ui/core/Typography/Typography";
import {makeStyles, useTheme} from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme:Theme) => ({
  card: {
    display: 'flex',
    height: 110,
    [theme.breakpoints.down('xs')]: {
      height: 59,
    }
  },
  image: {
    minWidth: 80,
    maxWidth: 80,
    height: 80,
    margin: 15,
    marginRight: 0,
    [theme.breakpoints.down('xs')]: {
      minWidth: 45,
      maxWidth: 45,
      height: 45,
      margin: 7,
    }
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: 0
    }
  },
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    }
  }
}));

export function InfoCard(props: {
  title: string,
  subtitle: string,
  imageUrl: string,
  className?: string,
  style?: React.CSSProperties,
}) {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={props.className} style={props.style}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.image}
          image={props.imageUrl}
        />
        <div>
          <CardContent className={classes.cardContent}>
            <Typography variant={isDownXs ? 'h6' : 'h5'} noWrap={true}>
              {props.title}
            </Typography>
            <Typography variant={isDownXs ? 'subtitle2' : 'subtitle1'} color="textSecondary">
              {props.subtitle}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </div>
  );
}
