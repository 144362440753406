import Immutable from 'immutable';
import {Container} from "unstated";

/*
 * Take a look at the CallStateContainer for the logic.
 * This container only exists for performance reasons, as it allows you to subscribe to the frequently
 * updated lines and markers independently from the call state and thus prevent unnecessary renders.
 */

type DrawingAndMarkerState = {
  lines: Immutable.Map<string, { // The key is the line id
    senderSpreedId: string,
    timestamp: number,
    points: Array<{ x: number, y: number }>,
    color: string,
    id: string
  }>,
  markers: Immutable.Map<string, { // The key is the userSpreedId
    senderSpreedId: string,
    expiresTimestamp: number
    x: number,
    y: number,
    color: string
  }>
}

export class DrawingAndMarkerStateContainer extends Container<DrawingAndMarkerState> {

  constructor() {
    super();
    this.state = {
      lines: Immutable.Map(),
      markers: Immutable.Map(),
    };
  }

}
