import * as React from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import {CHAT_WINDOW_HEADER_HEIGHT, CHAT_WINDOW_INPUT_HEIGHT} from './ChatWindow';
import {ChatMessage} from '../../../../api/chatTypes';
import {ChatContent} from './ChatContent';

const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'relative',
    height: `calc(100% - ${CHAT_WINDOW_HEADER_HEIGHT + CHAT_WINDOW_INPUT_HEIGHT}px)`,
    overflowY: 'auto',
    paddingBottom: 4,
  },
}));

export function ChatWindowContent(props: {
  messages: Array<ChatMessage>
}) {
  const {root} = useStyles();
  return (
    <div className={root}>
      <ChatContent messages={props.messages}/>
      <ScrollToDiv/>
    </div>
  );
}

/**
 * A div with no content. It scrolls to the div's position after rendering it.
 */
function ScrollToDiv() {
  return (
    <div ref={element => {
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }}/>
  );
}
