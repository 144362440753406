import {default as React} from "react";
import {useRouter} from "../../../util/hooks/useRouterHook";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {routes} from "../../../router/MyRouter";
import {Call} from "../../../api/callTypes";

export function CallAlreadyEndedPage(props: { call: Call }) {
  const {history} = useRouter();

  return (
    <Dialog open={true}>
      <DialogTitle>Call already ended!</DialogTitle>
      <DialogActions>
        {props.call.supportCaseId &&
        <Button
            id={'button_home'}
            variant="contained"
            color="primary"
            onClick={() => history.push(routes.supportCaseDetails(props.call.supportCaseId!!))}
        >
            Go to Support Case
        </Button>}
        <Button
          id={'button_home'}
          variant="contained"
          color="primary"
          onClick={() => history.push(routes.home)}
        >
          Home
        </Button>
      </DialogActions>
    </Dialog>
  );
}
