import * as React from 'react';
import {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import EmbeddedPage from "../../../../components/EmbeddedPage";
import {LandingPageAppBar} from "../../LandingPageAppBar";
import {AppBarTopPadding} from "../../../../components/AppBarTopPadding";
import {Item} from "../../../../api/itemTypes";
import {makeStyles} from "@material-ui/styles";
import ImageInput from "../../../../components/ImageInput";
import {ImageTooLargeDialog, MAX_IMAGE_FILE_SIZE} from "../../../../components/dialogs/ImageTooLargeDialog";
import {loadItem, uploadItemImage} from "../../../../api/itemApi";
import {MalformedImageDialog} from "../../../../components/dialogs/MalformedImageDialog";
import NoCameraWarning from "../../../../components/NoCameraWarning";
import UserNotReachableDialog from "../../../../components/dialogs/UserNotReachableDialog";
import {checkIfCameraAndAudioIsAvailable} from "../../../../util/webrtc/webrtcUtil";
import {declineInvite} from "../../../../api/inviteApi";
import * as callApi from "../../../../api/callApi";
import {useRouter} from "../../../../util/hooks/useRouterHook";
import * as H from "history";
import {routes} from "../../../../router/MyRouter";
import Spinner from "../../../../spinner/Spinner";
import {SupportCaseList} from "../../support-case/SupportCaseList";
import {SupportCase} from "../../../../api/supportCaseTypes";
import {getSupportCases} from "../../../../api/supportCaseApi";
import {useCurrentAccountHasRole} from "../../../../util/hooks/useCurrentAccount";
import {Typography} from "@material-ui/core";
import {animated} from "react-spring";
import InfoIcon from '@material-ui/icons/Info';
import {ItemInfoDialog} from "./ItemInfoDialog";
import {useMainLoadTransition} from '../../../../util/hooks/useMainLoadTransition';

const useStyles = makeStyles({
  img: {
    marginBottom: -4,
    maxHeight: 400, // The height of the 3 items + the spacing between them
    maxWidth: 400,
    borderRadius: 4,
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  button: {
    width: '100%',
    maxWidth: 400,
    height: 44,
    marginRight: 'auto',
    marginLeft: 'auto'
  }
});

export function ItemDetailsPage(props: {
  itemId: number,
}) {
  const {history, location} = useRouter();
  const [item, setItem] = useState(null as Item | null);
  const [itemInfoDialogOpen, setItemInfoDialogOpen] = useState(false);
  const [supportCases, setSupportCases] = useState(null as Array<SupportCase> | null);
  const loading = item === null || supportCases === null;
  const mainLoadTransition = useMainLoadTransition({loading});
  useEffect(() => {
    loadItem(props.itemId)
      .then(setItem)
      .then(() => getSupportCases({itemId: props.itemId}, {
        page: 0,
        size: 99999,
        sort: {field: 'lastActivity', order: 'desc'}
      }))
      .then(page => page.content)
      .then(setSupportCases)
      .catch(() => {
        history.push(routes.home + location.search);
      });
  }, []);
  return (
    <EmbeddedPage>
      <LandingPageAppBar
        title={<>Item <animated.span style={mainLoadTransition}>{item && item.name}</animated.span></>}
        previousLocation={'/' + location.search}
        customRightIcon={<InfoIcon color="inherit"/>}
        onCustomRightButtonClick={() => setItemInfoDialogOpen(true)}
      />
      <AppBarTopPadding>
        <div style={{padding: 16}}>

          {loading && <Spinner/>}

          <animated.div style={mainLoadTransition}>
            <Grid container={true} spacing={2} direction="row-reverse">
              <Grid item={true} xs={12}>
                {item && <SupportButton item={item}/>}
              </Grid>
              <Grid item={true} xs={12} md={5} lg={4}>
                {item && <ItemImage item={item} onItemUpdate={setItem}/>}
              </Grid>
              <Grid item={true} xs={12} md={7} lg={8}>
                <>
                  <Typography variant="h5">Latest Support Cases</Typography>
                  {supportCases && (
                    <SupportCaseList
                      supportCases={supportCases}
                      onClick={(supportCase) => history.push(routes.supportCaseDetails(supportCase.id) + location.search)}
                    />
                  )}
                  {supportCases && supportCases.length <= 0 && (
                    <Typography variant="body1">
                      There are no support cases for this item!
                    </Typography>
                  )}
                </>
              </Grid>
            </Grid>
          </animated.div>
        </div>
      </AppBarTopPadding>
      {item && itemInfoDialogOpen &&
      <ItemInfoDialog item={item} onUpdate={setItem} onClose={() => setItemInfoDialogOpen(false)}/>}
    </EmbeddedPage>
  );
}

function ItemImage(props: {
  item: Item,
  onItemUpdate(item: Item): void
}) {
  const classes = useStyles();
  const [malformedImageDialogOpen, setMalformedImageDialogOpen] = useState(false);
  const [imageTooLargeDialogOpen, setImageTooLargeDialogOpen] = useState(false);
  return (
    <div style={{width: '100%'}}>
      <ImageInput
        onUpload={async file => {
          if (file.size > MAX_IMAGE_FILE_SIZE) {
            setImageTooLargeDialogOpen(true);
            return;
          }
          try {
            props.onItemUpdate(await uploadItemImage(props.item.id, file))
          } catch (error) {
            console.log(error);
            setMalformedImageDialogOpen(true);
          }
        }}
        allowDelete={false}
        onDelete={() => null}
        imageHref={props.item._links.image}
        className={classes.img}
        width="100%"
        height="100%"
        style={props.item.imageId ? {maxHeight: 400} : {height: 400}}
      />

      <MalformedImageDialog
        open={malformedImageDialogOpen}
        onClose={() => setMalformedImageDialogOpen(false)}
      />
      <ImageTooLargeDialog
        open={imageTooLargeDialogOpen}
        onClose={() => setImageTooLargeDialogOpen(false)}
      />
    </div>
  );
}

function SupportButton(props: { item: Item }) {
  const [noCameraWarningDialogOpen, setNoCameraWarningDialogOpen] = useState(false);
  const [userNotReachableDialogOpen, setUserNotReachableDialogOpen] = useState(false);
  const isEnduser = useCurrentAccountHasRole('END_USER') === true;
  const classes = useStyles();
  const {history} = useRouter();
  if (!isEnduser) {
    return null;
  }
  return (
    <div style={{width: '100%'}}>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={() => handleGetSupport(history, props.item, setUserNotReachableDialogOpen, setNoCameraWarningDialogOpen)}
      >
        Get Support
      </Button>
      <NoCameraWarning
        open={noCameraWarningDialogOpen}
        handleDialogClose={() => setNoCameraWarningDialogOpen(false)}
      />
      <UserNotReachableDialog
        open={userNotReachableDialogOpen}
        onClose={() => setUserNotReachableDialogOpen(false)}
      />
    </div>
  );
}

async function handleGetSupport(
  history: H.History,
  item: Item,
  setUserNotReachableDialogOpen: (open: boolean) => void,
  setNoCameraWarningDialogOpen: (open: boolean) => void
) {
  const cameraAvailable = await checkIfCameraAndAudioIsAvailable();
  if (cameraAvailable) {
    try {
      const call = await callApi.createCall({itemId: item.id});
      setTimeout(() => {
        // Decline the invite after 60 seconds. If the user accepted it in the meanwhile we get a 400,
        // but this is no problem and we can just ignore it :-)
        declineInvite(call.invites[0].id).catch(() => null);
      }, 60 * 1000);
      history.push(routes.call(call.id));
    } catch (error) {
      if (error.response.status === /* Sorry, I cannot brew coffee, because */ 418 /* I'm a teapot */) {
        setUserNotReachableDialogOpen(true);
        return;
      }
    }
  } else {
    setNoCameraWarningDialogOpen(true);
  }
}
