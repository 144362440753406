import {Client, ClientRequest} from './clientTypes';
import {myAxios} from '../util/myAxios';
import {Page, Pageable, toUrlParams} from '../query/pageable';
import {isValidationErrorResponse, ValidationErrors} from './restApi';
import {AxiosResponse} from 'axios';

export function loadClient(clientId: number): Promise<Client> {
  return myAxios()
    .then(axios => axios.get(`/api/clients/${clientId}`))
    .then(response => response.data);
}

export function loadClients(
  params: { searchText?: string },
  pageable: Pageable
): Promise<Page<Client>> {
  const searchParam = params.searchText === undefined ? '' : `search=${encodeURIComponent(params.searchText)}&`;
  return myAxios()
    .then(axios => axios.get(`/api/clients/?${searchParam}${toUrlParams(pageable)}`))
    .then(response => response.data);
}

export function createClient(client: ClientRequest): Promise<Client | ValidationErrors> {
  return myAxios()
    .then(axios => axios.post(`/api/clients`, {...client, contactEmail: client.contactEmail.trim()}))
    .then(
      response => response.data,
      error => {
        const response: AxiosResponse = error.response;
        if (isValidationErrorResponse(response)) {
          return response.data;
        } else {
          throw error;
        }
      });
}

export function updateClient(client: Client): Promise<Client | ValidationErrors> {
  return myAxios()
    .then(axios => axios.put(`/api/clients/${client.id}`, client))
    .then(
      response => response.data,
      error => {
        const response = error.response;
        if (isValidationErrorResponse(response)) {
          return response.data;
        } else {
          throw error;
        }
      });
}

export function deleteClient(clientId: number): Promise<{}> {
  return myAxios()
    .then(axios => axios.delete(`/api/clients/${clientId}`));
}
