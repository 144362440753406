import {useEffect, useRef} from "react";

/**
 * Returns the previous value of the given value or null if there was no previous value.
 */
export function usePrevious<T>(value: T) {
  const ref = useRef(null as T | null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
