import {BaseNotification} from "../notifications/BaseNotification";
import * as React from "react";
import log from "../util/myLog";

const logger = log.getLogger('NotificationHandler');

export type NotificationListener = (notification: BaseNotification, source: NotificationSource) => void;

export type NotificationSource = 'webpush' | 'polling' | 'android';

export interface NotificationHandler {
  onReceiveNotification(notification: BaseNotification, source: NotificationSource): void;

  addListener(listener: NotificationListener): NotificationListener;

  removeListener(listener: NotificationListener): void;
}

export const NotificationHandlerContext = React.createContext<NotificationHandler>(createNotificationHandler());

function createNotificationHandler(): NotificationHandler {
  let listeners: NotificationListener[] = [];
  return {
    onReceiveNotification(notification: BaseNotification, source: NotificationSource): void {
      logger.info(`Received notification via ${source}: `, notification);
      listeners.forEach(l => l(notification, source));
    },
    addListener(listener: NotificationListener): NotificationListener {
      listeners = [...listeners, listener];
      return listener;
    },
    removeListener(listener: NotificationListener): void {
      listeners = listeners.filter(e => e !== listener);
    }
  };
}
