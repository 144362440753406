import * as React from 'react';
import {CSSProperties, ReactNode, useEffect, useState} from 'react';
import {Theme} from '@material-ui/core';
import {SingleClickIconButton} from './SingleClickButton';
import makeStyles from "@material-ui/styles/makeStyles";
import Zoom from "@material-ui/core/Zoom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {grey} from "@material-ui/core/colors";
import {createStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    width: 48,
    height: 48,
    margin: `${theme.spacing()}px ${theme.spacing(0.5)}px`
  },
  button: {
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.standard,
    }),
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    '&:hover': {
      backgroundColor: grey[800],
    },
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: 0,
  },
  hoverCatcher: {
    zIndex: -1,
    position: 'absolute',
    bottom: 0,
    left: -8,
    width: 64
  }
}));

export function SingleClickButtonWithSubmenu(props: {
  children: Array<ReactNode>,
  title?: string,
  icon?: ReactNode,
  style?: CSSProperties,
  childrenWidth?: number,
  childrenHeight?: number,
  initialHeight?: number
}) {
  const children = props.children.filter(element => !!element);
  const classes = useStyles();
  const childrenWidth = props.childrenWidth || 62;
  const childrenHeight = props.childrenHeight || 56;
  const initialHeight = props.initialHeight || 48;
  const [colorsVisible, setColorsVisible] = useState(false);
  const [hoverCount, setHoverCount] = useState(0);
  useEffect(() => {
    // Prevent hover count from getting negative. This can happen, because we set it to 0 on some places
    if (hoverCount < 0) {
      setHoverCount(0);
    }
  }, [hoverCount]);
  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={() => {
        setColorsVisible(false);
        setHoverCount(0);
      }}>
        <>
          <SingleClickIconButton
            buttonProps={{
              className: classes.button,
              title: props.title || 'More',
              style: props.style,
              onMouseEnter: () => setHoverCount(prev => ++prev),
              onMouseLeave: () => setHoverCount(prev => --prev),
            }}
            onSingleClick={async () => setColorsVisible(prev => !prev)}
          >
            {props.icon || <MoreVertIcon/>}
          </SingleClickIconButton>
          {children.map((element, index) => (
            <HoverObject
              key={index}
              position={index}
              visible={colorsVisible || hoverCount > 0}
              initialHeight={initialHeight}
              childrenHeight={childrenHeight}
              childrenWidth={childrenWidth}
              onMouseEnter={() => setHoverCount(prev => ++prev)}
              onMouseLeave={() => setHoverCount(prev => --prev)}
              onClick={() => {
                setColorsVisible(false);
                setHoverCount(0);
              }}
            >
              {element}
            </HoverObject>
          ))}
          {hoverCount > 0 && (
            // We need this to keep the menu open when leaving to SingleClickButton to select an element
            <div
              className={classes.hoverCatcher}
              style={{height: initialHeight + 2 + children.length * childrenHeight}}
              onMouseEnter={() => setHoverCount(prev => ++prev)}
              onMouseLeave={() => setHoverCount(prev => --prev)}
            />
          )}
        </>
      </ClickAwayListener>
    </div>
  );
}

function HoverObject(props: {
  visible: boolean,
  position: number,
  children: ReactNode,
  initialHeight: number,
  childrenHeight: number,
  childrenWidth: number,
  onMouseEnter?(): void,
  onMouseLeave?(): void,
  onClick?(): void
}) {
  return (
    <Zoom
      in={props.visible}
      style={{transitionDelay: props.visible ? `${30 * props.position}ms` : '0ms'}}
    >
      <div style={{
        position: 'absolute',
        bottom: props.initialHeight + props.position * props.childrenHeight,
        left: (56 - props.childrenWidth) / 2
      }} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <div onClick={props.onClick}>
          {props.children}
        </div>
      </div>
    </Zoom>
  );
}
