import log from "../myLog";

const logger = log.getLogger('ChunkedDataChannel');

/**
 * A wrapper for RTC data channels, that splits them into smaller chunks.
 *
 * @see https://kurzdigital.atlassian.net/wiki/spaces/KDS/pages/842268690/WebRtc+Datachannel+Chunking
 */
export class ChunkedDataChannel {

  private open = false;

  private _closed = false;
  public get closed() {
    return this._closed;
  }

  /**
   * The underlying rtc data channel.
   */
  public readonly rtcChannel: RTCDataChannel;

  private buffers: Map<number, { receivedDataLength: number, data: Uint8Array }> = new Map();

  // Used to queue messages in case someone calls #sendMessage() before the channel is open
  private messageQueue: Array<ArrayBuffer> = [];

  /**
   * Creates a new chunked data channel.
   *
   * @param channel The RTC channel to wrap.
   */
  constructor(channel: RTCDataChannel) {
    this.rtcChannel = channel;
    channel.onopen = () => {
      this.open = true;
      // Replay messages
      this.messageQueue.forEach(message => this.sendMessage(message));
      this.messageQueue.length = 0;
    };
    channel.onclose = () => {
      this.onClose();
      this._closed = true;
    };
    channel.onmessage = event => {
      const buffer = toArrayBuffer(event.data);
      // Check if it is a promise or an ArrayBuffer
      if ((buffer as any).then) {
        (buffer as Promise<ArrayBuffer>).then(data => this.onChunkedData(data))
      } else {
        this.onChunkedData(buffer as ArrayBuffer);
      }
    }
  }

  /*
   * Methods that can be overwritten by a user of this class
   */
  public onMessage(message: Uint8Array) {
  }

  public onClose() {
  }

  /**
   * Sends a message through the underlying data channel and automatically breaks it up into chunks.
   *
   * <p>In case the underlying rtc data channel is not open, it will put the message in a queue and send them
   * as soon as the channel opens.
   *
   * @param message The full message data.
   */
  public sendMessage(message: ArrayBuffer) {
    // Don't send messages to a closed data channel
    if (this.closed) {
      return;
    }

    if (!this.open) {
      this.messageQueue.push(message);
      return;
    }

    // Can be constant, because we send messages sequentially
    const messageId = 42;

    const chunks: Chunk[] = Chunk.fromFullMessage(messageId, message);
    for (let i = 0; i < chunks.length; i++) {
      logger.debug("Sending chunk ", i + 1, " of ", chunks.length, " for message with id ", messageId);
      this.rtcChannel.send(chunks[i].chunkDataWithHeaders);
    }
  }

  private onChunkedData(data: ArrayBuffer) {
    const chunk = new Chunk(data);

    // Get or set the buffer
    let buffer = this.buffers.get(chunk.messageId);
    if (buffer == null) {
      buffer = {
        data: new Uint8Array(chunk.totalDataLength),
        receivedDataLength: 0
      };
      this.buffers.set(chunk.messageId, buffer);
    }

    // Update the buffer data
    buffer.data.set(chunk.chunkData, buffer.receivedDataLength);
    buffer.receivedDataLength += chunk.chunkData.byteLength;

    logger.debug("Received chunk data for message with id ", chunk.messageId, ". ",
      "We now have ", buffer.receivedDataLength, " of ", chunk.totalDataLength, " total bytes");

    // Check if the buffer is full (= message is complete)
    if (buffer.receivedDataLength === chunk.totalDataLength) {
      this.onMessage(buffer.data);
      this.buffers.delete(chunk.messageId);
    }
  }

}

// An internal helper class that represents a chunk
class Chunk {

  /**
   * The chunk header size in bytes.
   *
   * 4 bytes for the messageId field and 4 bytes for the totalDataLength field.
   */
  public static readonly CHUNK_HEADER_LENGTH = 2 * 4;

  /**
   * The maximum length of a chunk.
   */
  public static readonly MAX_CHUNK_LENGTH = 16 * 1024;

  public readonly chunkDataWithHeaders: ArrayBuffer;
  public readonly messageId: number;
  public readonly totalDataLength: number;
  public readonly chunkData: Uint8Array;

  /**
   * @param data The chunk data with headers.
   */
  constructor(data: ArrayBuffer) {
    this.chunkDataWithHeaders = data;
    const dataView = new DataView(data);
    this.messageId = dataView.getUint32(0, true);
    this.totalDataLength = dataView.getUint32(4, true);
    this.chunkData = new Uint8Array(data, Chunk.CHUNK_HEADER_LENGTH, data.byteLength - Chunk.CHUNK_HEADER_LENGTH);
  }

  /**
   * Creates an array of chunks from a full message.
   *
   * @param messageId The id of the message.
   * @param message The full message data.
   */
  public static fromFullMessage(messageId: number, message: ArrayBuffer): Chunk[] {
    const chunks: Chunk[] = [];
    const totalDataLength = message.byteLength;
    const chunkBuffer = new Uint8Array(this.MAX_CHUNK_LENGTH);
    const maxChunkDataLength = Chunk.MAX_CHUNK_LENGTH - Chunk.CHUNK_HEADER_LENGTH;

    let offset = 0;
    while (offset < totalDataLength) {
      const chunkDataLength = Math.min(maxChunkDataLength, totalDataLength - offset);
      const chunkData = message.slice(offset, offset + chunkDataLength);

      // Set header
      const view = new DataView(chunkBuffer.buffer);
      view.setUint32(0, messageId, true);
      view.setUint32(4, totalDataLength, true);
      // Set body
      chunkBuffer.set(new Uint8Array(chunkData), Chunk.CHUNK_HEADER_LENGTH);

      const chunk = new Chunk(chunkBuffer.buffer.slice(0, chunkDataLength + Chunk.CHUNK_HEADER_LENGTH));
      chunks.push(chunk);

      offset += chunkDataLength;
    }

    return chunks;
  }

}

function toArrayBuffer(data: ArrayBuffer | Blob): ArrayBuffer | Promise<ArrayBuffer> {
  // In Firefox, we get a Blob in the data channel message event data
  if (data instanceof Blob) {
    return new Response(data).arrayBuffer();
  }
  // In Chrome, we get an ArrayBuffer
  return data;
}
