import * as React from "react";
import {MouseEvent, ReactNode, useContext, useEffect, useState} from "react";
import {ListSubheader, MenuItem, Theme} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from "@material-ui/core/Menu/Menu";
import LinkMenuItem from "../components/LinkMenuItem";
import {getCurrentAccount, keycloak, login} from "../keycloak";
import {AccountDto} from "../api/userTypes";
import {isEndUser, isSupportAgent} from "../permissions";
import {Username} from "../components/Username";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from "@material-ui/core/Avatar/Avatar";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/styles";
import {WebpushContext} from "../components/WebpushContext";
import * as notificationTokenApi from "../api/notificationTokenApi";
import * as supportAgentApi from "../api/supportAgentApi";
import {toObjectUrl} from "../util/hooks/useObjectUrl";
import {PRIVACY_NOTICE_LABEL, PRIVACY_NOTICE_URL} from "../branding/general";

interface Props {
  customRightIcon?: ReactNode,

  onCustomRightButtonClick?(): void
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32
  }
}));

export function AppBarMenu(props: Props) {
  const [account, setAccount] = useState<AccountDto | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [avatarObjectUrl, setAvatarObjectUrl] = useState("");
  const styles = useStyles();
  const webpushSubscription = useContext(WebpushContext);

  useEffect(() => {
    getCurrentAccount().then(account => {
      setAccount(account);
      if (isSupportAgent(account) && account.imageId) {
        toObjectUrl(supportAgentApi.imageUrl(account.id)).then(setAvatarObjectUrl);
      }
    });
  }, []);
  const {customRightIcon, onCustomRightButtonClick} = props;

  if (customRightIcon) {
    return (
      <IconButton id="confirm_button" color="inherit" onClick={onCustomRightButtonClick}>
        {customRightIcon}
      </IconButton>
    );
  }
  if (!account) {
    return null;
  }

  const getUsernameMenuItem = () => {
    if (isEndUser(account!!)) {
      return null;
    }
    return (
      <MenuItem key="username">
        <Username user={account!!}/>
      </MenuItem>
    );
  };

  const getLoginMenuItem = () => {
    return (
      <MenuItem key="login" id="menuitem_login" onClick={login}>
        Login
      </MenuItem>
    );
  };

  const getLogoutMenuItem = () => {
    return (
      <MenuItem
        id="menuitem_logout"
        key="logout"
        onClick={async () => {
          if (webpushSubscription) {
            await notificationTokenApi.unregisterWebpush(webpushSubscription);
          }
          keycloak.logout();
        }}
      >
        Log out
      </MenuItem>
    );
  };

  return (
    <>
      <IconButton id="menu_button" onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget!!)}
                  color="inherit">
        {isEndUser(account) && <MoreVertIcon color="inherit"/>}
        {!isEndUser(account) && account.imageId == null &&
        <AccountCircleIcon style={{fontSize: 32}} color="inherit"/>}
        {isSupportAgent(account) && account.imageId != null &&
        <Avatar className={styles.avatar} src={avatarObjectUrl}/>}
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        disableAutoFocusItem={true}
      >
        {isEndUser(account) ? (
          [
            <ListSubheader key="login_header">Support Agent & Admin User</ListSubheader>,
            getLoginMenuItem()
          ]
        ) : (
          [
            <ListSubheader key="account">Account</ListSubheader>,
            getUsernameMenuItem(),
            getLogoutMenuItem()
          ]
        )}
        <Divider/>
        <ListSubheader>Legal</ListSubheader>
        <LinkMenuItem text="Terms and Conditions" url={'/legal/terms_and_conditions_en.pdf'}/>
        <LinkMenuItem text={PRIVACY_NOTICE_LABEL} url={PRIVACY_NOTICE_URL}/>
        <LinkMenuItem text="Imprint" url={'/legal/imprint_en.html'}/>
        <Divider/>
        <ListSubheader>Version {process.env.REACT_APP_GIT_TAG || 'n/a'}</ListSubheader>
      </Menu>
    </>
  );
}
