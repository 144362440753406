import * as React from 'react';
import {Theme} from '@material-ui/core';
import makeStyles from "@material-ui/styles/makeStyles";
import ButtonBase from "@material-ui/core/ButtonBase";
import {SingleClickButtonWithSubmenu} from "../../../components/SingleClickButtonWithSubmenu"
import ColorLensIcon from '@material-ui/icons/ColorLens';
import {useTheme} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  colorOption: {
    padding: 19,
    borderRadius: 1000,
    borderStyle: 'solid',
    borderColor: '#f5f5f5',
    borderWidth: 3,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    '&:hover': {
      borderColor: '#e3e3e3',
      boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19)',
    },
    transition: theme.transitions.create(['box-shadow', 'border-color'], {
      duration: theme.transitions.duration.standard,
    }),
  },
}));

export const DRAWING_COLORS = [
  '#7f7f7f', '#FFD232', '#FF2D55', '#2196f3', '#4caf50', '#000000'
];

export function DrawingColorPicker(props: {
  onSelect(color: string): void,
  currentColor: string,
}) {
  const classes = useStyles();
  const theme: Theme = useTheme();

  return (
    <SingleClickButtonWithSubmenu
      title="Select Color"
      icon={<ColorLensIcon/>}
      style={{
        backgroundColor: props.currentColor,
        color: theme.palette.getContrastText(props.currentColor)
      }}
      childrenWidth={50}
      childrenHeight={50}
      initialHeight={62}
    >
      {DRAWING_COLORS.map((color, index) => (
        <ButtonBase
          key={index}
          className={classes.colorOption}
          style={{backgroundColor: color}}
          onClick={() => {
            props.onSelect(color);
          }}/>
      ))}
    </SingleClickButtonWithSubmenu>
  );
}
