import * as React from 'react';
import {CSSProperties} from 'react';
import Transition from 'react-transition-group/Transition';

export type TransitionState = 'exited' | 'entering' | 'entered' | 'exiting';

interface WithTransitionProps {
  key: any;
  duration: number;
  defaultStyle: CSSProperties;
  transitionStyles: { [key: string]: CSSProperties };
  children: (style: CSSProperties, transitionState: TransitionState) => React.ReactNode;
}

export function withTransition(props: WithTransitionProps) {
  return (
    <Transition key={props.key} timeout={props.duration}>
      {(transitionState: TransitionState) =>
        props.children(
          {
            ...props.defaultStyle,
            ...props.transitionStyles[transitionState]
          },
          transitionState)
      }
    </Transition>);
}

export function cardAnimationProps(maxHeight: number): {
  duration: number;
  defaultStyle: CSSProperties;
  transitionStyles: { [key: string]: CSSProperties };
} {
  const duration = 250;
  return {
    duration,
    defaultStyle: {
      transition: `opacity ${duration}ms ease-out, max-height ${duration}ms ease-out`,
      opacity: 0,
      maxHeight: 0,
    },
    transitionStyles: {
      entered: {
        opacity: 1,
        maxHeight: maxHeight,
      },
    },
  };
}
