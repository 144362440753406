import {useEffect, useState} from "react";
import {AccountDto, Role} from "../../api/userTypes";
import {getCurrentAccount} from "../../keycloak";

/**
 * Gets current connected account. It's null while fetching the current account.
 */
export function useCurrentAccount() {
  const [account, setAccount] = useState(null as AccountDto | null);
  useEffect(() => {
    getCurrentAccount().then(setAccount)
  }, []);
  return account;
}

/**
 * Gets if the current connected account has the given role. It's null while fetching the current account.
 */
export function useCurrentAccountHasRole(role: Role): boolean | null {
  const account = useCurrentAccount();
  if (!account) {
    return null;
  }
  return account.roles.filter(r => r === role).length > 0;
}
