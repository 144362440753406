import * as React from 'react';
import {WithStyles} from '@material-ui/core';
import {computed, IObservableArray, IObservableValue, observable} from 'mobx';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import {observer} from 'mobx-react';
import {fullPageable} from '../../../query/pageable';
import * as supportAgentApi from '../../../api/supportAgentApi';
import {User} from '../../../api/userTypes';
import {Spinner} from '../../../spinner/Spinner';
import {DialogWithDirtyWarning} from '../../../components/dialogs/DialogWithDirtyWarning';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {MyDialogStyles} from '../../../styles/myDialogStyles';
import TextField from '@material-ui/core/TextField/TextField';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import {userToString} from '../../../components/Username';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import Button from '@material-ui/core/Button/Button';

interface Props {
  currentSupportAgent: User;
  open: boolean;
  onClose: (selectedSupportAgentId: number | null) => void;
}

type PropsWithStyle = Props & WithStyles<MyDialogStyles>;

@observer
export class ReassignItemsDialog extends React.Component<PropsWithStyle> {

  @observable loading = true;
  @observable selectedSupportAgentId: number | undefined;
  supportAgents: IObservableArray<User> = observable.array();
  dirtyState: IObservableValue<boolean> = observable.box(false);
  inProgressCounter: IObservableValue<number> = observable.box(0);

  constructor(props: PropsWithStyle) {
    super(props);
    this.init = this.init.bind(this);
  }

  @computed get noAgentsAvailable(): boolean {
    return !this.loading && this.supportAgents.length <= 1;
  }

  /**
   * This Dialog component needs to remain mounted for nicer animations, so
   * we use this function triggered by `Dialog.onRendered` instead of `componentDidMount`
   * for initialization.
   */
  init() {
    this.loading = true;
    this.supportAgents.replace([]);
    this.dirtyState.set(false);
    this.selectedSupportAgentId = undefined;
    Promise.all([
      supportAgentApi.getAll({}, fullPageable()).then(agents => {
        this.supportAgents.replace(agents.content);
      })
    ]).then(() => {
      this.loading = false;
    });
  }

  render() {
    return (
      <DialogWithDirtyWarning
        open={this.props.open}
        onClose={() => this.props.onClose(null)}
        dirtyState={this.dirtyState}
        fullWidth={true}
        maxWidth="md"
        onRendered={this.init}
        render={closeHandler => (
          <>
            <DialogTitle>
              Reassign items
              <IconButton
                onClick={closeHandler}
                className={this.props.classes.closeButton}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {this.renderDialogContent()}
            </DialogContent>

            <DialogActions>
              {this.noAgentsAvailable ?
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.props.onClose(null)}
                >
                  OK
                </Button>
                :
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!this.selectedSupportAgentId || this.inProgressCounter.get() > 0}
                  onClick={() =>
                    this.props.onClose(this.selectedSupportAgentId!)
                  }
                >
                  Assign
                </Button>
              }
            </DialogActions>
          </>
        )}
      />
    );
  }

  private renderDialogContent() {
    if (this.loading) {
      return <Spinner/>;
    }
    if (this.noAgentsAvailable) {
      return (
        <DialogContentText>
          No agents available for assignment.
        </DialogContentText>);
    }
    return (
      <TextField
        label="Assign to"
        value={this.selectedSupportAgentId}
        onChange={e => {
          this.selectedSupportAgentId = Number.parseInt(e.target.value, 10);
        }}
        margin="dense"
        select={true}
        fullWidth={true}
        required={true}
      >
        {this.supportAgents.map(agent => (
          <MenuItem key={agent.id} value={agent.id} disabled={this.props.currentSupportAgent.id === agent.id}>
            {userToString(agent)}
          </MenuItem>
        ))}
      </TextField>);
  }
}
