import * as React from 'react';
import {ReactNode, SyntheticEvent} from 'react';
import Dialog, {DialogProps} from '@material-ui/core/Dialog/Dialog';
import {observer} from 'mobx-react';
import {action, IObservableValue, observable} from 'mobx';
import {DirtyWarningDialog} from './DirtyWarningDialog';

export interface DialogWithDirtyWarningProps {
  dirtyState: IObservableValue<boolean>;

  render(closeHandler: React.ReactEventHandler<{}>): ReactNode;

  children?: undefined
}

/**
 * A Dialog that will open a [DirtyWarningDialog] if it is closed when [dirtyState] is true.
 */
@observer
export class DialogWithDirtyWarning extends React.Component<DialogProps & DialogWithDirtyWarningProps> {
  dirtyWarningDialogOpen: IObservableValue<boolean> = observable.box(false);

  constructor(props: DialogProps & DialogWithDirtyWarningProps) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    // WARNING: Even though IntelliJ tells you, that "dirtyState" and "onClose" are unused: DON'T REMOVE THEM!
    // WARNING: If you remove them, the code does no longer work. I guess it's some weird MobX magic...
    // I do not like MobX :/
    const {dirtyState, onClose, render, ...dialogProps} = this.props;
    return (
      <>
        <DirtyWarningDialog
          open={this.dirtyWarningDialogOpen.get()}
          onOk={(e) => {
            this.dirtyWarningDialogOpen.set(false);
            if (this.props.onClose) {
              this.props.onClose(e, 'backdropClick');
            }
          }}
          onDismiss={() => this.dirtyWarningDialogOpen.set(false)}
        />
        <Dialog
          onClose={this.handleClose}
          {...dialogProps}
        >
          {this.props.render(this.handleClose)}
        </Dialog>
      </>);
  }

  @action
  private handleClose(e: SyntheticEvent) {
    if (this.props.dirtyState.get()) {
      this.dirtyWarningDialogOpen.set(true);
    } else {
      if (this.props.onClose) {
        this.props.onClose(e, 'backdropClick');
      }
    }
  }
}
