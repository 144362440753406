import * as React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {InvitationCreatedNotification} from "../../notifications/InvitationCreatedNotification";
import {RouteComponentProps, withRouter} from "react-router";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import {MuiThemeProvider, StyleRulesCallback, Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {green, red} from "@material-ui/core/colors";
import createMuiTheme, {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {Sound} from "../Sound";
import {declineInvite} from "../../api/inviteApi";

type LocalStyles = 'buttonIcon' | 'titleIcon';

type Props = {
  handleDialogClose(): void,
  notification: InvitationCreatedNotification
} & RouteComponentProps & WithStyles<LocalStyles>;

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = theme => ({
  titleIcon: {
    marginRight: theme.spacing(2),
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  buttonIcon: {
    marginRight: theme.spacing(),
  }
});

const acceptCallTheme = createMuiTheme({
  palette: {
    primary: green
  }
} as ThemeOptions);

const declineCallTheme = createMuiTheme({
  palette: {
    primary: red
  }
} as ThemeOptions);

export class IncomingCallDialog extends React.Component<Props> {

  private timeout: any;

  componentDidMount() {
    // Cancel calling after 60 seconds
    this.timeout = setTimeout(() => {
      declineInvite(this.props.notification.payload.invite.id);
      this.props.handleDialogClose();
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const {handleDialogClose, notification, history, classes} = this.props;
    return (
      <Dialog open={true}>
        <Sound src={'/sounds/dial.mp3'} loop={true}/>
        <DialogTitle>
          <NotificationsActive className={classes.titleIcon} fontSize="large"/>
          Incoming call
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.getDialogContent()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiThemeProvider theme={acceptCallTheme}>
            <Button
              onClick={() => history.push(`/calls/` + notification.payload.call.id)}
              color="primary"
            >
              <CallIcon className={classes.buttonIcon}/>
              Accept
            </Button>
          </MuiThemeProvider>
          <MuiThemeProvider theme={declineCallTheme}>
            <Button
              onClick={() => {
                declineInvite(notification.payload.invite.id);
                handleDialogClose();
              }}
              color="primary"
            >
              <CallEndIcon className={classes.buttonIcon}/>
              Decline
            </Button>
          </MuiThemeProvider>
        </DialogActions>
      </Dialog>
    );
  }

  private getDialogContent = () => {
    const {notification} = this.props;
    if (notification.payload.invite.inviter.name) {
      // It's a support agent
      const supportAgentName = notification.payload.invite.inviter.name.first + ' ' + notification.payload.invite.inviter.name.last;
      return (<>The support agent <b>{supportAgentName}</b> is calling you!</>);
    } else {
      // It's a Client
      return 'A Client is calling you!';
    }

  }
}

export default withStyles(localStyles)(withRouter(IncomingCallDialog));
