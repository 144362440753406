import * as React from 'react';
import {CSSProperties, useEffect, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {ChatMessage} from '../../../../api/chatTypes';
import {useCurrentAccount} from '../../../../util/hooks/useCurrentAccount';
import moment from 'moment';

const useStyles = makeStyles(() => createStyles({
  authorName: {
    marginTop: 4,
    marginLeft: 8,
    marginRight: 8,
  },
  speechBubble: {
    borderRadius: '5px 15px 15px 15px',
    display: 'inline-block',
    width: 'auto',
    margin: 4,
    marginLeft: 8,
    marginRight: 8,
    padding: 15,
    background: '#afeff8',
    wordWrap: 'break-word',
    maxWidth: 'calc(100% - 16px)'
  }
}));

export function ChatBubble(props: {
  position: 'left' | 'right',
  message: ChatMessage,
  prevMessage?: ChatMessage,
  className?: string,
  style?: CSSProperties
}) {
  const {authorName, speechBubble} = useStyles();
  const ownUserId = (useCurrentAccount() || {id: -1}).id;
  // Lighten the background color to make the black text more readable
  const color = lightenDarkenColor(props.message.color, 100);

  const [displayTime, setDisplayTime] = useState(moment(props.message.created).fromNow());
  useEffect(() => {
    const interval = window.setInterval(() => setDisplayTime(moment(props.message.created).fromNow()), 1000);
    return () => clearInterval(interval);
  });

  return (
    <div className={props.className} style={{...props.style, textAlign: props.position}}>
      {/* Only display the user name, when the previous message was from another user. Also display it, when the last message is older than 5 minutes. */}
      {(!props.prevMessage || props.message.sender.id !== props.prevMessage.sender.id || moment(props.message.created).diff(props.prevMessage.created, 'minutes') >= 5) && (
        <div className={authorName}>
          <Typography>
            <b>
              {props.message.sender.id === ownUserId && 'You'}
              {props.message.sender.id !== ownUserId && props.message.sender.name && `${props.message.sender.name.first} ${props.message.sender.name.last}`}
              {props.message.sender.id !== ownUserId && !props.message.sender.name && 'Client'}
            </b>
            &nbsp;• <span title={moment(props.message.created).format('L, LT')}>{displayTime}</span>
          </Typography>
        </div>
      )}
      <div className={speechBubble} style={{
        background: color,
        borderRadius: props.position === 'left' ? '5px 15px 15px 15px' : '15px 5px 15px 15px'
      }}>
        <Typography>
          {props.message.body}
        </Typography>
      </div>
    </div>
  );
}

// Taken from https://css-tricks.com/snippets/javascript/lighten-darken-color/
function lightenDarkenColor(color: string, amt: number) {

  let usePound = false;

  if (color[0] == '#') {
    color = color.slice(1);
    usePound = true;
  }

  let num = parseInt(color, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);

}
