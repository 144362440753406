import * as React from "react";
import {Card, CardContent, CardHeader, createStyles, Theme} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import {makeStyles} from "@material-ui/styles";
import {green, red} from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    margin: theme.spacing(),
  },
  checkOk: {
    color: green[500],
  },
  checkFailed: {
    color: red[500],
  },
}));

export function CheckItem(props: {
  title: string,
  children: React.ReactNode,
}) {
  const classes = useStyles();
  return (<Card className={classes.card}>
      <CardHeader title={props.title}/>
      <CardContent>
        {props.children}
      </CardContent>
    </Card>
  );
}

type CheckResultType = null | true | string;

export function CheckResult(props: {
  result: CheckResultType,
}) {
  const classes = useStyles();
  const r = props.result;
  return (<>
      {r === true && <CheckIcon className={classes.checkOk} fontSize="large"/>}
      {typeof r === "string" && <>
        <ErrorIcon className={classes.checkFailed} fontSize="large"/>
        {r}
      </>}
    </>
  );
}
