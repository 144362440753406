import * as React from 'react';
import {ReactNode} from 'react';
import {LocalStreamHelper} from "../../../util/webrtc/LocalStreamHelper";
import NoCameraWarning from "../../../components/NoCameraWarning";
import log from "../../../util/myLog";

export const logger = log.getLogger('LocalStreamSupplier');

export type LocalStreamConsumerProps = {
  stream: MediaStream,
  devices: Array<MediaDeviceInfo>,
  videoEnabled: boolean,
  audioEnabled: boolean,
  screenShareEnabled: boolean,
  streamIsFrontFacing: boolean,
  setVideoEnabled(enabled: boolean): void,
  setAudioEnabled(enabled: boolean): void,
  setShareScreenEnabled(enabled: boolean): void,
  switchToNextVideoTrack(): void
}

type Props = {
  render(props: LocalStreamConsumerProps): ReactNode
};

type State = {
  failedToAccess: boolean,
  stream?: MediaStream,
  videoEnabled?: boolean,
  audioEnabled?: boolean,
  screenShareEnabled?: boolean
};

/**
 * Supplied the local stream to the node in the render function.
 */
export class LocalStreamSupplier extends React.PureComponent<Props, State> {

  private localStreamHelper?: LocalStreamHelper;

  constructor(props: Props) {
    super(props);
    this.state = {
      failedToAccess: false
    };
  }

  componentDidMount() {
    this.localStreamHelper = new LocalStreamHelper({
      onMediaStreamChange: this.handleMediaStreamChange,
      onFailedToAccessMediaDevice: error => {
        this.setState({failedToAccess: true});
        logger.warn('Failed to access camera or microphone', error);
      }
    });
  }

  componentWillUnmount() {
    if (this.localStreamHelper) {
      this.localStreamHelper.close();
      this.localStreamHelper = undefined;
    }
  }

  render() {
    const {stream, videoEnabled, audioEnabled, screenShareEnabled, failedToAccess} = this.state;
    if (stream) {
      return this.props.render({
        stream,
        videoEnabled: videoEnabled!!,
        audioEnabled: audioEnabled!!,
        screenShareEnabled: screenShareEnabled!!,
        streamIsFrontFacing: this.localStreamHelper!!.currentVideoTrackIsMostLikelyFrontFacing(),
        devices: this.localStreamHelper!!.devices,
        setAudioEnabled: enabled => {
          this.localStreamHelper!!.audioEnabled = enabled;
          this.updateState();
        },
        setVideoEnabled: enabled => {
          this.localStreamHelper!!.videoEnabled = enabled;
          this.updateState();
        },
        setShareScreenEnabled: enabled => {
          this.localStreamHelper!!.screenShareEnabled = enabled;
          this.updateState();
        },
        switchToNextVideoTrack: () => {
          this.localStreamHelper!!.switchToNextVideoTrack();
          this.updateState();
        }
      });
    }

    if (failedToAccess) {
      return <NoCameraWarning open={true} withoutOkButton={true}/>;
    }
    return null;
  }

  private handleMediaStreamChange = (stream: MediaStream) => {
    this.updateState();
  };

  private updateState = () => {
    if (!this.localStreamHelper) {
      return;
    }
    const {stream, videoEnabled, audioEnabled, screenShareEnabled} = this.localStreamHelper;
    this.setState({stream, videoEnabled, audioEnabled, screenShareEnabled})
  };

}
