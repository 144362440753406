import {HasLinks} from './restApi';

export enum RoleEnum {
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  TENANT_ADMIN = 'TENANT_ADMIN',
  SUPPORT_AGENT = 'SUPPORT_AGENT',
  END_USER = 'END_USER',
}

/** Mirrors [com.kurzdigital.remotexpert.security.Role] */
export type Role = 'PLATFORM_ADMIN' | 'TENANT_ADMIN' | 'SUPPORT_AGENT' | 'END_USER';

export interface SupportAgentRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  imageId: number | null;
  reassignItemsToSupportAgentId: number | null;
}

export interface DeleteSupportAgentRequest {
  reassignItemsToSupportAgentId?: number;
}

export interface User extends SupportAgentRequest, HasLinks {
  id: number;
  keycloakId: string;
  _links: {
    self: string;
    image: string | null;
  };
}

/** Mirrors [com.kurzdigital.remotexpert.user.UserNameDto] */
export interface UserName {
  'first': string;
  'last': string;
}

export function emptyUser(): SupportAgentRequest {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    imageId: null,
    reassignItemsToSupportAgentId: null,
  };
}

/**
 * We call it AccountDto to disambiguate it from DOM Apis `Account`
 */
export interface AccountDto extends User {
  id: number;
  keycloakId: string;
  roles: Role[];
}

/**
 * Mirrors [com.kurzdigital.remotexpert.user.ReassignSupportAgentItemsRequest]
 */
export interface ReassignSupportAgentItemsRequest {
  newSupportAgentId: number;
}

export function displayName(name: UserName): string {
  return `${name.first} ${name.last}`;
}
