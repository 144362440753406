import * as React from 'react';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import {SupportCase} from '../../../api/supportCaseTypes';
import {SupportCaseCard} from './SupportCaseCard';
import Spinner from '../../../spinner/Spinner';
import {routes} from '../../../router/MyRouter';
import {History} from 'history';
import {createStyles} from "@material-ui/styles";

type Props = {
  supportCases: Array<SupportCase> | null;
  history: History;
} & WithStyles<LocalStyles>;

type LocalStyles = 'root' | 'scroll';

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = (theme: Theme) => createStyles({
  root: {
    width: 'auto',
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: 275,
    padding: theme.spacing(2),
  },
  scroll: {
    height: 'calc(100% - 32px)',
    minWidth: 'calc(100% - 32px)',
    padding: 0,
    width: 'auto',
    position: 'absolute',
    whiteSpace: 'nowrap',
  }
});

class SupportCaseScroller extends React.PureComponent<Props> {

  render() {
    const {classes, supportCases} = this.props;
    if (supportCases && supportCases.length <= 0) {
      return null;
    }
    return (
      <>
        <Typography variant="h5" style={{margin: 16, marginBottom: 0}}>
          Latest Support Cases
        </Typography>
        <div className={classes.root}>
          <div className={classes.scroll}>
            {supportCases && supportCases.map(supportCase => <SupportCaseCard
              key={supportCase.id}
              onClick={() => this.props.history.push(routes.supportCaseDetails(supportCase.id) + location.search)}
              supportCase={supportCase}
            />)}
            {!supportCases && <div style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}><Spinner/></div>}
            <div style={{width: 16, display: 'inline-block'}}/>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(localStyles)(SupportCaseScroller);
