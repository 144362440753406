import {myAxios} from '../util/myAxios';

/** Mirrors [com.kurzdigital.remotexpert.spreed.SpreedAuthRequest] */
export interface SpreedAuthRequest {
  id: string;
  sid: string;
}

export interface SpreedAuthResponse {
  userid: string;
  nonce: string;
}

export function authenticateSpreedSession(request: SpreedAuthRequest): Promise<SpreedAuthResponse> {
  return myAxios().then(axios => axios.post(`/api/spreed/authenticate`, request)
    .then(response => response.data));
}
