import log from '../myLog';
import {Config} from "../../api/configApi";
import {AccountDto} from "../../api/userTypes";
import {SpreedWebsocket} from "./SpreedWebsocket";
import {ChunkedDataChannel} from "./ChunkDataChannel";
import {addStream, closeStream} from "./MediaStreamUtils";
import {BaseEvent} from "../../events/BaseEvent";

const logger = log.getLogger('SpreedRoom');

interface User {
  readonly userId: number,
  readonly spreedId: string
}

/**
 * Represents a spreed room.
 */
export class SpreedRoom {

  // The listeners
  private welcomeListeners: Array<(ownSpreedId: string, userCount: number) => void> = [];
  private userJoinListeners: Array<(user: User, hasJoinedBeforeMe: boolean) => void> = [];
  private userMediaStreamListener: Array<(user: User, stream: MediaStream) => void> = [];
  private userLeaveListeners: Array<(user: User, expected: boolean) => void> = [];
  private disconnectedListeners: Array<() => void> = [];
  private dataChannelListeners: Array<(user: User, event: BaseEvent) => void> = [];

  // The key is the spreed user id (NOT the "normal" user id!)
  private connectedUsers: Map<string, SpreedUser> = new Map();
  // Maps a spreed user id to a "real" user id
  private userIdToSpreedId: Map<number, string> = new Map();
  // Maps a "real" user id to a spreed user id
  private spreedIdToUserId: Map<string, number> = new Map();

  // The spreed user ids of the users that were in the room when we received the welcome packet
  // This is used to dispatch the request-status packet
  private initialUsersWhenJoined: Array<string> = [];

  private readonly config: Config;
  private readonly roomName: string;
  private readonly account: AccountDto;
  private localStream: MediaStream;

  private spreedWebsocket?: SpreedWebsocket;
  private disconnected = false;

  public constructor(config: Config, roomName: string, account: AccountDto, localStream: MediaStream) {
    this.config = config;
    this.roomName = roomName;
    this.account = account;
    this.localStream = localStream;

    this.addDataChannelEventListener((user, event) => {
      logger.debug('Received event from user with id ', user.userId, ':', event);
    });
  }

  /**
   * Adds a listener that gets called when we successfully connected to the spreed room.
   */
  public addWelcomeListener(listener: (ownSpreedId: string, userCount: number) => void) {
    this.welcomeListeners.push(listener);
  }

  /**
   * Adds a listener that gets called when a user joins the spreed room.
   *
   * <p>Also gets dispatched for every user that was in the room when we joined it.
   */
  public addUserJoinListener(listener: (user: User, hasJoinedBeforeMe: boolean) => void) {
    this.userJoinListeners.push(listener);
  }

  /**
   * Adds a listener that gets called when a user media stream changes or gets available after a join.
   */
  public addUserMediaStreamListener(listener: (user: User, stream: MediaStream) => void) {
    this.userMediaStreamListener.push(listener);
  }

  /**
   * Adds a listener that gets called when a user leaves the spreed room.
   */
  public addUserLeaveListener(listener: (user: User, expected: boolean) => void) {
    this.userLeaveListeners.push(listener);
  }

  /**
   * Adds a listener that gets called when we disconnect from the spreed room.
   */
  public addDisconnectedListener(listener: () => void) {
    this.disconnectedListeners.push(listener);
  }

  /**
   * Adds a listener that gets called when we receive a data channel event.
   */
  public addDataChannelEventListener(listener: (user: User, event: BaseEvent) => void) {
    this.dataChannelListeners.push(listener);
  }

  /**
   * Replaces the current local stream, e.g. to switch the camera view from front to back camera.
   */
  public replaceLocalStream(stream: MediaStream) {
    this.localStream = stream;
    // Tell the other users about the new stream
    this.connectedUsers.forEach(user => {
      user.peerConnection.getSenders().forEach(sender => {
        const newTrack = stream.getTracks().find(track => track.kind && sender.track && track.kind === sender.track.kind);
        sender.replaceTrack(newTrack || null).then()
      })
    })
  }

  /**
   * Sends the given event to the user with the given spreed id..
   */
  public dispatchEvent(spreedId: string, event: BaseEvent) {
    const eventAsByteArray = new TextEncoder().encode(JSON.stringify(event));
    const user = this.connectedUsers.get(spreedId);
    if (user) {
      user.dataChannel.sendMessage(eventAsByteArray.buffer);
      logger.debug('Sent event to user with spreed id ', user.spreedId, ':', event);
    } else {
      logger.debug('Cannot send event to user with spreed id ', spreedId, ', because he\'s not connected:', event)
    }
  }

  /**
   * Broadcasts the given event to all other users.
   */
  public broadcastEvent(event: BaseEvent) {
    const eventAsByteArray = new TextEncoder().encode(JSON.stringify(event));
    this.connectedUsers.forEach(user => {
      user.dataChannel.sendMessage(eventAsByteArray.buffer);
    });
    logger.debug('Broadcasted event:', event);
  }

  /**
   * Connects to the spreed room.
   * You should call this after registering your listeners.
   */
  public connect() {
    if (this.spreedWebsocket) {
      throw new Error('Multiple calls to #connect() are now allowed!');
    }
    this.spreedWebsocket = new SpreedWebsocket({
      config: this.config,
      roomName: this.roomName,
      account: this.account,
      callbacks: {
        onWelcome: this.handleWelcome.bind(this),
        onPeerJoined: this.handlePeerJoined.bind(this),
        onPeerLeft: this.handlePeerLeft.bind(this),
        onPeerBye: this.handlePeerBye.bind(this),
        onPeerOffer: this.handlePeerOffer.bind(this),
        onPeerAnswer: this.handlePeerAnswer.bind(this),
        onPeerIceCandidate: this.handlePeerIceCandidate.bind(this),
        onConnectionLost: this.handleConnectionLost.bind(this)
      },
    });
  }

  /**
   * Disconnects from the spreed room.
   */
  public disconnect() {
    if (!this.spreedWebsocket) {
      throw new Error('Cannot disconnect without calling #connect() first!');
    }
    if (this.disconnected) {
      // We allow multiple disconnects, but ignore them
      return;
    }
    this.disconnected = true;
    this.connectedUsers.forEach(user => {
      this.spreedWebsocket!!.sendBye(user.spreedId);
      user.peerConnection.close();
      if (user.mediaStream) {
        closeStream(user.mediaStream);
      }
    });
    this.spreedWebsocket.close();

    this.disconnectedListeners.forEach(listener => listener());
  }

  /**
   * Creates a new peer connection with the given spreed user.
   */
  private createPeerConnection(spreedId: string): RTCPeerConnection {
    logger.debug('Creating peer connection with spreed user ', spreedId);

    const rtcConfig: RTCConfiguration = {
      iceServers: [{
        urls: [this.config.turnServerUrl],
        username: 'kds',
        // TODO It's probably not a good idea to have hardcoded credentials.
        credential: 'kurzdigital2016',
      }]
    };

    const peerConnection = new RTCPeerConnection(rtcConfig);

    peerConnection.ontrack = event => {
      logger.debug(`peerConnection.ontrack triggered`);
      const stream = event.streams[0];
      const user = this.connectedUsers.get(spreedId);
      if (user != null) {
        user.mediaStream = stream;
        this.userMediaStreamListener
          .forEach(listener => listener({userId: user.userId, spreedId}, stream));
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      logger.debug('ICE connection state for user with spreed id ', spreedId,
        ' changed to ', peerConnection.iceConnectionState);
    };

    peerConnection.onsignalingstatechange = () => {
      logger.debug(`Signaling state changed to ${peerConnection.signalingState}`);
      const user = this.connectedUsers.get(spreedId);
      user!!.isNegotiating = peerConnection.signalingState !== 'stable';
    };

    peerConnection.onicecandidate = (evt: RTCPeerConnectionIceEvent) => {
      logger.debug(`peerConnection.onicecandidate, candidate: ${JSON.stringify(evt.candidate)}`);
      if (!evt.candidate) {
        return;
      }
      this.spreedWebsocket!!.sendIceCandidate(spreedId, evt.candidate);
    };

    peerConnection.ondatachannel = event => {
      logger.debug(`peerConnection.ondatachannel triggered, id: ${event.channel.id}`);
      // We don't need this, because we open the data channel on both sides
    };

    peerConnection.onicegatheringstatechange = () => {
      logger.debug(`peerConnection.onicegatheringstatechange triggered, iceGatheringState: ${peerConnection.iceGatheringState}`);
    };

    peerConnection.onnegotiationneeded = async () => {
      const user = this.connectedUsers.get(spreedId);
      if (user!!.isNegotiating) {
        logger.info('nested onnegotiationneeded detected, ignoring');
        return;
      }
      logger.debug(`peerConnection.onnegotiationneeded triggered`);
      user!!.isNegotiating = true;
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);
      this.spreedWebsocket!!.sendOffer(spreedId, offer);
    };

    return peerConnection;
  }

  private handleDataChannelMessage(user: User, message: Uint8Array) {
    const strMessage = new TextDecoder("utf-8").decode(message);
    const event = JSON.parse(strMessage) as BaseEvent;
    this.dataChannelListeners.forEach(listener => listener(user, event))
  }

  private handleWelcome(ownSpreedId: string, otherUsers: Array<{ spreedId: string, userId: number }>) {
    otherUsers.forEach(value => {
      this.userIdToSpreedId.set(value.userId, value.spreedId);
      this.spreedIdToUserId.set(value.spreedId, value.userId);
      this.initialUsersWhenJoined.push(value.spreedId);
    });
    this.welcomeListeners.forEach(listener => listener(ownSpreedId, otherUsers.length));
  }

  private handlePeerJoined(spreedId: string, userId: number) {
    // The current user connected again, probably from another browser window
    if (userId == this.account.id) {
      window.alert('Another client took over the call');
      this.disconnect();
      return;
    }

    const existingSpreedId = this.userIdToSpreedId.get(userId);
    if (existingSpreedId != null) {
      const user = this.connectedUsers.get(existingSpreedId);
      if (user != null) {
        user.peerConnection.close();
        if (user.dataChannel) {
          user.dataChannel.rtcChannel.close();
        }
        this.connectedUsers.delete(existingSpreedId);
      }
      this.userIdToSpreedId.delete(userId);
      this.spreedIdToUserId.delete(spreedId);
    }

    this.userIdToSpreedId.set(userId, spreedId);
    this.spreedIdToUserId.set(spreedId, userId);
    const user = new SpreedUser(userId, spreedId, this.createPeerConnection(spreedId));
    user.dataChannel.onMessage = message => this.handleDataChannelMessage({userId, spreedId}, message);
    this.connectedUsers.set(spreedId, user);

    if (this.localStream) {
      addStream(user.peerConnection, this.localStream);
    }

    // Dispatch user join listener
    this.userJoinListeners.forEach(listener => listener({userId, spreedId}, false));
  }

  private handlePeerLeft(spreedId: string) {
    const userId = this.spreedIdToUserId.get(spreedId);
    if (userId) {
      this.connectedUsers.delete(spreedId);
      this.spreedIdToUserId.delete(spreedId);
      this.userIdToSpreedId.delete(userId);
      this.userLeaveListeners.forEach(listener => listener({userId, spreedId}, false));
    }
  }

  private handlePeerBye(spreedId: string) {
    const userId = this.spreedIdToUserId.get(spreedId);
    if (userId) {
      this.connectedUsers.delete(spreedId);
      this.spreedIdToUserId.delete(spreedId);
      this.userIdToSpreedId.delete(userId);
      this.userLeaveListeners.forEach(listener => listener({userId, spreedId}, true));
    }
  }

  private async handlePeerOffer(spreedId: string, offer: RTCSessionDescription) {
    const userId = this.spreedIdToUserId.get(spreedId);
    if (userId == null) {
      logger.warn('Received offer from user with unknown id. This should not happen...');
      return;
    }

    let user = this.connectedUsers.get(spreedId);
    if (user == null) {
      user = new SpreedUser(userId, spreedId, this.createPeerConnection(spreedId));
      user.dataChannel.onMessage = message => this.handleDataChannelMessage({userId, spreedId}, message);
      this.connectedUsers.set(spreedId, user);
      addStream(user.peerConnection, this.localStream);
      if (this.initialUsersWhenJoined.indexOf(spreedId) != -1) {
        this.userJoinListeners.forEach(listener => listener({userId, spreedId}, true));
      }
    }

    user.isNegotiating = true;
    await user.peerConnection.setRemoteDescription(offer);
    const answer = await user!!.peerConnection.createAnswer();
    await user!!.peerConnection.setLocalDescription(answer);
    this.spreedWebsocket!!.sendAnswer(spreedId, answer);
  }

  private handlePeerAnswer(spreedId: string, answer: RTCSessionDescription) {
    let user = this.connectedUsers.get(spreedId);
    if (user != null) {
      user.peerConnection.setRemoteDescription(answer).then();
    }
  }

  private handlePeerIceCandidate(spreedId: string, candidate: RTCIceCandidate) {
    let user = this.connectedUsers.get(spreedId);
    if (user != null) {
      user.peerConnection.addIceCandidate(candidate).catch(error => {
        logger.warn("Failure during addIceCandidate", error);
      });
    }
  }

  private handleConnectionLost() {
    this.disconnect();
  }

}

// An internal helper class
class SpreedUser {

  public readonly userId: number;
  public readonly spreedId: string;
  public readonly peerConnection: RTCPeerConnection;
  public readonly dataChannel: ChunkedDataChannel;

  // Flag for workaround for chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=740501
  // solution described here: https://blog.mozilla.org/webrtc/the-evolution-of-webrtc/
  public isNegotiating = false;

  public mediaStream?: MediaStream;

  public constructor(userId: number, spreedId: string, peerConnection: RTCPeerConnection) {
    this.userId = userId;
    this.spreedId = spreedId;
    this.peerConnection = peerConnection;
    const rtcDataChannel = peerConnection.createDataChannel('main', {negotiated: true, id: 0});
    this.dataChannel = new ChunkedDataChannel(rtcDataChannel);
  }

}
