const partition = <T extends any>(pred: ((v: T) => boolean), arr: T[]): [T[], T[]] =>
  arr.reduce(
    (acc: [T[], T[]], e: T) => {
      acc[pred(e) ? 0 : 1].push(e);
      return acc;
    },
    [[], []]);

export interface Picker<T> {
  pick: (pred: (e: T) => boolean) => T[];
  remaining: () => T[];
}

/**
 * @returns A picker that allows wo successively consume the elements of `list`.
 * @example
 * const picker = createPicker([1, 2, 3]);
 * picker.pick(e => e > 1); // => [2,3]
 * picker.remaining(); // => [1]
 */
export function createPicker<T>(list: T[]): Picker<T> {
  let remaining = list;
  return {
    pick: (pred: (e: T) => boolean): T[] => {
      const [matches, r] = partition(pred, remaining);
      remaining = r;
      return matches;
    },
    remaining: () => remaining,
  };
}
