/**
 * Closes any tracks of the given stream.
 * Does nothing if given `stream` is `undefined` or `null`.
 * @returns {undefined} for compact assignments, like `this.stream = closeStream(this.stream);`
 */
export function closeStream(stream?: MediaStream | null): undefined {
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }
  return undefined;
}

export function waitForChannelToOpen(channel: RTCDataChannel): Promise<RTCDataChannel> {
  return new Promise((resolve, reject) => {
    channel.onopen = () => resolve(channel);
  });
}

export function addStream(peerConnection: RTCPeerConnection, stream: MediaStream) {
  stream && stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));
}
