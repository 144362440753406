/**
 * Maps `org.springframework.data.domain.Page`
 */
export interface Page<T> {
  first: boolean;
  last: boolean;
  number: number;
  totalElements: number;
  totalPages: number;
  content: T[];
}

export function emptyPage(): Page<never> {
  return {
    first: true,
    last: true,
    number: 0,
    totalElements: 0,
    totalPages: 0,
    content: [],
  };
}

export interface Pageable {
  /** page index, starts at 0 */
  page?: number;
  /** number of elements per page */
  size?: number;
  sort?: Sort;
  search?: string;
}

export interface Sort {
  field: string;
  order?: SortOrder;
}

export type SortOrder = 'asc' | 'desc';

export function defaultPageable(): Pageable {
  return {
    page: 0,
    size: 20,
  };
}

export function fullPageable(): Pageable {
  return {
    page: 0,
    size: 9999,
  };
}

/**
 * Converts `pageable` into URL-parameters that Spring understands as `org.springframework.data.domain.Pageable`.
 * With the addition of the `search` parameter.
 */
export function toUrlParams(pageable: Pageable): string {
  const arr = [];
  pageable.page !== undefined && arr.push(`page=${pageable.page}`);
  pageable.page !== undefined && arr.push(`size=${pageable.size}`);
  if (pageable.sort) {
    arr.push(`sort=${pageable.sort.field}${pageable.sort.order === undefined ? '' : `,${pageable.sort.order}`}`);
  }
  if (pageable.search) {
    arr.push(`search=${encodeURIComponent(pageable.search)}`);
  }
  return arr.join('&');
}
