/**
 * Type of predicates that take a User account parameter.
 * We use this so often, we give it a brief name.
 */
import {AccountDto, Role, RoleEnum} from './api/userTypes';

export const hasRole = (role: Role) => (account: AccountDto): boolean => account.roles.indexOf(role) >= 0;
export const isPlatformAdmin = hasRole(RoleEnum.PLATFORM_ADMIN);
export const isTenantAdmin = hasRole(RoleEnum.TENANT_ADMIN);
export const isSupportAgent = hasRole(RoleEnum.SUPPORT_AGENT);
export const isEndUser = hasRole(RoleEnum.END_USER);
