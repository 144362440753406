import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {InvitationCreatedNotification} from "../notifications/InvitationCreatedNotification";
import {declineInvite} from "../api/inviteApi";
import {NotificationHandlerContext, NotificationListener} from "./NotificationHandler";
import IncomingCallDialog from "./dialogs/IncomingCallDialog";
import {StopRingingNotification} from "../notifications/StopRingingNotification";

export const InvitationCreatedHandler: React.FC = () => {
  const [notificationState, setNotificationState] = useState<InvitationCreatedNotification | null>(null)
  const notificationHandler = useContext(NotificationHandlerContext);
  useEffect(() => {
    const listener: NotificationListener = n => {
      if (n.type == 'INVITATION_CREATED') {
        const invitationCreatedNotification = n as InvitationCreatedNotification;
        setNotificationState(prevNotification => {
          if (prevNotification != null) {
            const invite = invitationCreatedNotification.payload.invite;
            // Decline an incoming invite when we are still displaying a previous invite
            // We wait for a few seconds to give the caller time to join the room. Otherwise
            // he might be too slow and get the information that the call has already ended.
            setTimeout(() => declineInvite(invite.id), 5000);
            return prevNotification;
          }
          return invitationCreatedNotification;
        });
      }
      if (n.type == 'STOP_RINGING') {
        const stopRingingNotification = n as StopRingingNotification;
        setNotificationState(prevNotification => {
          if (prevNotification != null) {
            const inviteId = stopRingingNotification.payload.inviteId;
            if (prevNotification.payload.invite.id == inviteId) {
              return null;
            }
          }
          return prevNotification;
        });
      }
    };
    notificationHandler.addListener(listener);
    return () => notificationHandler.removeListener(listener);
  }, []);
  return notificationState ? <IncomingCallDialog
    notification={notificationState}
    handleDialogClose={() => setNotificationState(null)}
  /> : null;
};
