import {useEffect, useState} from "react";
import {currentShownState, onStartHandler, onStopHandler} from "../../androidApi";

/**
 * A hook that provides whether the android app is currently shown.
 * If not on android, is always true.
 */
export function useAndroidShown(): boolean {
  const [shown, setShown] = useState(currentShownState);
  useEffect(() => {
    const disposables = [
      onStartHandler.on(() => setShown(true)),
      onStopHandler.on(() => setShown(false)),
    ];
    return () => {
      disposables.forEach(d => d.dispose());
    }
  });
  return shown;
}
