import {myAxios} from '../util/myAxios';
import {Invite} from "./inviteTypes";

export function getInvite(inviteId: number): Promise<Invite> {
  return myAxios()
    .then(axios => axios.get(`/api/invites/${inviteId}`))
    .then(response => response.data);
}

export function acceptInvite(inviteId: number): Promise<Invite> {
  return myAxios()
    .then(axios => axios.post(`/api/invites/${inviteId}/accept`))
    .then(response => response.data);
}

export function declineInvite(inviteId: number, noPush: boolean = false): Promise<Invite> {
  return myAxios()
    .then(axios => axios.post(`/api/invites/${inviteId}/decline?no_push=${noPush ? '1' : '0'}`))
    .then(response => response.data);
}
