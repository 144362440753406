import * as React from 'react';
import {MouseEventHandler, ReactElement, ReactNode, useEffect, useState} from 'react';
import {StyledComponentProps, Theme} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import {createStyles, makeStyles} from "@material-ui/styles";
import {myAxios} from "../util/myAxios";
import Spinner from "../spinner/Spinner";

type LocalStyles = 'root' | 'image' | 'placeholderImage' | 'placeholderIcon';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: theme.shape.borderRadius,
  },
  placeholderImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.imagePlaceholderBackground,
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%',
    borderRadius: theme.shape.borderRadius,
  },
  placeholderIcon: {
    color: '#fff',
    fontSize: 40,
  },
}));

/**
 * Displays a given image or a placeholder if absent.
 */
export const ImageContainer = (props: {
  imageHref: string | null;
  onClick?: MouseEventHandler<HTMLElement>;
  width?: number | string;
  height?: number | string;
  children?: ReactNode;
} & StyledComponentProps<LocalStyles>): ReactElement => {
  const styles = useStyles(props);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  useEffect(() => {
    if (!props.imageHref) return;
    const x = props.imageHref;
    myAxios()
      .then(axios => axios.get(x, {responseType: 'blob'}))
      .then(response =>
        URL.createObjectURL(response.data))
      .then(setDataUrl);
  }, [props.imageHref]);
  return (
    <div
      className={styles.root}
      onClick={props.onClick}
      style={{height: props.height || 100, width: props.width || 100}}
    >
      {props.imageHref
        ? (dataUrl
          ? <img src={dataUrl} className={styles.image}/>
          : <Spinner/>)
        : (<div className={styles.placeholderImage}>
          <ImageIcon className={styles.placeholderIcon}/>
        </div>)
      }
      {props.children}
    </div>);
};
