import {useScannedItems} from "./useScannedItems";

/**
 * Checks if the user has scanned items. It's null while fetching them.
 */
export function useHasScannedItems() {
  const scannedItems = useScannedItems(0, 1);
  if (scannedItems == null) {
    return null;
  }
  return scannedItems.length > 0;
}
