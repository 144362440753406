import * as React from 'react';
import {createStyles, makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {CHAT_WINDOW_HEADER_HEIGHT} from "./ChatWindow";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    cursor: 'grabbing',
    height: CHAT_WINDOW_HEADER_HEIGHT,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '5px 5px 0 0',
    }
  },
  text: {
    fontSize: 24,
    marginLeft: 12,
    paddingTop: 9,
  },
  closeButton: {
    position: 'absolute',
    right: 7,
    padding: 6
  }
}));

export function ChatWindowHeader(props: {
  onClose(): void
}) {
  const {root, text, closeButton} = useStyles();
  return (
    <div className={root + ' chatHeader'}>
      <Typography color="textPrimary" className={text}>
        Chat
        <IconButton className={closeButton + ' chatCloseButton'} onClick={props.onClose}>
          <CloseIcon/>
        </IconButton>
      </Typography>
    </div>
  );
}
