import {HasLinks} from './restApi';

export interface ClientRequest {
  name: string;
  address: Address;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  imageId: number | null;
}

export interface Client extends ClientRequest, HasLinks {
  id: number;
  name: string;
  _links: {
    self: string;
    image: string | null;
  };
}

export function emptyClientRequest(): ClientRequest {
  return {
    name: '',
    address: emptyAddress(),
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    imageId: null,
  };
}

export interface Address {
  addressLine: string;
  postalCode: string;
  city: string;
  country: string;
}

export function emptyAddress(): Address {
  return {
    addressLine: '',
    postalCode: '',
    city: '',
    country: '',
  };
}
