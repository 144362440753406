import {useEffect, useState} from "react";
import {Item} from "../../api/itemTypes";
import {loadItems} from "../../api/itemApi";

/**
 * Gets an array with all scanned items. It's null while fetching them.
 */
export function useScannedItems(page: number, size: number) {
  const [items, setItems] = useState(null as Array<Item> | null);
  useEffect(() => {
    loadItems({}, {page, size}).then(page => page.content).then(setItems);
  }, [page, size]);
  return items;
}
