import * as React from 'react';

/**
 * A hidden input field for file upload.
 */
export function HiddenInput(props: {
  id: string,
  accept: string,
  onUpload(file: File): Promise<any>
}) {
  return (
    <input
      id={props.id}
      type="file"
      accept={props.accept}
      style={{display: 'none'}}
      onChange={async event => {
        event.preventDefault();
        // Check that the files length so avoid issue with IE11 calling the handler twice,
        // see also https://github.com/facebook/react/issues/8793
        const target = event.target;
        if (target.files != null && target.files.length > 0) {
          const file = target.files[0];
          await props.onUpload(file);
        }
        target.value = ''; // Reset value, so user may upload the same filename again
        // this.menuAnchorEl = undefined;
      }}
    />
  );
}
