import * as React from 'react';
import {CONTAINER_MAX_WIDTH} from "../../styles/muiTheme";
import BottomNavigation from "@material-ui/core/BottomNavigation/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction/BottomNavigationAction";
import LinkIcon from '@material-ui/icons/Link';
import {ItemOutlineIcon} from '../../styles/icons';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {StyleRulesCallback, Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {LandingPageTab} from "./LandingPage";

type Props = {
  currentTab: LandingPageTab
  onClick(tab: LandingPageTab): void
} & WithStyles<LocalStyles>;

export type LocalStyles = 'root';

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    maxWidth: CONTAINER_MAX_WIDTH,
  }
});

class LandingPageBottomNavigation extends React.PureComponent<Props> {

  render() {
    const {classes, currentTab, onClick} = this.props;
    return (
      <BottomNavigation showLabels={true} value={currentTab} className={classes.root}>
        <BottomNavigationAction
          label="Assigned Items"
          value={LandingPageTab.AssignedItems}
          icon={<LinkIcon/>}
          onClick={() => onClick(LandingPageTab.AssignedItems)}
        />
        <BottomNavigationAction
          label="All Items"
          value={LandingPageTab.AllItems}
          icon={<ItemOutlineIcon/>}
          onClick={() => onClick(LandingPageTab.AllItems)}
        />
        <BottomNavigationAction
          label="Contacts"
          value={LandingPageTab.Contacts}
          icon={<AccountBoxIcon/>}
          onClick={() => onClick(LandingPageTab.Contacts)}
        />
      </BottomNavigation>
    );
  }
}

export default withStyles(localStyles)(LandingPageBottomNavigation);
