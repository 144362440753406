import * as React from 'react';
import List from '@material-ui/core/List/List';
import {Item} from "../../../api/itemTypes";
import Typography from "@material-ui/core/Typography/Typography";
import {ItemListElement} from "./ItemListElement";
import Spinner from "../../../spinner/Spinner";

type Props = {
  items: Array<Item> | null,
  headline: string,
  noItemsText: string
};

export class ItemList extends React.PureComponent<Props> {

  render() {
    const {items, headline, noItemsText} = this.props;
    if (items && items.length <= 0) {
      return (
        <Typography variant="h5" align="center" style={{marginTop: 150}}>
          {noItemsText}
        </Typography>
      );
    }
    return (
      <>
        <Typography variant="h5">
          {headline}
        </Typography>
        {!items && <Spinner/>}
        <List>
          {items && items.map(item => (
            <ItemListElement
              key={item.id}
              item={item}
            />
          ))}
        </List>
      </>
    );
  }
}

export default ItemList;
