import * as React from 'react';
import {useEffect, useState} from 'react';
import EmbeddedPage from "../../components/EmbeddedPage";
import {AppBarTopPadding} from "../../components/AppBarTopPadding";
import {LandingPageAppBar} from "../normal/LandingPageAppBar";
import * as platformApi from '../../api/platformApi';
import {CallStats} from '../../api/platformApi';
import {Page} from "../../query/pageable";
import Spinner from "../../spinner/Spinner";
import {
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import moment from "moment";
import {makeStyles} from "@material-ui/styles";

const rowsPerPage = 10;

const useStyles = makeStyles({
  listItemTextRoot: {
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export function CallListPage(props: {}) {

  const [pageIndex, setPageIndex] = useState(0);
  const [page, setPage] = useState<Page<CallStats> | null>(null);
  const classes = useStyles();

  useEffect(() => {
    platformApi.getCallStats({
      page: pageIndex,
      size: rowsPerPage,
    }).then(setPage);
  }, [pageIndex, setPage]);
  if (!page) {
    return <Spinner/>;
  }
  return (
    <EmbeddedPage>
      <LandingPageAppBar title="Call List"/>
      <AppBarTopPadding/>

      <Table size="small">
        <TableHead>
          <TableRow>
            <MyTablePagination
              page={page}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          </TableRow>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Tenant</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Screenshots</TableCell>
            <TableCell>Chats</TableCell>
            <TableCell>Participants (id, role, user agent)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {page.content.map(row =>
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.tenantId}</TableCell>
              <TableCell>{moment(row.created).format('L LT')}</TableCell>
              <TableCell>{row.duration ? `${moment.duration(row.duration).seconds()}s` : '-'}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.screenshotCount || '-'}</TableCell>
              <TableCell>{row.chatMessageCount || '-'}</TableCell>
              <TableCell>
                <List dense={true} disablePadding={true}>{row.participants.map(p =>
                  <ListItem key={p.id}>
                    <Tooltip title={`${p.id} ${p.roles.join(",")} ${p.userAgent}`}>
                      <ListItemText
                        classes={{
                          root: classes.listItemTextRoot,
                        }}
                      >{p.id} {p.roles.join(",")} <Typography
                        variant="caption">{p.userAgent}</Typography></ListItemText>
                    </Tooltip>
                  </ListItem>
                )}
                </List>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <MyTablePagination
              page={page}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </EmbeddedPage>
  );
}

function MyTablePagination(props: {
  page: Page<CallStats>,
  pageIndex: number,
  setPageIndex: (index: number) => void,
}) {
  return (
    <TablePagination
      count={props.page.totalElements}
      onChangePage={(ev, pageIndex) => {
        props.setPageIndex(pageIndex);
      }}
      page={props.pageIndex}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[rowsPerPage]}
    />);
}
