import * as React from 'react';
import {CSSProperties} from 'react';
import {Call, Screenshot} from '../../../../api/callTypes';
import {CallCard, MissedCallsCard, useMissedCallsRecord} from './CallCard';
import {SupportCase} from '../../../../api/supportCaseTypes';
import {Typography} from '@material-ui/core';

export function CallList(props: {
  supportCase: SupportCase,
  calls: Array<Call>,
  className?: string,
  style?: CSSProperties,
  onChatClick(call: Call): void,
  onScreenshotClick: (screenshot: Screenshot) => void,
}) {
  const {calls} = props;
  const missedCallsRecord = useMissedCallsRecord(props.supportCase);
  return (
    <div className={props.className} style={props.style}>
      {missedCallsRecord && <MissedCallsCard missedCallRecord={missedCallsRecord}/>}
      {calls.map(call => (
        <CallCard
          key={call.id}
          call={call}
          onChatClick={() => props.onChatClick(call)}
          onScreenshotClick={props.onScreenshotClick}
        />
      ))}
      {calls && calls.length <= 0 && !missedCallsRecord && (
        <Typography variant="body1">
          There are no calls for this support case!
        </Typography>
      )}
    </div>
  );
}
