import * as React from "react";
import {MenuItem} from "@material-ui/core";

type Props = {
  text: string,
  url: string,
};

class LinkMenuItem extends React.PureComponent<Props> {

  render() {
    const {text, url} = this.props;
    return (
      <MenuItem onClick={() => window.open(url, '_blank')}>
        {text}
      </MenuItem>
    );
  }

}

export default LinkMenuItem;
