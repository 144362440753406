import {myAxios} from '../util/myAxios';
import {Call, CreateCallRequest, Screenshot} from './callTypes';
import {Invite} from "./inviteTypes";

export function createCall(callRequest: CreateCallRequest): Promise<Call> {
  return myAxios()
    .then(axios => axios.post(`/api/calls`, callRequest))
    .then(response => response.data);
}

export function getCall(callId: number): Promise<Call> {
  return myAxios()
    .then(axios => axios.get(`/api/calls/${callId}`))
    .then(response => response.data);
}

export function endCall(callId: number): Promise<Call> {
  return myAxios()
    .then(axios => axios.post(`/api/calls/${callId}/end`))
    .then(response => response.data);
}

export async function inviteUser(callId: number, inviteeId: number): Promise<Invite> {
  const axios = await myAxios();
  const response = await axios.post(`/api/calls/${callId}/invites`, {inviteeId});
  return response.data;
}

export function uploadScreenshot(callId: number, file: File, onProgress?: (percentage: number) => void): Promise<Screenshot> {
  return myAxios()
    .then(axios => {
      const form = new FormData();
      form.append('file', file, file.name || '');
      return axios.post(`/api/calls/${callId}/screenshots`, form, {
        onUploadProgress: (event) => {
          if (onProgress) {
            onProgress(event.loaded / event.total)
          }
        }
      });
    })
    .then(response => response.data);
}
