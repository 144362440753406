import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {AccountDto} from '../../../api/userTypes';
import {Call, getColorOfUser, isPartOfCall} from '../../../api/callTypes';
import {Config, getConfig} from '../../../api/configApi';
import {CallView} from "./CallView";
import {SpreedRoom} from "../../../util/webrtc/SpreedRoom";
import {Provider, Subscribe} from "unstated";
import {CallStateContainer} from "./state/CallStateContainer";
import {LocalStreamConsumerProps} from "./LocalStreamSupplier";
import {BaseEvent} from "../../../events/BaseEvent";
import Spinner from "../../../spinner/Spinner";
import {withSnackbar, WithSnackbarProps} from "notistack";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import {CommonStyles, commonStyles} from "../../../styles/commonStyles";
import {acceptInvite} from "../../../api/inviteApi";
import {endCall, getCall} from "../../../api/callApi";
import {BaseNotification} from "../../../notifications/BaseNotification";
import {InviteeDeclinedNotification} from "../../../notifications/InviteeDeclinedNotification";
import {Invite} from "../../../api/inviteTypes";
import {routes} from "../../../router/MyRouter";
import {ChatMessage} from "../../../api/chatTypes";
import {isSupportAgent} from "../../../permissions";
import {DrawingAndMarkerStateContainer} from "./state/DrawingAndMarkerStateContainer";
import {RemovePendingInviteReason} from "../../../events/RemovePendingInvite";
import {NotificationHandler, NotificationListener} from "../../../components/NotificationHandler";
import {CallAlreadyEndedPage} from "./CallAlreadyEndedPage";
import {ThemeProvider} from "@material-ui/styles";
import {defaultTheme} from "../../../styles/muiTheme";

type Props = RouteComponentProps<{}> & LocalStreamConsumerProps & WithSnackbarProps & WithStyles<CommonStyles> & {
  callId: number;
  account: AccountDto;
  notificationHandler: NotificationHandler;
};

type State = {
  call?: Call,
  config?: Config,
  ownSpreedId?: string,
  connectedUsers: Array<{ userId: number, spreedId: string, stream?: MediaStream }>,
  callStateContainerReady: boolean,
  navigating: boolean, // safe-guard to ensure we only trigger one history navigation
  callEndedGraceful: boolean, // Whether this call ended graceful, i.e. we hung up or the last partner left
};

class CallPage extends React.PureComponent<Props, State> {

  private closeActions: (Array<() => void>) = [];

  private spreedRoom?: SpreedRoom;
  private callStateContainer?: CallStateContainer;
  private drawingAndMarkerStateContainer = new DrawingAndMarkerStateContainer();

  private newMessageHandler?: (message: ChatMessage) => void;
  private takeScreenshotHandler?: (() => string) | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      connectedUsers: [],
      callStateContainerReady: false,
      navigating: false,
      callEndedGraceful: false,
    };
  }

  async componentDidMount() {
    const call = await getCall(this.props.callId);
    const config = await getConfig();
    this.setState({call, config});

    if (call.ended) {
      return;
    }

    const inviteAcceptPromises: Array<Promise<any>> = [];
    call.invites
      .filter(invite => invite.invitee.id == this.props.account.id)
      .filter(invite => invite.status == 'REQUESTED')
      .forEach(invite => inviteAcceptPromises.push(acceptInvite(invite.id)));

    if (inviteAcceptPromises.length <= 0) {
      if (!isPartOfCall(call, this.props.account.id)) {
        alert("You are not part of this call!");
        return;
      }
    } else {
      try {
        await Promise.all(inviteAcceptPromises)
      } catch (error) {
        alert("Your invite expired!");
        return;
      }
    }
    this.spreedRoom = this.joinSpreedRoom();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.stream != this.props.stream) {
      this.spreedRoom!!.replaceLocalStream(this.props.stream);
      // Replace the stream of our own user in "connectedUsers"
      this.setState(prevState => {
        const connectedUsers = [];
        for (let connectedUser of prevState.connectedUsers) {
          if (connectedUser.spreedId == prevState.ownSpreedId) {
            connectedUser = {...connectedUser, stream: this.props.stream};
          }
          connectedUsers.push(connectedUser);
        }
        return {connectedUsers};
      });
    }
  }

  componentWillUnmount() {
    const closeActions = this.closeActions;
    this.closeActions = [];
    closeActions.forEach(f => f());
  }

  render() {
    if (this.state.call && this.state.call.ended) {
      return (
        // TODO: Avoid nested ThemeProviders (one in MyRouter around CallPage and this one)
        <ThemeProvider theme={defaultTheme}>
          <CallAlreadyEndedPage call={this.state.call}/>;
        </ThemeProvider>
      );
    }
    if (!this.state.callStateContainerReady) {
      return <Spinner/>;
    }
    const firstInvite = this.state.call!!.invites[0];
    const isCaller = firstInvite.inviter.id === this.props.account.id;
    const inviterName = firstInvite.inviter.name ? `${firstInvite.inviter.name.first} ${firstInvite.inviter.name.last}` : 'Client';
    const inviteeName = firstInvite.invitee.name ? `${firstInvite.invitee.name.first} ${firstInvite.invitee.name.last}` : 'Client';
    const remoteUsername = isCaller ? inviteeName : inviterName;
    return (
      <>
        <Provider inject={[this.callStateContainer!!, this.drawingAndMarkerStateContainer]}>
          <Subscribe to={[CallStateContainer]}>{(callStateContainer: CallStateContainer) =>
            <CallView
              {...this.props}
              call={this.state.call!!}
              callStateContainer={callStateContainer}
              remoteUsername={remoteUsername}
              ownSpreedId={this.state.ownSpreedId}
              connectedUsers={this.state.connectedUsers}
              onLeaveCall={this.handleLeaveCall}
              newMessageHandlerRef={newMessageHandler => this.newMessageHandler = newMessageHandler}
              takeScreenshotHandlerRef={takeScreenshotHandler => this.takeScreenshotHandler = takeScreenshotHandler}
            />
          }
          </Subscribe>
        </Provider>
      </>
    );
  }

  private handleLargeScreenSwitch = (userSpreedId: string) => {
    const user = this.state.connectedUsers.find(user => user.spreedId == userSpreedId);
    if (user) {
      let text;
      if (userSpreedId === this.state.ownSpreedId) {
        text = 'Now showing you';
      } else {
        const userInfo = this.callStateContainer!!.state.knownUsers.get(userSpreedId);
        if (!userInfo) {
          // We don't have any information about this user, so we cannot show the notification!
          return;
        }
        const name = userInfo.name ? `${userInfo.name.first} ${userInfo.name.last}` : 'Client';
        text = `Now showing ${name}`;
      }
      this.props.enqueueSnackbar(
        text,
        {variant: 'info', autoHideDuration: 2000, className: this.props.classes.withMobileBottomMargin}
      );
    }
  };

  private handleUserHello = (name?: { first: string, last: string }) => {
    const displayName = name ? `${name.first} ${name.last}` : 'The Client';
    this.props.enqueueSnackbar(
      `${displayName} has joined the call`,
      {variant: 'info', autoHideDuration: 2000, className: this.props.classes.withMobileBottomMargin}
    );
  };

  private handleRemovePendingInvite = async (invite: Invite, reason: RemovePendingInviteReason) => {
    let name = 'invited user';
    if (invite.invitee.name) {
      name = `user ${invite.invitee.name.first} ${invite.invitee.name.last}`
    }

    switch (reason) {
      case 'CANCELED': {
        this.props.enqueueSnackbar(
          `Canceled invite for the ${name}`,
          {variant: 'info', autoHideDuration: 2000, className: this.props.classes.withMobileBottomMargin}
        );
        break;
      }
      case 'MISSED': {
        this.props.enqueueSnackbar(
          `The ${name} is currently not available`,
          {variant: 'warning', autoHideDuration: 2000, className: this.props.classes.withMobileBottomMargin}
        );
        break;
      }
    }
  };

  private joinSpreedRoom(): SpreedRoom {
    const room = new SpreedRoom(
      this.state.config!!,
      this.state.call!!.roomName,
      this.props.account,
      this.props.stream
    );

    room.addWelcomeListener(this.handleSpreedWelcome);
    room.addUserJoinListener(this.handleSpreedUserJoin);
    room.addUserMediaStreamListener(this.handleSpreedUserMediaStream);
    room.addUserLeaveListener(this.handleSpreedUserLeave);
    room.addDisconnectedListener(this.handleSpreedDisconnect);
    room.addDataChannelEventListener(this.handleDataChannelEvent);

    // Connect AFTER adding all the listeners
    room.connect();

    this.closeActions.push(() => this.spreedRoom && this.spreedRoom.disconnect());
    return room;
  }

  private handleLeaveCall = () => {
    this.setState({
      callEndedGraceful: true
    }, () => {
      if (this.spreedRoom) {
        if (this.state.connectedUsers.length <= 1) {
          // We are alone, so let's end the call
          endCall(this.state.call!!.id);
        }
        this.spreedRoom.disconnect();
      }
    });
  };

  private handleSpreedWelcome = (ownSpreedId: string, userCount: number) => {
    this.callStateContainer = new CallStateContainer(
      this.spreedRoom!!,
      {spreedId: ownSpreedId, userId: this.props.account.id},
      this.drawingAndMarkerStateContainer,
      this.handleLargeScreenSwitch,
      this.handleUserHello,
      this.handleRemovePendingInvite,
      this.handleChatMessage,
      this.handleTakeScreenshot,
      this.handleScreenshotSaved,
      getColorOfUser(this.state.call!!, this.props.account.id)!!,
      this.props.account.firstName ? {
        first: this.props.account.firstName,
        last: this.props.account.lastName
      } : undefined,
    );

    // Add all invitees to the list of pending invites.
    if (userCount <= 0) {
      this.state.call!!.invites.filter(invite => invite.status == 'REQUESTED').forEach(invite => {
        if (invite.invitee.id !== this.props.account.id) {
          this.callStateContainer!!.addToPendingInvites(invite);
        }
      });
    }
    const listener: NotificationListener = notification => {
      this.handleNotification(notification);
      this.callStateContainer!!.handleNotification(notification);
    };
    this.props.notificationHandler.addListener(listener);
    this.closeActions.push(() => this.props.notificationHandler.removeListener(listener));

    this.setState(prevState => ({
      callStateContainerReady: true,
      ownSpreedId,
      connectedUsers: [
        {userId: this.props.account.id, spreedId: ownSpreedId, stream: this.props.stream},
        ...prevState.connectedUsers
      ],
    }));
  };

  private handleSpreedUserJoin = (user: { userId: number, spreedId: string }, hasJoinedBeforeMe: boolean) => {
    this.setState(prevState => ({
      connectedUsers: [...prevState.connectedUsers, user].sort((a, b) => a.userId - b.userId)
    }));
    if (hasJoinedBeforeMe) {
      this.callStateContainer!!.sendHelloToUser(user.spreedId);
    }
    this.callStateContainer!!.removeFromPendingInvitesByUserId(user.userId, 'CANCELED');
    if (this.callStateContainer!!.state.largeScreen.timestamp <= 0) {
      if (this.state.call!!.supportCaseId) { // It's Support-Agent <=> Client - The Client should be large
        if (isSupportAgent(this.props.account)) {
          this.callStateContainer!!.setLargeScreen(user.spreedId);
        } else {
          this.callStateContainer!!.setLargeScreen(this.state.ownSpreedId!!);
        }
      } else { // It's Support-Agent <=> Support-Agent - The first user should be large
        if (hasJoinedBeforeMe) {
          this.callStateContainer!!.setLargeScreen(user.spreedId);
        } else {
          this.callStateContainer!!.setLargeScreen(this.state.ownSpreedId!!);
        }
      }
    }
  };

  private handleSpreedUserMediaStream = (user: { userId: number, spreedId: string }, stream: MediaStream) => {
    this.setState(prevState => {
      const connectedUsers = [];
      for (let connectedUser of prevState.connectedUsers) {
        if (connectedUser.spreedId == user.spreedId) {
          connectedUser = {...connectedUser, stream};
        }
        connectedUsers.push(connectedUser);
      }
      return {connectedUsers};
    });
  };

  private handleNotification = (notification: BaseNotification) => {
    if (notification.type == 'INVITEE_DECLINED') {
      if (this.state.call!!.invites[0].id == (notification as InviteeDeclinedNotification).payload.invite.id) {
        // The initial invite got declined, so lets leave the call (we don't have to end it, because it gets ended by
        // the backend automatically when the first invite gets declined)
        this.setState(
          {callEndedGraceful: true},
          () => this.navigateToItem()
        );
      }
    }
  };

  private handleChatMessage = (message: ChatMessage) => {
    if (this.newMessageHandler) {
      this.newMessageHandler(message);
    }
  };

  private handleTakeScreenshot = () => {
    if (this.takeScreenshotHandler) {
      return this.takeScreenshotHandler();
    }
    return null;
  };

  private handleScreenshotSaved = (user: { spreedId: string }, name?: { first: string, last: string }) => {
    let text = 'Screenshot saved';
    let variant: 'info' | 'success' = 'success';
    if (user.spreedId !== this.state.ownSpreedId) {
      let displayName = "The client";
      if (name) {
        displayName = `${name.first} ${name.last}`;
      }
      text = `${displayName} has saved a screenshot`;
      variant = 'info';
    }
    this.props.enqueueSnackbar(text, {
      variant,
      autoHideDuration: 2000,
      className: this.props.classes.withMobileBottomMargin
    });
  };

  private handleSpreedUserLeave = (user: { userId: number, spreedId: string }, expected: boolean) => {
    this.setState(prevState => {
      const connectedUsers = [];
      for (let connectedUser of prevState.connectedUsers) {
        if (connectedUser.spreedId != user.spreedId) {
          connectedUsers.push(connectedUser);
        }
      }

      const userInfo = this.callStateContainer!!.state.knownUsers.get(user.spreedId);
      if (userInfo) {
        let displayName = "The client";
        if (userInfo.name) {
          displayName = `${userInfo.name.first} ${userInfo.name.last}`;
        }
        this.props.enqueueSnackbar(
          `${displayName} has left the call`,
          {variant: 'info', autoHideDuration: 2000, className: this.props.classes.withMobileBottomMargin}
        );
      }

      if (connectedUsers.length <= 1) {
        // We are the last user in the room, so let's end the call
        this.setState({
          callEndedGraceful: true,
        }, () => {
          endCall(this.state.call!!.id);
          this.navigateToSupportCase();
        });
        return null;
      }

      if (prevState.connectedUsers.filter(user => user.spreedId === this.callStateContainer!!.state.largeScreen.userSpreedId).length <= 0) {
        const smallestSpreedId = prevState.connectedUsers.sort((a, b) => a.spreedId < b.spreedId ? 1 : -1)[0].spreedId;
        this.callStateContainer!!.setLargeScreen(smallestSpreedId);
      }

      return {connectedUsers};
    });
  };

  private handleSpreedDisconnect = () => {
    if (!this.state.callEndedGraceful) {
      this.props.enqueueSnackbar(
        `Call was disconnected`,
        {variant: 'warning', autoHideDuration: 5000, className: this.props.classes.withMobileBottomMargin}
      );
    }
    this.navigateToSupportCase();
  };

  private handleDataChannelEvent = (sender: { userId: number, spreedId: string }, event: BaseEvent) => {
    this.callStateContainer!!.handleEvent(sender, event, false);
  };

  private navigateToSupportCase = () => {
    if (this.state.navigating) return;
    this.setState({navigating: true});
    if (this.state.call && this.state.call.supportCaseId) {
      this.props.history.push(routes.supportCaseDetails(this.state.call.supportCaseId));
    } else {
      this.props.history.push(routes.home);
    }
  };

  private navigateToItem = () => {
    if (this.state.navigating) return;
    this.setState({navigating: true});
    if (this.state.call && this.state.call.itemId) {
      this.props.history.push(routes.itemDetails(this.state.call.itemId));
    } else {
      this.props.history.push(routes.home);
    }
  };
}

export default withRouter(withSnackbar(withStyles(commonStyles)(CallPage)));
