import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import EmbeddedPage from "../../../components/EmbeddedPage";
import {AppBarTopPadding} from "../../../components/AppBarTopPadding";
import {LandingPageAppBar} from "../LandingPageAppBar";
import {CameraCheck} from "./CameraCheck";
import {MicrophoneCheck} from "./MicrophoneCheck";
import {Button, createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

export type RunCheckFunc = () => Promise<void>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  checkAllButton: {
    margin: theme.spacing(),
  },
}));

export function CheckPage() {
  const classes = useStyles();
  const runCheckFuncs = useRef<RunCheckFunc[]>([]);
  const [currentCheck, setCurrentCheck] = useState(-1);
  useEffect(() => {
    if (currentCheck < 0) return;
    const f = runCheckFuncs.current[currentCheck];
    if (!f) {
      setCurrentCheck(-1);
      return;
    }
    f().then(() => {
      setCurrentCheck(s => s + 1);
    });
  }, [currentCheck]);
  return (
    <EmbeddedPage>
      <LandingPageAppBar title="Check Requirements" previousLocation={'/'}/>
      <AppBarTopPadding/>

      <Button
        className={classes.checkAllButton}
        variant="contained"
        color="primary"
        disabled={currentCheck >= 0}
        onClick={() => {
          setCurrentCheck(0);
        }}>
        Run all Checks
      </Button>

      <MicrophoneCheck setRunCheckFunc={f => {
        runCheckFuncs.current[0] = f;
      }}/>
      <CameraCheck setRunCheckFunc={f => {
        runCheckFuncs.current[1] = f;
      }}
      />
    </EmbeddedPage>
  );
}
