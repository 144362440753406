import * as React from 'react';
import {WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {emptyItemRequest, ItemRequest} from '../../../api/itemTypes';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import {IObservableArray, IObservableObject, IObservableValue, observable} from 'mobx';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import * as itemApi from '../../../api/itemApi';
import {isValidationErrors, ValidationErrors} from '../../../api/restApi';
import {observer} from 'mobx-react';
import {ItemForm} from './ItemForm';
import * as clientApi from '../../../api/clientApi';
import {fullPageable} from '../../../query/pageable';
import {Client} from '../../../api/clientTypes';
import * as supportAgentApi from '../../../api/supportAgentApi';
import {User} from '../../../api/userTypes';
import {Spinner} from '../../../spinner/Spinner';
import {DialogWithDirtyWarning} from '../../../components/dialogs/DialogWithDirtyWarning';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {MyDialogStyles, myDialogStyles} from '../../../styles/myDialogStyles';
import {ItemOutlineIcon} from '../../../styles/icons';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Typography from '@material-ui/core/Typography/Typography';
import {SingleClickButton} from '../../../components/SingleClickButton';

interface Props {
  open: boolean;
  onClose: () => void;
}

type PropsWithStyle = Props & WithStyles<MyDialogStyles>;

@observer
export class NewItemDialog extends React.Component<PropsWithStyle> {

  @observable item: ItemRequest & IObservableObject = observable(emptyItemRequest());
  @observable loading = true;
  clients: IObservableArray<Client> = observable.array();
  supportAgents: IObservableArray<User> = observable.array();
  dirtyState: IObservableValue<boolean> = observable.box(false);
  inProgressCounter: IObservableValue<number> = observable.box(0);
  @observable validationErrors: ValidationErrors | undefined = undefined;

  constructor(props: PropsWithStyle) {
    super(props);
    this.init = this.init.bind(this);
  }

  /**
   * This Dialog component needs to remain mounted for nicer animations, so
   * we use this function triggered by `Dialog.onRendered` instead of `componentDidMount`
   * for initialization.
   */
  init() {
    this.loading = true;
    this.item = observable(emptyItemRequest());
    this.clients.replace([]);
    this.supportAgents.replace([]);
    this.dirtyState.set(false);
    this.validationErrors = undefined;
    Promise.all([
      clientApi.loadClients({}, fullPageable()).then(clients => {
        this.clients.replace(clients.content);
      }),
      supportAgentApi.getAll({}, fullPageable()).then(agents => {
        this.supportAgents.replace(agents.content);
      })
    ]).then(() => {
      this.loading = false;
    });
  }

  render() {
    return (
      <DialogWithDirtyWarning
        id="newItemDialog"
        open={this.props.open}
        onClose={this.props.onClose}
        dirtyState={this.dirtyState}
        fullWidth={true}
        maxWidth="md"
        onRendered={this.init}
        render={closeHandler => (
          <>
            <DialogTitle disableTypography={true}>
              <ItemOutlineIcon className={this.props.classes.titleIcon}/>
              <Typography variant="h6">New Item</Typography>
              <IconButton
                onClick={closeHandler}
                className={this.props.classes.closeButton}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {this.loading
                ? <Spinner/>
                : <ItemForm
                  classes={this.props.classes}
                  clients={this.clients}
                  supportAgents={this.supportAgents}
                  item={this.item}
                  dirtyState={this.dirtyState}
                  inProgressCounter={this.inProgressCounter}
                  fieldErrors={this.validationErrors ? this.validationErrors.fieldErrors : []}
                />}
            </DialogContent>
            <DialogActions>
              <SingleClickButton
                buttonProps={{
                  id: "newItemDialog_button_save",
                  color: "primary",
                  variant: "contained",
                  disabled: this.inProgressCounter.get() > 0,
                }}
                onSingleClick={() =>
                  itemApi.createItem(this.item).then(data => {
                    if (isValidationErrors(data)) {
                      this.validationErrors = data;
                    } else {
                      this.validationErrors = undefined;
                      this.props.onClose();
                    }
                  })
                }
              >
                Create
              </SingleClickButton>
            </DialogActions>
          </>
        )}
      />
    );
  }
}

export default withStyles(myDialogStyles)(NewItemDialog);
