import * as React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";

type Props = {
  open: boolean,
  onClose?(): void,
};

class UnknownItemDialog extends React.Component<Props> {

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle>Unknown Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The scanned QR code does not belong to any item.
          </DialogContentText>
          <DialogContentText>
            Please scan another one or enter the Item-Id manually.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default UnknownItemDialog;
