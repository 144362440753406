import * as spreedApi from './spreedChannelingApi';
import * as spreedRestApi from '../../api/spreedRestApi';
import {Config} from '../../api/configApi';
import {AccountDto} from '../../api/userTypes';
import log from '../myLog';

export const logger = log.getLogger('SpreedWebsocket');

export interface SpreedWebsocketProps {
  config: Config;
  roomName: string;
  account: AccountDto;
  callbacks: SpreedWebsocketCallbacks;
}

export interface SpreedWebsocketCallbacks {
  /**
   * Joined the room.
   */
  onWelcome: (ownSpreedUserId: string, otherUsers: Array<{ spreedId: string, userId: number }>) => void;
  /**
   * A peer joined the room.
   * This peer may have been in this room before.
   * We should disconnect any previous connections and connect to the new one.
   */
  onPeerJoined: (spreedUserId: string, userId: number) => void;
  /**
   * A peer has left the room without closing the call correctly.
   * This should normally lead to an abort of the call, best with some buffer time in case the peer reconnects.
   */
  onPeerLeft: (spreedUserId: string) => void;
  /**
   * A peer sent a 'Bye' message, i.e. has signalled to close the call.
   */
  onPeerBye: (spreedUserId: string) => void;
  /**
   * A peer sent an offer
   */
  onPeerOffer: (spreedUserId: string, offer: RTCSessionDescription) => void;
  /**
   * A peer sent an answer
   */
  onPeerAnswer: (spreedUserId: string, answer: RTCSessionDescription) => void;
  /**
   * A peer sent an ICE candidate
   */
  onPeerIceCandidate: (spreedUserId: string, candidate: RTCIceCandidate) => void;
  /**
   * The connection to the spreed server was lost
   */
  onConnectionLost: () => void;
}

export class SpreedWebsocket {
  private props: SpreedWebsocketProps;

  private ws: WebSocket;
  private mySpreedId: string = '';

  constructor(props: SpreedWebsocketProps) {
    this.props = props;
    this.ws = this.createSpreedWebSocket();
    this.addLoggingToWebSocket();
  }

  sendOffer(spreedUserId: string, desc: RTCSessionDescriptionInit) {
    logger.info('Sending Offer to user with spreed id ', spreedUserId);
    this.ws.send(spreedApi.createOfferRequest(spreedUserId, desc));
  }

  sendAnswer(spreedUserId: string, desc: RTCSessionDescriptionInit) {
    logger.info('Sending Answer to user with spreed id ', spreedUserId);
    this.ws.send(spreedApi.createAnswerRequest(spreedUserId, desc));
  }

  sendIceCandidate(spreedUserId: string, candidate: RTCIceCandidate) {
    logger.debug('Sending ICE candidate to user with spreed id ', spreedUserId);
    this.ws.send(spreedApi.createCandidateRequest(spreedUserId, candidate));
  }

  sendBye(spreedUserId: string) {
    if (this.isSocketNotClosed()) {
      logger.debug('Sending Bye to user with spreed id ', spreedUserId);
      this.ws.send(spreedApi.createByeRequest(spreedUserId));
    }
  }

  close() {
    if (this.isSocketNotClosed()) {
      this.ws.close();
    }
  }

  /**
   * @see [The spreed protocol](https://github.com/strukturag/spreed-webrtc/blob/master/doc/CHANNELING-API.txt).
   */
  private createSpreedWebSocket(): WebSocket {
    const self = this;
    const ws = new WebSocket(this.props.config.spreedWebSocketUrl);
    const realSend = ws.send;
    ws.send = function (data: (string | ArrayBufferLike | Blob | ArrayBufferView)) {
      // logDebug(`Sending WebSocket message, ${JSON.stringify(data)}`);
      realSend.call(this, data);
    };
    logger.info(`Constructing spreed WebSocket to connect to room '${this.props.roomName}'`);

    spreedApi.addSpreedListener(
      ws,
      {
        onSelf: selfDoc => {
          self.mySpreedId = selfDoc.Data.Id;
          if (selfDoc.Data.Userid.length === 0) {
            // user not authenticated
            logger.info(`Received 'Self', my spreed id: ${selfDoc.Data.Id}`);
            spreedRestApi.authenticateSpreedSession({
              id: selfDoc.Data.Id,
              sid: selfDoc.Data.Sid,
            }).then((response) => {
              ws.send(spreedApi.createAuthenticationRequest(response.userid, response.nonce));
            });
          } else {
            logger.info(`Received second 'Self', authentication successful, my userid: ${selfDoc.Data.Userid}`);
            ws.send(spreedApi.createHelloRequest(
              self.props.roomName,
              window.navigator.userAgent));
          }
        },
        onWelcome: welcomeDoc => {
          const otherUsers = welcomeDoc.Data.Users.filter(u => u.Id !== self.mySpreedId);
          logger.info(`Received 'Welcome', ` +
            `${otherUsers.length} users already in this room: ${otherUsers.map((u: any) => u.Id)}`);
          self.props.callbacks.onWelcome(self.mySpreedId,
            otherUsers.map(data => ({userId: parseInt(data.Userid, 10), spreedId: data.Id})))
        },
        onJoined: joinedDoc => {
          self.props.callbacks.onPeerJoined(joinedDoc.From, parseInt(joinedDoc.Data.Userid, 10));
        },
        onLeft: lefDoc => {
          self.props.callbacks.onPeerLeft(lefDoc.Data.Id);
        },
        onBye: byeDoc => {
          logger.info('Received "Bye" from user with spreed id ', byeDoc.From);
          self.props.callbacks.onPeerBye(byeDoc.From);
        },
        onOffer: offerDoc => {
          logger.info('Received "Offer" from user with spreed id ', offerDoc.From);
          self.props.callbacks.onPeerOffer(offerDoc.From, offerDoc.Data.Offer);
        },
        onAnswer: answerDoc => {
          logger.info('Received "Answer" from user with spreed id ', answerDoc.From);
          self.props.callbacks.onPeerAnswer(answerDoc.From, answerDoc.Data.Answer);
        },
        onCandidate: candidateDoc => {
          logger.debug('Received "Candidate" from user with spreed id ', candidateDoc.From, ":\n",
            JSON.stringify(candidateDoc.Data.Candidate.candidate));
          self.props.callbacks.onPeerIceCandidate(candidateDoc.From, candidateDoc.Data.Candidate);
        },
      }
    );
    return ws;
  }

  private addLoggingToWebSocket(): void {
    this.ws.addEventListener('open', (e: Event) => {
      logger.info(`Websocket received event 'open'`);
    });
    this.ws.addEventListener('close', (c: CloseEvent) => {
      logger.info(`Websocket received event 'close', reason: ${c.reason}`);
      this.props.callbacks.onConnectionLost();
    });
    this.ws.addEventListener('error', (e: Event) => {
      logger.error(`Websocket received event 'error'`);
    });
    this.ws.addEventListener('message', (c: MessageEvent) => {
      logger.debug(`Websocket received event 'message', data: ${c.data}`);
    });
  }

  private isSocketNotClosed() {
    return this.ws.readyState !== WebSocket.CLOSED && this.ws.readyState !== WebSocket.CLOSING;
  }
}
