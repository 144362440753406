import * as React from "react";
import {ReactElement, useContext, useEffect} from "react";
import {NotificationHandlerContext} from "./NotificationHandler";
import {InvitationCreatedNotification} from "../notifications/InvitationCreatedNotification";

/**
 * When mounted this component listens for notifications and tries to show desktop notification.
 * The main use-case is to show a notification when the
 * - Only notifications received via polling are considered (webpush notifications will produce desktop notifications in `worker.js`)
 * - Only `InvitationCreatedNotification`s are considered
 * - Only if this window is not visible (checked via `Document.visibilityState`)
 */
export const ShowNotifcationOnIncomingCallHandler: React.FC = (): ReactElement | null => {
  const notificationHandler = useContext(NotificationHandlerContext);
  useEffect(() => {
    const l = notificationHandler.addListener((notification, source) => {
      console.log(`ShowNotifcationOnIncomingCallHandler, document.visibilityState:${document.visibilityState}`);
      if (
        source === 'polling' &&
        notification.type === 'INVITATION_CREATED' &&
        document.visibilityState === 'hidden'
      ) {
        tryToShowNotification(notification as InvitationCreatedNotification);
      }
      return () => {
        notificationHandler.removeListener(l);
      }
    });
  }, []);
  return null;
};

async function tryToShowNotification(n: InvitationCreatedNotification): Promise<void> {
  const permission = await Notification.requestPermission();
  if (permission !== "granted") return;
  const reg = await navigator.serviceWorker.ready;
  let callerName = 'A client';
  if (n.payload.invite.inviter.name) {
    callerName = n.payload.invite.inviter.name.first + '' + n.payload.invite.inviter.name.last;
  }
  const notificationTitle = 'Incoming call';
  const notificationOptions: NotificationOptions = {
    body: callerName + ' is calling you',
    actions: [{action: 'accept', title: 'Accept', icon: '/icons/accept-call.png'}, {
      action: 'decline',
      title: 'Decline',
      icon: '/icons/decline-call.png'
    }],
    data: n.payload,
    // sound: '/sounds/dial.mp3',
    vibrate: [200, 100, 200, 100, 200, 100, 400],
    tag: 'invite:' + n.payload.invite.id,
    requireInteraction: true,
  };
  return reg.showNotification(notificationTitle, notificationOptions);
}
