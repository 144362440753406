import * as React from 'react';
import {CSSProperties} from 'react';
import {IObservableObject, IObservableValue, observable} from 'mobx';
import {observer} from 'mobx-react';
import ImageInput, {imageInputStyles, ImageInputStyles} from './ImageInput';
import * as imageApi from '../api/imageApi';
import {WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import {MalformedImageDialog} from "./dialogs/MalformedImageDialog";
import {applyCardImageParametersToImageUrl} from "../pages/admin/dashboard/DashboardPage";

export interface NewSubject {
  imageId: number | null;
}

export interface ExistingSubject {
  _links: {
    image: string | null;
  };
}

const MAX_IMAGE_FILE_SIZE = 5 * 1024 * 1024;

interface Props {
  dirtyState: IObservableValue<boolean>;
  inProgressCounter: IObservableValue<number>;
  newSubject: NewSubject & IObservableObject;
  existingSubject?: ExistingSubject;
  style?: CSSProperties;
}

interface State {
  invalidImageDialogOpen: boolean
}

type PropsWithStyle = Props & WithStyles<ImageInputStyles>;

/**
 * Renders a [ImageInput] component that is already handling
 * - Sending the upload image request to the server
 * - Applying the field changes to the given [NewSubject]
 * - Picking the correct imageHref
 */
@observer
export class ImageInputForSubject extends React.Component<PropsWithStyle, State> {
  /** The href of the image just uploaded or '' if the image is to be deleted. */
  @observable private uploadedImageHref?: string = undefined;
  @observable private errorText?: string = undefined;

  constructor(props: PropsWithStyle) {
    super(props);
    this.state = {
      invalidImageDialogOpen: false
    };
  }

  imageHref(): string | null {
    if (this.uploadedImageHref === '') {
      // User selected to delete the image - show placeholder
      return null;
    }
    return this.uploadedImageHref
      || (this.props.existingSubject && this.props.existingSubject._links.image)
      || null;
  }

  render() {
    return (
      <ImageInput
        style={this.props.style}
        classes={this.props.classes}
        onUpload={async (file: File) => {
          if (file.size > MAX_IMAGE_FILE_SIZE) {
            this.errorText = 'File must not be bigger than 5MB';
            return;
          } else {
            this.errorText = undefined;
          }
          this.props.inProgressCounter.set(this.props.inProgressCounter.get() + 1);
          await imageApi.uploadImage(file).then(image => {
            this.props.newSubject.imageId = image.id;
            this.uploadedImageHref = applyCardImageParametersToImageUrl(image._links.image)!!;
            this.props.inProgressCounter.set(this.props.inProgressCounter.get() - 1);
            this.props.dirtyState.set(true);
          }).catch(error => {
            if (error.response.status === 400) {
              // Invalid image
              this.setState({invalidImageDialogOpen: true});
              this.props.inProgressCounter.set(0);
            } else {
              // Rethrow error if it's not a 400
              return error;
            }
          });
        }
        }
        onDelete={() => {
          this.errorText = undefined;
          this.props.newSubject.imageId = null;
          this.uploadedImageHref = '';
          this.props.dirtyState.set(true);
        }
        }
        imageHref={this.imageHref()}
      >
        {this.errorText &&
        <FormHelperText
          error={true}
          style={{
            width: 100,
          }}
        >
          {this.errorText}
        </FormHelperText>}
        <MalformedImageDialog
          open={this.state.invalidImageDialogOpen}
          onClose={() => this.setState({invalidImageDialogOpen: false})}
        />
      </ImageInput>
    );
  }
}

export default withStyles(imageInputStyles)(ImageInputForSubject);
