import {useEffect} from 'react';

export function Sound(props: { src: string, loop: boolean }) {
  useEffect(() => {
      const audio = new Audio(props.src);
      audio.loop = props.loop;
      audio.play().catch(() => null);
      return () => {
        audio.pause();
        audio.srcObject = null;
      };
    }
  );
  return null;
}
