import * as React from "react";
import {useContext, useEffect} from "react";
import {NotificationHandlerContext} from "./NotificationHandler";
import {isAndroid, onNotification} from "../androidApi";
import * as Sentry from "@sentry/browser";

export const AndroidNotificationHandler: React.FC = (): null => {
  const notificationHandler = useContext(NotificationHandlerContext);

  useEffect(() => {
    if (!isAndroid()) return;
    const disposable = onNotification.on(notification =>
      notificationHandler.onReceiveNotification(notification, 'android')
    );
    Sentry.configureScope(scope => {
      scope.setTag("push_mode", "android");
    });
    return () => {
      disposable.dispose();
    }
  });
  return null;
};
