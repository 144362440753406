import * as React from 'react';
import {AccountDto} from '../../../api/userTypes';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {RouteComponentProps, withRouter} from "react-router";
import ItemList from "./ItemList";
import {loadItems} from "../../../api/itemApi";
import {fullPageable} from "../../../query/pageable";
import {Item} from "../../../api/itemTypes";
import SupportCaseScroller from "../support-case/SupportCaseScroller";
import {SupportCase} from "../../../api/supportCaseTypes";
import {getSupportCases} from "../../../api/supportCaseApi";

const BOTTOM_NAVIGATION_HEIGHT = 56;

type Props = {
  account: AccountDto;
} & RouteComponentProps & WithStyles<LocalStyles>;

type LocalStyles = 'root';

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = theme => ({
  root: {
    margin: 'auto',
    padding: theme.spacing(2),
    paddingBottom: BOTTOM_NAVIGATION_HEIGHT,
  },
});

type State = {
  items: Array<Item> | null,
  supportCases: Array<SupportCase> | null,
}

class AssignedItemsPage extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      items: null,
      supportCases: null
    }
  }

  async componentDidMount() {
    const itemPage = await loadItems({supportAgentId: this.props.account.id}, fullPageable());
    const supportCasePage = await getSupportCases({supportAgentId: this.props.account.id}, {
      page: 0,
      size: 20,
      sort: {field: 'lastActivity', order: 'desc'}
    });
    this.setState({items: itemPage.content, supportCases: supportCasePage.content});
  }

  render() {
    const {classes} = this.props;
    const {items, supportCases} = this.state;
    return (
      <>
        <SupportCaseScroller supportCases={supportCases} history={this.props.history}/>
        <div className={classes.root}>
          <ItemList items={items} headline="Assigned Items" noItemsText="You have no assigned items"/>
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(localStyles)(AssignedItemsPage));
