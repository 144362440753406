import {HelperTextSupplier} from "./helperTextUtil";
import {isInRange, isValidEmail, isValidUrl, isWithinMaxLength} from "./validations";

export const emailValidation: HelperTextSupplier = {
  getProps(input: string) {
    return {
      error: true,
      helperText: 'Invalid email address'
    };
  },
  shouldDisplay(input: string) {
    return !!input && !isValidEmail(input);
  }
};

export const urlValidation: HelperTextSupplier = {
  getProps(input: string) {
    return {
      error: true,
      helperText: 'Invalid internet address'
    };
  },
  shouldDisplay(input: string) {
    return !!input && !isValidUrl(input);
  }
};

export const notEmptyValidation: HelperTextSupplier = {
  getProps(input: string) {
    return {
      error: true,
      helperText: 'Must not be empty'
    };
  },
  shouldDisplay(input: string) {
    return input === undefined || input === null || input.length <= 0;
  }
};

export const notBlankValidation: HelperTextSupplier = {
  getProps(input: string) {
    return {
      error: true,
      helperText: 'Must not be blank'
    };
  },
  shouldDisplay(input: string) {
    return input === undefined || input === null || input.trim().length <= 0;
  }
};


export function maxLengthValidation(maxLength: number): HelperTextSupplier {
  return {
    getProps(input: string) {
      return {
        error: true,
        helperText: `Maximum character length: ${maxLength} (current: ${input.length})`
      };
    },
    shouldDisplay(input: string) {
      return !!input && !isWithinMaxLength(input, maxLength);
    }
  }
}

export function inRangeValidation(min: number, max: number): HelperTextSupplier {
  return {
    getProps(input: string) {
      return {
        error: true,
        helperText: `The input must be between ${min} and ${max}`
      };
    },
    shouldDisplay(input: string) {
      return !!input && !isInRange(parseFloat(input), min, max);
    }
  }
}
