import * as React from 'react';
import {WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import {IObservableObject, IObservableValue, observable} from 'mobx';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import * as supportAgentApi from '../../../api/supportAgentApi';
import {isValidationErrors, ValidationErrors} from '../../../api/restApi';
import {observer} from 'mobx-react';
import {emptyUser, SupportAgentRequest} from '../../../api/userTypes';
import {UserForm} from './UserForm';
import {DialogWithDirtyWarning} from '../../../components/dialogs/DialogWithDirtyWarning';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {MyDialogStyles, myDialogStyles} from '../../../styles/myDialogStyles';
import {SupportAgentIcon} from '../../../styles/icons';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Typography from '@material-ui/core/Typography/Typography';
import {SingleClickButton} from '../../../components/SingleClickButton';

interface Props {
  open: boolean;
  onClose: () => void;
}

type PropsWithStyle = Props & WithStyles<MyDialogStyles>;

@observer
export class NewSupportAgentDialog extends React.Component<PropsWithStyle> {

  @observable user: SupportAgentRequest & IObservableObject = observable(emptyUser());
  dirtyState: IObservableValue<boolean> = observable.box(false);
  inProgressCounter: IObservableValue<number> = observable.box(0);
  @observable validationErrors: ValidationErrors | undefined = undefined;

  constructor(props: PropsWithStyle) {
    super(props);
    this.init = this.init.bind(this);
  }

  /**
   * This Dialog component needs to remain mounted for nicer animations, so
   * we use this function triggered by `Dialog.onRendered` instead of `componentDidMount`
   * for initialization.
   */
  init() {
    this.user = observable(emptyUser());
    this.dirtyState.set(false);
    this.validationErrors = undefined;
  }

  render() {
    return (
      <DialogWithDirtyWarning
        id="newSupportAgentDialog"
        open={this.props.open}
        onClose={this.props.onClose}
        dirtyState={this.dirtyState}
        fullWidth={true}
        maxWidth="md"
        onRendered={this.init}
        render={closeHandler => (
          <>
            <DialogTitle disableTypography={true}>
              <SupportAgentIcon className={this.props.classes.titleIcon}/>
              <Typography variant="h6">New Support Agent</Typography>
              <IconButton
                onClick={closeHandler}
                className={this.props.classes.closeButton}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <UserForm
                classes={this.props.classes}
                user={this.user}
                dirtyState={this.dirtyState}
                inProgressCounter={this.inProgressCounter}
                fieldErrors={this.validationErrors ? this.validationErrors.fieldErrors : []}
              />
            </DialogContent>
            <DialogActions>
              <SingleClickButton
                buttonProps={{
                  id: "newSupportAgentDialog_button_save",
                  color: "primary",
                  variant: "contained",
                  disabled: this.inProgressCounter.get() > 0,
                }}
                onSingleClick={() =>
                  supportAgentApi.create(this.user).then(data => {
                    if (isValidationErrors(data)) {
                      this.validationErrors = data;
                    } else {
                      this.validationErrors = undefined;
                      this.props.onClose();
                    }
                  })
                }
              >
                Create
              </SingleClickButton>
            </DialogActions>
          </>
        )}
      />
    );
  }
}

export default withStyles(myDialogStyles)(NewSupportAgentDialog);
