import * as React from "react";
import {ReactElement, ReactNode, useEffect, useState} from "react";

export const ServiceWorkerContext = React.createContext<ServiceWorkerRegistration | null>(null);

export const ServiceWorkerContextProvider: React.FC = (props: { children?: ReactNode }): ReactElement | null => {
  const [loading, setLoading] = useState<boolean>(true);
  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<ServiceWorkerRegistration | null>(null);
  useEffect(() => {
    registerWorker().then(reg => {
      setServiceWorkerRegistration(reg || null);
      setLoading(false);
    }).catch(reason => {
      console.warn(`Error while registering ServiceWorker: ${reason}`);
      setLoading(false);
    });
  }, []);
  // While still loading, don't render the children
  if (loading) return null;
  else return (
    <ServiceWorkerContext.Provider value={serviceWorkerRegistration}>
      {props.children}
    </ServiceWorkerContext.Provider>);
};

async function registerWorker(): Promise<ServiceWorkerRegistration | undefined> {
  if ('serviceWorker' in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/worker.js`;
    try {
      const reg = await navigator.serviceWorker.register(swUrl);
      console.info(`Registered service worker ${swUrl}`);
      return reg;
    } catch (error) {
      console.error('Error during service worker registration:', error);
      return;
    }
  }
  return;
}
