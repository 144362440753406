import * as React from 'react';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {Subscribe} from "unstated";
import {CallStateContainer} from "./state/CallStateContainer";
import {Sound} from "../../../components/Sound";
import {LargeVideo} from "./LargeVideo";
import {VideoCircle} from "./VideoCircle";
import {declineInvite} from "../../../api/inviteApi";
import {Invite} from "../../../api/inviteTypes";
import {createStyles} from "@material-ui/styles";

type Props = {
  ownSpreedId?: string,
  connectedUsers: Array<{ userId: number, spreedId: string, stream?: MediaStream }>,
  ownStreamIsFrontFacing: boolean,
  takeScreenshotHandlerRef?(ref: (() => string) | null): void,
  drawingColor: string,
  takeDrawingScreenshotRef(ref: null | (() => Promise<File | null>)): void
};

type PropsWithStyle = Props & WithStyles<LocalStyles>;

export type LocalStyles = 'root';

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

export class VideoContainer extends React.PureComponent<PropsWithStyle> {

  private readonly runningTimers: Array<any> = [];

  componentWillUnmount() {
    this.clearTimeouts();
  }

  render() {
    this.clearTimeouts();
    const {classes} = this.props;
    return (
      <Subscribe to={[CallStateContainer]}>
        {(callStateContainer: CallStateContainer) => (
          <div className={classes.root}>
            {this.getVideoComponents(callStateContainer)}
          </div>
        )}
      </Subscribe>
    );
  }

  private getVideoComponents(callStateContainer: CallStateContainer) {
    const {connectedUsers, ownSpreedId, ownStreamIsFrontFacing, takeScreenshotHandlerRef} = this.props;
    const largeVideoSpreedId = this.getWhoIsLarge(callStateContainer);
    const users: Array<{
      userId: number,
      spreedId?: string, // Not present when pending
      stream?: MediaStream,
      name: null | {
        first: string,
        last: string
      },
      color: string,
      invite?: Invite
    }> = [];

    for (let connectedUser of connectedUsers) {
      const userInfo = callStateContainer.state.knownUsers.get(connectedUser.spreedId || '-1');
      let name = null;
      let color = '#cccccc';
      if (userInfo && userInfo.name) {
        name = userInfo.name;
      }
      if (userInfo && userInfo.color) {
        color = userInfo.color
      }
      users.push({...connectedUser, name, color})
    }

    callStateContainer.state.pendingInvites
      .filter(pendingIvnite => pendingIvnite.expiresTimestamp > Date.now())
      .filter(pendingInvite => connectedUsers.filter(user => user.userId == pendingInvite.invite.invitee.id).length <= 0)
      .forEach(pendingInvite => {
        users.push({
          invite: pendingInvite.invite,
          userId: pendingInvite.invite.invitee.id,
          name: pendingInvite.invite.invitee.name,
          color: pendingInvite.invite.inviterColor
        });
        this.runningTimers.push(setTimeout(() => {
          callStateContainer.cleanupPendingInvites();
        }, pendingInvite.expiresTimestamp - Date.now() + 1000))
      });

    const playCallSound = users.length > connectedUsers.length && connectedUsers.length < 2;
    users.sort((a, b) => a.userId - b.userId);
    return (
      <>
        {playCallSound && <Sound src={'/sounds/dial.mp3'} loop={true}/>}
        {users.map(user => {
          const streamStatus =
            callStateContainer.state.streamStatus.get(user.spreedId || '') || {audioEnabled: true, videoEnabled: true};
          if (user.spreedId == (largeVideoSpreedId || ownSpreedId)) {
            return (
              <LargeVideo
                key={user.spreedId}
                name={user.name}
                isSelf={user.spreedId == ownSpreedId}
                stream={user.stream}
                audioEnabled={user.spreedId != ownSpreedId && streamStatus.audioEnabled}
                videoEnabled={streamStatus.videoEnabled && !callStateContainer.state.drawing.active}
                ownStreamIsFrontFacing={ownStreamIsFrontFacing}
                takeScreenshotHandlerRef={takeScreenshotHandlerRef}
                drawingColor={this.props.drawingColor}
                takeDrawingScreenshotRef={this.props.takeDrawingScreenshotRef}
              />
            );
          } else {
            return (
              <VideoCircle
                key={user.spreedId || user.userId}
                name={user.name}
                color={user.color}
                isSelf={user.spreedId == ownSpreedId}
                onClick={() => user.spreedId && callStateContainer.setLargeScreen(user.spreedId)}
                stream={user.stream}
                audioEnabled={user.spreedId != ownSpreedId && streamStatus.audioEnabled}
                videoEnabled={streamStatus.videoEnabled}
                ownStreamIsFrontFacing={ownStreamIsFrontFacing}
                withCancelButton={!!user.invite && connectedUsers.length > 1}
                onCancelClick={async () => {
                  if (user.invite) {
                    try {
                      const invite = await declineInvite(user.invite.id, true);
                      callStateContainer.removeFromPendingInvites(invite, 'CANCELED', true);
                    } catch {
                      // We can just ignore exceptions here.
                    }
                  }
                }}
              />
            );
          }
        })}
      </>
    );
  }

  private getWhoIsLarge(callStateContainer: CallStateContainer) {
    // In case no user in the "connectedUser" props should be large according to our call state,
    // we use the user with the largest user id instead.
    let userWithLargestId = null;
    for (let user of this.props.connectedUsers) {
      if (callStateContainer.state.largeScreen && user.spreedId == callStateContainer.state.largeScreen.userSpreedId) {
        return user.spreedId;
      }
      if (!userWithLargestId) {
        userWithLargestId = user;
      }
      if (userWithLargestId.userId < user.userId) {
        userWithLargestId = user;
      }
    }

    return userWithLargestId ? userWithLargestId.spreedId : this.props.ownSpreedId;
  }

  private clearTimeouts() {
    this.runningTimers.forEach(timer => clearTimeout(timer));
    this.runningTimers.length = 0;
  }
}

export default withStyles(localStyles)(VideoContainer);
