import {myAxios} from '../util/myAxios';
import {Page, Pageable, toUrlParams} from "../query/pageable";

export async function getCallStats(pageable: Pageable): Promise<Page<CallStats>> {
  return myAxios()
    .then(axios => axios.get(`/api/platform/calls?${toUrlParams(pageable)}`))
    .then(response => response.data);
}

/** Mirrors [com.kurzdigital.remotexpert.call.CallStatsDto] */
export interface CallStats {
  id: number,
  duration: number | null,
  created: string,
  tenantId: number,
  status: CallStatus,
  participants: Participant[],
  screenshotCount: number,
  chatMessageCount: number,
}

export interface Participant {
  id: number,
  userAgent: string,
  roles: string[],
}

type CallStatus = 'REQUESTED' | 'DECLINED' | 'ACTIVE' | 'ENDED';
