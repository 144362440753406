import {myAxios} from '../util/myAxios';
import {Page, Pageable, toUrlParams} from '../query/pageable';
import {DeleteSupportAgentRequest, SupportAgentRequest, User} from './userTypes';
import {isValidationErrorResponse, ValidationErrors} from './restApi';
import {Item} from './itemTypes';

export function getAll(
  params: { searchText?: string },
  pageable: Pageable): Promise<Page<User>> {
  const searchParam = params.searchText === undefined ? '' : `search=${encodeURIComponent(params.searchText)}&`;
  return myAxios()
    .then(axios => axios.get(`/api/support_agents/?${searchParam}${toUrlParams(pageable)}`))
    .then(response => response.data);
}

export function create(supportAgent: SupportAgentRequest): Promise<Item | ValidationErrors> {
  return myAxios()
    .then(axios => axios.post(`/api/support_agents`, {...supportAgent, email: supportAgent.email.trim()}))
    .then(
      response => response.data,
      error => {
        const response = error.response;
        if (isValidationErrorResponse(response)) {
          return response.data;
        } else {
          throw error;
        }
      });
}

export function update(user: User): Promise<User | ValidationErrors> {
  return myAxios()
    .then(axios => axios.put(`/api/support_agents/${user.id}`, user))
    .then(
      response => response.data,
      error => {
        const response = error.response;
        if (isValidationErrorResponse(response)) {
          return response.data;
        } else {
          throw error;
        }
      });
}

export function remove(userId: number, req: DeleteSupportAgentRequest = {}): Promise<{}> {
  return myAxios()
    .then(axios => axios.delete(`/api/support_agents/${userId}`, {data: req}));
}

export function imageUrl(userId: number): string {
  return `/api/support_agents/${userId}/image`;
}
