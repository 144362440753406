import * as React from 'react';
import {Theme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  clearfix: {
    '&:after': {
      content: '\'\'',
      clear: 'both',
      display: 'table',
    },
  },
}));

export function Clearfix() {
  const classes = useStyles();
  return (<div className={classes.clearfix}/>);
}
