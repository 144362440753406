/** Mirrors [com.kurzdigital.remotexpert.call.CreateCallRequest] */
import {Invite} from "./inviteTypes";
import {ChatMessage} from './chatTypes';

export interface CreateCallRequest {
  calleeId?: number;
  supportCaseId?: number;
  itemId?: number;
}

/** Mirrors [com.kurzdigital.remotexpert.call.CallDto] */
export interface Call {
  id: number;
  roomName: string;
  itemId: number | null;
  supportCaseId: number | null;
  invites: Array<Invite>;
  ended: boolean;
  screenshots: Array<Screenshot>;
  lastChatMessage?: ChatMessage;
}

export interface PartialCall {
  id: number;
  roomName: string;
  supportCaseId: number | null;
}

export interface Screenshot {
  id: number,
  callId: number,
  _links: {
    self: string,
    image: string
  }
}

export function isPartOfCall(call: Call, userId: number) {
  for (let invite of call.invites) {
    // If it's the inviter, he is definitely part of the call
    if (invite.inviter.id == userId) {
      return true;
    }
    // An invitee is only part of the call, if he accepted his invite
    if (invite.invitee.id == userId && invite.status == 'ACCEPTED') {
      return true;
    }
  }
  return false;
}

/**
 * Gets the color of a user in the call.
 */
export function getColorOfUser(call: Call, userId: number) {
  let color = null;
  call.invites.forEach(invite => {
    if (invite.inviter.id === userId) {
      color = invite.inviterColor;
    } else if (invite.invitee.id === userId) {
      color = invite.inviteeColor;
    }
  });
  return color;
}
