import * as React from 'react';

type Props = {
  startTimestamp: number
};

type State = {
  seconds: number
}

class CountingClock extends React.PureComponent<Props, State> {

  private timer?: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      seconds: Math.round((Date.now() - props.startTimestamp) / 1000)
    }
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        seconds: prevState.seconds + 1
      }));
    }, 1000);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    this.setState({
      seconds: Math.round((Date.now() - this.props.startTimestamp) / 1000)
    })
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <>{this.formatTime(this.state.seconds)}</>
    );
  }

  private formatTime(seconds: number) {
    let strSeconds = (seconds % 60).toString(10);
    if (strSeconds.length == 1) {
      strSeconds = '0' + strSeconds;
    }

    let strMinutes = (Math.floor(seconds / 60) % 60).toString(10);
    if (strMinutes.length == 1) {
      strMinutes = '0' + strMinutes;
    }

    let strHours = (Math.floor(seconds / 60 / 60) % 60).toString(10);
    if (strHours.length == 1) {
      strHours = '0' + strHours;
    }

    if (strHours == '00') {
      return strMinutes + ':' + strSeconds;
    }
    return strHours + ':' + strMinutes + ':' + strSeconds;
  }

}

export default CountingClock;
