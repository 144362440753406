import * as React from 'react';
import {CSSProperties, useEffect, useState} from 'react';
import {MenuItem, Paper, TextField, Theme} from "@material-ui/core";
import {Item} from "../../../api/itemTypes";
import {scanItemCode} from "../../../api/itemApi";
import {CONTAINER_MAX_WIDTH} from "../../../styles/muiTheme";
import UnknownItemDialog from "./UnknownItemDialog";
import ItemCodeInputField from "./ItemCodeInputField";
import QrReader from 'react-qr-reader';
import makeStyles from "@material-ui/styles/makeStyles";
import {createStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: CONTAINER_MAX_WIDTH / 2,
    margin: 'auto',
  },
  cameraSelect: {
    marginTop: theme.spacing(),
  },
}));

const defaultConstraints: MediaTrackConstraints = {
  facingMode: {ideal: "environment"},
};

export function ItemScanner(props: {
  style?: CSSProperties,
  className?: string,
  onItemScanned(item: Item): void,
}) {
  const [textFieldInput, setTextFieldInput] = useState("");
  const [lastItemCode, setLastItemCode] = useState("");
  const [unknownItemDialogOpen, setUnknownItemDialogOpen] = useState(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const classes = useStyles();

  const constraints: MediaTrackConstraints = selectedDeviceId ? {
    deviceId: selectedDeviceId,
  } : defaultConstraints;

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoDevices: MediaDeviceInfo[] = devices.filter(d => d.kind === "videoinput");
      setDevices(videoDevices);
    });
  }, []);

  const handleItemCode = async (itemCode: string | null) => {
    if (itemCode == null || lastItemCode == itemCode) {
      return;
    }
    setLastItemCode(itemCode);
    let item;
    try {
      item = await scanItemCode(itemCode.split("/").pop()!!);
    } catch (error) {
      setUnknownItemDialogOpen(true);
      return;
    }
    setUnknownItemDialogOpen(false);
    setTextFieldInput(item.itemCode);
    props.onItemScanned(item);
  };

  return (
    <div style={props.style} className={props.className}>
      <div className={classes.root}>
        <Paper>
          <QrReader
            key={selectedDeviceId}
            delay={250}
            onError={(err) => console.log("Error in QR code scanner:", err)}
            onScan={handleItemCode}
            constraints={constraints}
          />
          <ItemCodeInputField
            value={textFieldInput}
            onChange={setTextFieldInput}
            onConfirm={handleItemCode}
            invalid={true}
          />
        </Paper>
        {(devices.length > 1) &&
        <TextField
          className={classes.cameraSelect}
          label="Choose Camera"
          fullWidth={true}
          select={true}
          value={selectedDeviceId}
          onChange={ev => setSelectedDeviceId(ev.target.value)}
        >
          {devices.map((d, i) =>
            <MenuItem
              key={d.deviceId}
              value={d.deviceId}>
              {d.label || `Camera ${i + 1}`}
            </MenuItem>
          )}
        </TextField>
        }
      </div>
      <UnknownItemDialog open={unknownItemDialogOpen} onClose={() => {
        setLastItemCode('');
        setUnknownItemDialogOpen(false);
      }}/>
    </div>
  );
}
