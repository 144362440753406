import * as React from 'react';
import {User} from '../api/userTypes';

interface Props {
  user: User;
}

export function Username(
  props: Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
): JSX.Element {
  const {user, ...rest} = props;
  return (
    <span {...rest}>{user.firstName}&nbsp;{user.lastName}</span>
  );
}

export function userToString(user: User): string {
  return `${user.firstName} ${user.lastName}`;
}
