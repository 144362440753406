import * as React from 'react';
import {ChatBubble} from './ChatBubble';
import {ChatMessage} from '../../../../api/chatTypes';
import {useCurrentAccount} from '../../../../util/hooks/useCurrentAccount';

export function ChatContent(props: {
  messages: Array<ChatMessage>
}) {
  const ownUserId = (useCurrentAccount() || {id: -1}).id;
  return (
    <>
      {props.messages.map((message, index) => {
        const prevMessage = index > 0 ? props.messages[index - 1] : undefined;
        return (
          <ChatBubble
            key={index}
            position={message.sender.id === ownUserId ? 'right' : 'left'}
            message={message}
            prevMessage={prevMessage}
          />
        )
      })}
    </>
  );
}
