import Keycloak, {KeycloakError} from 'keycloak-js';
import {AccountDto} from "./api/userTypes";
import {getAccount} from "./api/accountApi";
import * as Sentry from "@sentry/browser";

export const keycloak = Keycloak('/api/keycloak_config');

let currentAccountPromise: Promise<AccountDto> | null = null;
let checkIfLoggedInPromise: Promise<boolean> | null = null;
let loginPromise: Promise<void> | null = null;

/**
 * Gets the current account of the user.
 */
export function getCurrentAccount(): Promise<AccountDto> {
  if (currentAccountPromise) {
    return currentAccountPromise;
  }

  currentAccountPromise = getAccount();
  currentAccountPromise.then(account => {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: account.id.toString(),
        keycloakId: account.keycloakId,
        roles: account.roles.join(" "),
      });
    });
  });
  return currentAccountPromise;
}

/**
 * Checks if the we are currently authenticated as a support agent or tenant admin.
 */
export function checkIfLoggedIn(): Promise<boolean> {
  if (checkIfLoggedInPromise) {
    return checkIfLoggedInPromise;
  }

  checkIfLoggedInPromise = new Promise((resolve, reject) => {
    keycloak.init({
      onLoad: 'check-sso',
      responseMode: 'fragment',
      checkLoginIframe: false
    })
      .success((authenticated: boolean) => {
        resolve(authenticated);
      })
      .error((error: KeycloakError) => {
        console.error(`Keycloak init failed: ${JSON.stringify(error)}`);
        resolve(false);
      })
  });

  return checkIfLoggedInPromise;
}

/**
 * Redirects to the keycloak login page if we are not logged in.
 */
export async function login(): Promise<void> {
  if (loginPromise) {
    return loginPromise;
  }

  const loggedIn = await checkIfLoggedIn();
  if (loggedIn) {
    return;
  }

  loginPromise = new Promise((resolve, reject) =>
    keycloak.login({
      redirectUri: window === undefined ? undefined : window.location.protocol + '//' + window.location.host,
      scope: "offline_access",
    })
      .success(resolve)
      .error(reject)
  );

  return loginPromise;
}

/**
 * Logout from an existing keycloak session.
 */
export async function logout() {
  keycloak.logout();
}
