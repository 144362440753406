import {myAxios} from '../util/myAxios';

export interface Config {
  spreedWebSocketUrl: string;
  turnServerUrl: string;
  applicationServerKey: string; // used for web-push
}

export function getConfig(): Promise<Config> {
  return myAxios().then(axios => axios.get('/api/config').then(response => {
    return response.data;
  }));
}
