import * as React from 'react';
import {CSSProperties} from 'react';
import {SupportCase} from "../../../api/supportCaseTypes";
import List from "@material-ui/core/List";
import {SupportCaseListItem} from "./SupportCaseListItem";

export function SupportCaseList(props: {
  supportCases: Array<SupportCase>,
  onClick(supportCase: SupportCase): void,
  className?: string,
  style?: CSSProperties
}) {
  return (
    <List className={props.className} style={props.style}>
      {props.supportCases.map(supportCase => (
        <SupportCaseListItem
          key={supportCase.id}
          supportCase={supportCase}
          onClick={() => props.onClick(supportCase)}
        />
      ))}
    </List>
  );
}
