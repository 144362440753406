import {myAxios} from '../util/myAxios';
import {EntityRef} from './restApi';

/** Mirrors [com.kurzdigital.remotexpert.file.FileDto] */
export interface FileDto extends EntityRef {
  id: number;
  name: string;
  _links: {
    self: string;
    data: string;
  };
}

export function uploadFile(file: File): Promise<FileDto> {
  return myAxios()
    .then(axios => {
      const form = new FormData();
      form.append('file', file, file.name || '');
      return axios.post(`/api/files`, form);
    })
    .then(response => response.data);
}
