import * as React from 'react';
import {CSSProperties, ReactNode} from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    paddingTop: 56,
    [`${theme.breakpoints.up('xs')} AND (orientation: landscape)`]: {
      paddingTop: 48
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  }
}));

/**
 * A div with top-padding that has the size of a material design app bar.
 *
 * Its height is defined here: https://material.io/guidelines/layout/structure.html#structure-app-bar
 */
export function AppBarTopPadding(props: {
  children?: ReactNode,
  style?: CSSProperties
}) {
  const {root} = useStyles();
  return <div style={props.style} className={root}>{props.children}</div>;
}
