import React from 'react';

/**
 * Can be used as properties for an input field using the spread operator.
 */
export interface HelperTextProps {
  error: boolean,
  helperText: React.ReactNode
}

/**
 * A supplier for helper texts based on a given input.
 */
export interface HelperTextSupplier {
  getProps(input?: string): HelperTextProps,

  shouldDisplay(input?: string): boolean
}

/**
 * Gets the HelperTextProps which can be used for an input field using the spread operator.
 *
 * It accepts multiple suppliers and displays the helper text for the first supplier that
 * should be displayed. It's recommended to first use the suppliers for error validation and have
 * "general" helper texts as the last method parameter.
 *
 * @param input The input of the field.
 * @param suppliers The helper text suppliers.
 */
export function getHelperText(input?: string, ...suppliers: HelperTextSupplier[]): HelperTextProps | null {
  for (const supplier of suppliers) {
    if (supplier.shouldDisplay(input)) {
      return supplier.getProps(input);
    }
  }
  return null;
}

/**
 * Checks if the getHelperText method returns a property with an error.
 *
 * @param input The input of the field.
 * @param suppliers The helper text suppliers.
 */
export function hasError(input?: string, ...suppliers: HelperTextSupplier[]): boolean {
  const props = getHelperText(input, ...suppliers);
  return hasErrorInHelperTextProps(props);
}

export function hasErrorInHelperTextProps(props: HelperTextProps | null): boolean {
  return props !== null && props.error
}
