import {DependencyList, useEffect} from "react";

export function useFingerOrMouseMoveEffect(props: {
  onMove: (x: number, y: number) => void,
  onStartNew?: () => void,
  element: HTMLElement | null
}, deps: DependencyList) {

  useEffect(() => {
    if (props.element) {
      props.element.onmousedown = event => {
        const primaryButtonIsPressed = event.buttons % 2 === 1;
        if (primaryButtonIsPressed) {
          if (props.onStartNew) {
            props.onStartNew();
          }
          const offset = calculateRelativePosition(event);
          props.onMove(offset.x, offset.y);
          event.preventDefault();
        }
      };
      props.element.onmousemove = event => {
        const primaryButtonIsPressed = event.buttons % 2 === 1;
        if (primaryButtonIsPressed) {
          const offset = calculateRelativePosition(event);
          props.onMove(offset.x, offset.y);
          event.preventDefault();
        }
      };

      const touchStartListener = (event: TouchEvent) => {
        const touch = event.changedTouches[0];
        if (touch) {
          if (props.onStartNew) {
            props.onStartNew();
          }
          const offset = calculateRelativePosition(touch);
          props.onMove(offset.x, offset.y);
          event.preventDefault();
        }
      };

      props.element.addEventListener('touchstart', touchStartListener, {passive: false});

      const touchMoveListener = (event: TouchEvent) => {
        const touch = event.changedTouches[0];
        if (touch) {
          const offset = calculateRelativePosition(touch);
          props.onMove(offset.x, offset.y);
          event.preventDefault();
        }
      };

      props.element.addEventListener('touchmove', touchMoveListener, {passive: false});
      return () => {
        props.element!!.removeEventListener('touchstart', touchStartListener);
        props.element!!.removeEventListener('touchmove', touchMoveListener);
      }
    }
    return () => null;
  }, [props.onMove, props.element, ...deps]);
}

/**
 * Calculates the relative mouse/touch position.
 */
function calculateRelativePosition(eventOrTouch: any) {
  let element = eventOrTouch.target;
  let x = 0;
  let y = 0;

  while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
    x += element.offsetLeft - element.scrollLeft;
    y += element.offsetTop - element.scrollTop;
    element = element.offsetParent;
  }

  x = eventOrTouch.clientX - x;
  y = eventOrTouch.clientY - y;

  return {x: x, y: y};
}
