import * as React from 'react';
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import {SupportCase} from "../../../api/supportCaseTypes";
import moment from 'moment';
import PlaceholderCardMedia from "../../../components/PlaceholderCardMedia";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import {makeStyles} from "@material-ui/styles";
import {useCurrentAccountHasRole} from "../../../util/hooks/useCurrentAccount";
import {animated, useSpring} from "react-spring";
import {useObjectUrl} from "../../../util/hooks/useObjectUrl";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 300,
    display: "inline-block",
    margin: theme.spacing(0.5),
  },
  media: {
    height: 140,
  },
}));

export function SupportCaseCard(props: {
  supportCase: SupportCase;
  onClick(): void;
}) {
  const {supportCase} = props;
  const classes = useStyles();
  const isEnduser = useCurrentAccountHasRole('END_USER') === true;
  const missedCalls = isEnduser ? supportCase.missedSupportAgentToClientCallCount : supportCase.missedClientToSupportAgentCallCount;

  const mountTransition = useSpring({opacity: 1, from: {opacity: 0}});

  const supportCaseImageObjectUrl = useObjectUrl(supportCase.image);

  let supportCaseTime = moment(supportCase.lastActivity);
  let formattedDate = supportCaseTime.fromNow();
  if (moment().diff(supportCaseTime, 'days') >= 1) {
    formattedDate = supportCaseTime.format('L, LT')
  }

  return (
    <animated.div style={mountTransition} className={classes.root}>
      <Card className={classes.root}>
        <CardActionArea onClick={props.onClick}>
          {supportCaseImageObjectUrl == null && <PlaceholderCardMedia height={140}/>}
          {supportCaseImageObjectUrl != null && (
            <CardMedia
              className={classes.media}
              image={supportCaseImageObjectUrl || undefined}
            />
          )}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" noWrap={true}>
              {supportCase.name}
            </Typography>
            <Typography component="p" noWrap={true}>
              {missedCalls > 0 && <span style={{color: 'red'}}>{missedCalls} missed call{missedCalls > 1 && 's'}</span>}
              {missedCalls <= 0 && supportCase.status}
              &nbsp;| {formattedDate}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </animated.div>
  );
}
