import * as React from 'react';
import {ErrorInfo} from 'react';
import * as Sentry from '@sentry/browser';
import {observer} from 'mobx-react';
import {errorStore} from './ErrorStore';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';

interface Props {
}

interface State {
  hasError: boolean;
  eventId?: string;
}

/**
 * Cannot migrate this to React Hooks, because there is no equivalent to `componentDidCatch` yet.
 * https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
 */
@observer
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({hasError: true});
    // You can also log the error to an error reporting service
    Sentry.withScope(scope => {
      scope.setExtra('errorInfo', info);
      const eventId = Sentry.captureException(error);
      this.setState({eventId});
    });
  }

  render() {
    if (this.state.hasError || errorStore.hasErrors) {
      return (
        <Dialog open={true}>
          <DialogTitle>{'An unexpected error occurred'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can try to reload and see if that resolves the problem.
            </DialogContentText>
            {this.state.eventId &&
            <DialogContentText
              variant="caption"
            >
              Error ID: {this.state.eventId}
            </DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload(true)} color="primary">
              Reload
            </Button>
          </DialogActions>
        </Dialog>);
    }
    return this.props.children;
  }
}
