import {myAxios} from "../myAxios";
import {useEffect, useState} from "react";

export async function toObjectUrl(url: string): Promise<string> {
  const response = await (await myAxios()).get(url, {responseType: 'blob'});
  const data = await response.data;
  return URL.createObjectURL(data);
}

export function useObjectUrl(url: string | null): string | undefined {
  const [objectUrl, setObjectUrl] = useState(null as string | null);
  useEffect(() => {
    if (url) {
      toObjectUrl(url).then(setObjectUrl);
    }
  }, [url]);
  return objectUrl || undefined;
}
