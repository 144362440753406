import * as React from 'react';
import {useEffect, useState} from 'react';
import {grey, red} from '@material-ui/core/colors';
import Spinner from "../../../spinner/Spinner";
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import ButtonBase from "@material-ui/core/ButtonBase/ButtonBase";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Video} from "../../../components/Video";
import CloseIcon from '@material-ui/icons/Close';
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    transition: theme.transitions.create(['box-shadow', 'border-color'], {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19)',
      borderColor: grey[400],
    },
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 1000,
    border: 'solid 3px #ccc',
    backgroundColor: grey[700],
    borderColor: grey[200],
  },
  centered: {
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  videoCamIcon: {
    [theme.breakpoints.up('md')]: {
      height: '2em',
      width: '2em',
      fontSize: 36
    }
  },
  buttonBase: {
    margin: 8,
    borderRadius: 1000,
    zIndex: 40,
    position: 'relative',
    width: '100px',
    height: '100px',
    [theme.breakpoints.up('md')]: {
      width: '150px',
      height: '150px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      height: '200px',
    }
  },
  cancelButton: {
    position: 'absolute',
    padding: 5,
    borderRadius: 1000,
    zIndex: 42,
    backgroundColor: red[500],
    borderStyle: 'solid',
    borderColor: '#f5f5f5',
    borderWidth: 2,
    bottom: '6%',
    right: '6%',
    [theme.breakpoints.up('md')]: {
      padding: 8,
      bottom: '8%',
      right: '8%',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 9,
      bottom: '9%',
      right: '9%',
    }
  },
  closeIcon: {
    margin: 0,
    color: 'white'
  }
}));

export function VideoCircle(props: {
  stream?: MediaStream,
  name: { first: string, last: string } | null,
  color: string,
  isSelf: boolean,
  audioEnabled: boolean,
  videoEnabled: boolean,
  ownStreamIsFrontFacing: boolean,
  withCancelButton: boolean,
  onClick?(): void,
  onCancelClick(): void,
}) {
  const classes = useStyles();
  const [clickable, setClickable] = useState(false);
  useEffect(() => {
    const timer = window.setTimeout(() => setClickable(true), 750);
    return () => clearTimeout(timer);
  });

  const {stream, audioEnabled, videoEnabled, onClick, name, isSelf, ownStreamIsFrontFacing} = props;
  let displayName = 'Client';
  if (name) {
    displayName = `${name.first} ${name.last}`;
  }
  if (isSelf) {
    displayName = `yourself`;
  }

  return (
    <div
      title={stream ? `Show ${displayName} large for everyone` : `Calling ${displayName}...`}
      style={{display: 'inline-flex', position: 'relative'}}
    >
      <ButtonBase className={classes.buttonBase}>
        <Video
          mirrored={isSelf && ownStreamIsFrontFacing}
          stream={stream}
          audioEnabled={audioEnabled}
          videoEnabled={videoEnabled}
          className={classes.root}
          // Uncomment to match the bubble's border color with the user's color
          // style={{borderColor: props.color}}
          onClick={clickable ? onClick : undefined}
        />
        {!stream && (
          <div
            className={classes.root}
            // Uncomment to match the bubble's border color with the user's color
            // style={{borderColor: props.color}}
            onClick={clickable ? onClick : undefined}
          >
            <Spinner classNameOuter={classes.centered}/>
          </div>
        )}
        {stream && !videoEnabled && (
          <div
            className={classes.root}
            // Uncomment to match the bubble's border color with the user's color
            // style={{borderColor: props.color}}
            onClick={clickable ? onClick : undefined}
          >
            <VideocamOffIcon fontSize="large" className={`${classes.centered} ${classes.videoCamIcon}`}
                             color="secondary"/>
          </div>
        )}
      </ButtonBase>
      {props.withCancelButton && (
        <ButtonBase className={classes.cancelButton} title="Cancel invite" onClick={props.onCancelClick}>
          <CloseIcon className={classes.closeIcon}/>
        </ButtonBase>
      )}
    </div>
  );
}
