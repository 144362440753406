import * as React from 'react';
import {User} from '../../../api/userTypes';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {Username} from '../../../components/Username';
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import PersonIcon from "@material-ui/icons/Person";

type Props = {
  agent: User;
  onClick(): void;
};

type PropsWithStyle = Props & WithStyles<LocalStyles>;

export type LocalStyles = 'root';

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = theme => ({
  root: {
    marginBottom: theme.spacing(),
    backgroundColor: theme.palette.background.paper,
  },
});

export class ContactListElement extends React.PureComponent<PropsWithStyle> {

  render() {
    const {agent, classes, onClick} = this.props;
    return (
      <ListItem button={true} onClick={onClick} className={classes.root}>
        <ListItemIcon>
          <PersonIcon/>
        </ListItemIcon>
        <ListItemText>
          <Username user={agent}/>
        </ListItemText>
      </ListItem>
    );
  }
}

export default withStyles(localStyles)(ContactListElement);
