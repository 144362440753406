import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import TextField from '@material-ui/core/TextField';
import {Client} from '../../../api/clientTypes';
import {IObservableObject, IObservableValue, observable} from 'mobx';
import {Item, ItemRequest} from '../../../api/itemTypes';
import {User} from '../../../api/userTypes';
import {userToString} from '../../../components/Username';
import {observer} from 'mobx-react';
import {FieldError} from '../../../api/restApi';
import {createPicker} from '../../../util/picker';
import {renderRemainingErrors, textFieldErrorProps} from '../../../util/formUtils';
import ImageInputForSubject from '../../../components/ImageInputForSubject';
import {Snackbar, Typography, WithStyles} from '@material-ui/core';
import {MyDialogStyles} from '../../../styles/myDialogStyles';
import * as fileApi from '../../../api/fileApi';
import Button from '@material-ui/core/Button/Button';
import FileDownloadIcon from '../../../3rdParty/icons/FileDownload';
import LinkIcon from '@material-ui/icons/Link';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import {Attachment} from "../../../components/Attachment";
import {toObjectUrl} from "../../../util/hooks/useObjectUrl";
import {saveAs} from "file-saver";
import MuiAlert from '@material-ui/lab/Alert';

type Props = {
  clients: Client[];
  supportAgents: User[];
  item: ItemRequest & IObservableObject;
  /** The existing item to be edited. Is undefined when creating a new one. */
  existingItem?: Item;
  dirtyState: IObservableValue<boolean>;
  inProgressCounter: IObservableValue<number>;
  fieldErrors: FieldError[];
} & WithStyles<MyDialogStyles>;

const MAX_ATTACHMENT_FILE_SIZE = 15 * 1024 * 1024;

@observer
export class ItemForm extends React.Component<Props> {

  state = {snackbarVisible: false}

  showSnackbar = () => this.setState(() => ({snackbarVisible: true}))
  hideSnackbar = () => this.setState(() => ({snackbarVisible: false}))

  @observable attachmentUploadErrorText?: string = undefined;
  @observable qrImageObjectUrl?: string = undefined;

  componentDidMount(): void {
    if (this.props.existingItem) {
      toObjectUrl(this.props.existingItem._links.qrImage).then(objectUrl => {
        this.qrImageObjectUrl = objectUrl;
      });
    }
  }

  render() {
    const errorPicker = createPicker(this.props.fieldErrors);
    const existingItem = this.props.existingItem;
    return (
      <>
        <ImageInputForSubject
          classes={{root: this.props.classes.imageInput}}
          dirtyState={this.props.dirtyState}
          inProgressCounter={this.props.inProgressCounter}
          newSubject={this.props.item}
          existingSubject={this.props.existingItem}
        />
        {existingItem && this.qrImageObjectUrl &&
        <div
          className={this.props.classes.itemQrCodeStyle}
        >
          <img
            src={this.qrImageObjectUrl}
          />
          <div>
            <Button
              variant="outlined"
              size="small"
              onClick={async () => {
                const objectUrl = await toObjectUrl(existingItem._links.qrImageDownload);
                saveAs(objectUrl, `qrcode-${existingItem.id}.png`);
              }}
            >
              <FileDownloadIcon style={{fontSize: 18}}/>
              PNG
            </Button>
            <Button
              variant="outlined"
              size="small"
              style={{
                marginLeft: 8
              }}
              onClick={async () => {
                const objectUrl = await toObjectUrl(existingItem._links.qrImageDownloadPdf);
                saveAs(objectUrl, `qrcode-${existingItem.id}.pdf`);
              }}
            >
              <FileDownloadIcon style={{fontSize: 18}}/>
              PDF
            </Button>
            <Button
              variant="outlined"
              size="small"
              style={{
                marginLeft: 8
              }}
              onClick={async () => {
                await navigator.clipboard.writeText(existingItem._links.self);
                this.showSnackbar();
              }}
            >
              <LinkIcon/>
              Link

            </Button>
            <Snackbar open={this.state.snackbarVisible} autoHideDuration={6000} onClose={this.hideSnackbar}>
              <MuiAlert elevation={6} variant="filled" onClose={this.hideSnackbar} severity="info">
                Item Link was copied to clipboard
              </MuiAlert>
            </Snackbar>
          </div>
        </div>
        }
        <div className={this.props.classes.nextToImageInput}>
          <TextField
            id="itemForm_input_name"
            className={this.props.classes.noMarginTop}
            label="Item Name"
            value={this.props.item.name}
            onChange={ev => {
              this.props.item.name = ev.target.value;
              this.props.dirtyState.set(true);
            }}
            margin="dense"
            fullWidth={true}
            required={true}
            {...textFieldErrorProps(errorPicker.pick(e => e.field === 'name'))}
          />
          {this.props.existingItem &&
              <Typography variant="body1">
                ID {this.props.existingItem.itemCode}
              </Typography>
          }
        </div>
        <TextField
          id="itemForm_input_description"
          label="Description"
          value={this.props.item.description}
          onChange={ev => {
            this.props.item.description = ev.target.value;
            this.props.dirtyState.set(true);
          }}
          margin="dense"
          fullWidth={true}
          multiline={true}
          {...textFieldErrorProps(errorPicker.pick(e => e.field === 'description'))}
        />
        <TextField
          id="itemForm_input_internalNote"
          label={
            <span>Internal note <Typography
              variant="body2"
              style={{color: 'red', display: 'inline'}}>(only visible for support agents)
                  </Typography>
                  </span>
          }
          value={this.props.item.internalNote || ''}
          onChange={ev => {
            this.props.item.internalNote = ev.target.value;
            this.props.dirtyState.set(true);
          }}
          margin="dense"
          fullWidth={true}
          multiline={true}
          {...textFieldErrorProps(errorPicker.pick(e => e.field === 'internalNote'))}
        />
        <TextField
          id="itemForm_input_client"
          label="Client"
          value={this.props.item.client.id}
          onChange={e => {
            this.props.item.client.id = Number.parseInt(e.target.value, 10);
            this.props.dirtyState.set(true);
          }}
          margin="dense"
          select={true}
          SelectProps={{SelectDisplayProps: {id: 'itemForm_select_client'}}}
          fullWidth={true}
          required={true}
          {...textFieldErrorProps(errorPicker.pick(e => e.field === 'client.id'))}
        >
          {this.props.clients.map(client => (
            <MenuItem key={client.id} value={client.id}>
              {client.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="itemForm_input_supportAgent"
          label="Support Agent"
          value={this.props.item.supportAgent.id}
          onChange={e => {
            this.props.item.supportAgent.id = Number.parseInt(e.target.value, 10);
            this.props.dirtyState.set(true);
          }}
          margin="dense"
          select={true}
          SelectProps={{SelectDisplayProps: {id: 'itemForm_select_supportAgent'}}}
          fullWidth={true}
          required={true}
          {...textFieldErrorProps(errorPicker.pick(e => e.field === 'supportAgent.id'))}
        >
          {this.props.supportAgents.map(agent => (
            <MenuItem key={agent.id} value={agent.id}>
              {userToString(agent)}
            </MenuItem>
          ))}
        </TextField>

        <Typography variant="caption">
          Attachments
        </Typography>

        <input
          id="uploadAttachment"
          type="file"
          style={{
            display: 'none',
          }}
          onChange={e => {
            e.preventDefault();
            // Check that the files length so avoid issue with IE11 calling the handler twice,
            // see also https://github.com/facebook/react/issues/8793
            if (e.target.files != null && e.target.files.length > 0) {
              const f = e.target.files[0];
              if (f.size > MAX_ATTACHMENT_FILE_SIZE) {
                this.attachmentUploadErrorText = 'File must not be bigger than 15MB';
                return;
              } else {
                this.attachmentUploadErrorText = undefined;
              }
              this.props.dirtyState.set(true);
              this.props.inProgressCounter.set(this.props.inProgressCounter.get() + 1);
              fileApi.uploadFile(f).then(file => {
                  this.props.item.attachments.unshift(file); // unshift = prepend
                  this.props.inProgressCounter.set(this.props.inProgressCounter.get() - 1);
                }
              );
            }
            e.target.value = ''; // Reset value, so user may upload the same filename again
          }}
        />
        <label htmlFor="uploadAttachment">
          <Button
            size="large"
            component="div"
          >
            Add Attachment...
          </Button>
          {this.attachmentUploadErrorText &&
          <FormHelperText
            error={true}
          >
            {this.attachmentUploadErrorText}
          </FormHelperText>}
        </label>

        <div>
          {this.props.item.attachments.map((attachment, index) =>
            <Attachment
              key={attachment.id}
              attachment={attachment}
              onDelete={() => {
                this.props.item.attachments.splice(index, 1);
                this.props.dirtyState.set(true);
              }}
            />
          )}
        </div>
        {renderRemainingErrors(errorPicker.remaining())}
      </>
    );
  }
}
