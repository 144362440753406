import * as React from 'react';
import {AccountDto, User} from '../../../api/userTypes';
import List from '@material-ui/core/List/List';
import * as supportAgentApi from '../../../api/supportAgentApi';
import {fullPageable} from '../../../query/pageable';
import {Spinner} from '../../../spinner/Spinner';
import ContactListElement from "./ContactListElement";

type Props = {
  account: AccountDto;
  onClick(user: User): void;
  userIdsToHide?: Array<number>;
};

type State = {
  supportAgents?: Array<User>;
}

export class ContactList extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    supportAgentApi.getAll({}, fullPageable())
      .then(supportAgents => supportAgents.content)
      .then(supportAgents => this.setState({supportAgents}));
  }

  render() {
    return (
      <>
        {!this.state.supportAgents && <Spinner/>}
        {this.state.supportAgents && (
          <List>
            {this.state.supportAgents
              .filter(agent => !this.props.userIdsToHide || this.props.userIdsToHide.indexOf(agent.id) === -1)
              .map(agent => (
                <ContactListElement
                  key={agent.id}
                  agent={agent}
                  onClick={() => this.props.onClick(agent)}
                />
              ))}
          </List>
        )}
      </>
    );
  }
}

export default ContactList;
