import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from "@material-ui/core/InputBase/InputBase";
import Divider from "@material-ui/core/Divider/Divider";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import {CONTAINER_MAX_WIDTH} from "../../../styles/muiTheme";

type Props = {
  onConfirm(itemCode: string): void;
  onChange(input: string): void;
  value: string;
  invalid: boolean;
} & WithStyles<LocalStyles>;

export type LocalStyles = 'root' | 'input' | 'iconButton' | 'divider';

const localStyles = (theme: Theme) => ({
  root: {
    maxWidth: CONTAINER_MAX_WIDTH / 2,
    width: '100%',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

class ItemScanner extends React.PureComponent<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const {classes, onConfirm, onChange, value} = this.props;
    return (
      <div className={classes.root}>
        <InputBase
          value={value}
          onChange={event => onChange(event.target.value)}
          className={classes.input}
          placeholder="Enter Item-Id"
          onKeyPress={event => {
            if (event.key === 'Enter' && value.length > 0) {
              onConfirm(value);
              event.preventDefault();
            }
          }}
        />
        <Divider className={classes.divider}/>
        <IconButton disabled={value.length <= 0} color="primary" className={classes.iconButton}
                    onClick={() => onConfirm(value)}>
          <AddIcon/>
        </IconButton>
      </div>
    );
  }

}

export default withStyles(localStyles)(ItemScanner)
