import * as React from 'react';
import {MouseEvent, ReactNode, useState} from 'react';
import {ButtonProps} from '@material-ui/core/Button';
import {Button, IconButton} from "@material-ui/core";
import {IconButtonProps} from "@material-ui/core/IconButton";

export function SingleClickButton(props: {
  onSingleClick: (e: MouseEvent<any>) => Promise<any>,
  children: ReactNode,
  buttonProps: ButtonProps,
}) {
  const [pending, setPending] = useState(false);
  return <Button
    {...props.buttonProps}
    onClick={ev => {
      if (pending) return;
      setPending(true);
      props.onSingleClick(ev).then(() => setPending(false));
    }
    }
    disabled={pending || props.buttonProps.disabled}
  >
    {props.children}
  </Button>;
}

export function SingleClickIconButton(props: {
  onSingleClick: (e: MouseEvent<any>) => Promise<any>,
  children: ReactNode,
  buttonProps: IconButtonProps,
}) {
  const [pending, setPending] = useState(false);
  return <IconButton
    {...props.buttonProps}
    onClick={ev => {
      if (pending) return;
      setPending(true);
      props.onSingleClick(ev).then(() => setPending(false));
    }
    }
    disabled={pending || props.buttonProps.disabled}
  >
    {props.children}
  </IconButton>;
}
