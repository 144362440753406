import * as React from 'react';
import {AccountDto, User} from '../../../api/userTypes';
import ContactList from "./ContactList";
import Dialog from "@material-ui/core/Dialog/Dialog";
import withMobileDialog, {InjectedProps} from "@material-ui/core/withMobileDialog/withMobileDialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";

type Props = {
  account: AccountDto,
  open: boolean,
  onClose(): void,
  onClick(user: User): void,
  userIdsToHide?: Array<number>,
} & InjectedProps;

export class ContactsListDialog extends React.PureComponent<Props> {

  render() {
    const {account, fullScreen, open, onClose, onClick, userIdsToHide} = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          Select the user that you want to add to the call
        </DialogTitle>
        <ContactList account={account} onClick={onClick} userIdsToHide={userIdsToHide}/>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog<Props>()(ContactsListDialog);
