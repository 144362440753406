import * as Sentry from '@sentry/browser';
import {addUnhandledRejectionListener} from './errorListenerUtils';
import {SENTRY_DNS_URL} from "../branding/general";

/**
 * Configures the Sentry JS client (https://github.com/getsentry/raven-js).
 * The WEB-UI is at https://sentry.io/kurzdigital/remotexpert-web
 */
export function initSentry() {
  // Installs an `window.onerror` listener
  Sentry.init({
    dsn: SENTRY_DNS_URL,
    release: process.env.REACT_APP_GIT_TAG,
    environment: window.location.hostname,
  });
  Sentry.configureScope(scope => {
    scope.setTag('git_tag', process.env.REACT_APP_GIT_TAG || 'undefined');
    scope.setTag('git_commit', process.env.REACT_APP_GIT_COMMIT || 'undefined');
  });
  addUnhandledRejectionListener((e: PromiseRejectionEvent) => {
      Sentry.captureException(e.reason);
    }
  );
}
