import * as React from 'react';
import {BrowserRouter} from "react-router-dom";
import {AccountDto} from "../api/userTypes";
import {isEndUser, isPlatformAdmin, isSupportAgent, isTenantAdmin} from "../permissions";
import {Redirect, Route, Switch} from "react-router";
import DashboardPage from "../pages/admin/dashboard/DashboardPage";
import {LocalStreamSupplier} from "../pages/normal/call/LocalStreamSupplier";
import CallPage from "../pages/normal/call/CallPage";
import {adminTheme, callTheme} from "../styles/muiTheme";
import {LandingPage} from "../pages/normal/LandingPage";
import {ItemScannerPage} from "../pages/normal/item-scanner/ItemScannerPage";
import {ItemDetailsPage} from "../pages/normal/items/details/ItemDetailsPage";
import {EndUserItemsPage} from "../pages/normal/items/EndUserItemsPage";
import {NotFoundPage} from "./NotFoundPage";
import AddItemPage from "../pages/normal/add-item/AddItemPage";
import {SupportCaseDetailsPage} from '../pages/normal/support-case/details/SupportCaseDetailsPage';
import {NotificationHandlerContext} from '../components/NotificationHandler';
import * as userAgentDetect from "../util/userAgentDetect";
import * as branding from "../branding/general";
import {MuiThemeProvider, Typography} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {CallListPage} from "../pages/platform/CallListPage";
import {NotAllowedPage} from "./NotAllowedPage";
import {CheckPage} from "../pages/normal/check/CheckPage";

type Props = {
  account: AccountDto;
};

export const routes = {
  home: '/',
  platformAdmin: '/platform',
  tenantAdmin: '/admin',
  scanItem: '/scan-item',
  addItem: (itemCode: string) => `/add-item/${itemCode}`,
  call: (callId: number) => `/calls/${callId}`,
  itemDetails: (itemId: number) => `/items/${itemId}`,
  supportCaseDetails: (supportCaseId: number) => `/support-cases/${supportCaseId}`,
};

export class MyRouter extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          {this.getRootRoute()}
          {this.getTenantAdminRoute()}
          {this.getPlatformAdminRoute()}
          {this.getScanItemRoute()}
          {this.getAddItemRoute()}
          {this.getCallRoute()}
          {this.getItemDetailsRoute()}
          {this.getSupportCaseDetailsRoute()}
          {this.getCheckRoute()}
          <Route path="*" render={() => <NotFoundPage/>}/>
        </Switch>
      </BrowserRouter>
    );
  }

  /**
   * Gets the route for the root route ('/').
   * The rendered page depends on who is currently logged in.
   */
  private getRootRoute = () => {
    const {account} = this.props;
    if (isPlatformAdmin(account!!)) {
      return (
        <Redirect
          exact={true}
          path="/"
          to={routes.platformAdmin}
        />
      );
    }
    if (isTenantAdmin(account!!)) {
      return (
        <Redirect
          exact={true}
          path="/"
          to={routes.tenantAdmin}
        />
      );
    }
    if (isSupportAgent(account!!)) {
      return (
        <Route
          exact={true}
          path="/"
          render={() => <LandingPage account={account!!}/>}/>
      );
    }
    if (isEndUser(account!!)) {
      return (
        <Route
          exact={true}
          path="/"
          render={() => <EndUserItemsPage account={account!!}/>}/>
      );
    }

    return null;
  };

  private getPlatformAdminRoute = () => {
    return (
      <Route
        exact={true}
        path={routes.platformAdmin}
        render={props =>
          isPlatformAdmin(this.props.account) ? <CallListPage/> : <NotAllowedPage/>
        }
      />
    );
  };

  private getTenantAdminRoute = () => {
    const {account} = this.props;
    return (
      <Route
        exact={true}
        path={routes.tenantAdmin}
        render={props =>
          isTenantAdmin(this.props.account) ?
            <MuiThemeProvider theme={adminTheme}><DashboardPage account={account!!}/></MuiThemeProvider> :
            <NotAllowedPage/>
        }
      />
    );
  };

  private getItemDetailsRoute = () => {
    return (
      <Route
        exact={true}
        path="/items/:itemId"
        render={props => {
          const itemId = props.match.params.itemId;
          return <ItemDetailsPage itemId={itemId}/>;
        }}
      />
    );
  };

  private getSupportCaseDetailsRoute = () => {
    return (
      <Route
        exact={true}
        path="/support-cases/:supportCaseId"
        render={props => {
          const supportCaseId = props.match.params.supportCaseId;
          return <SupportCaseDetailsPage supportCaseId={supportCaseId}/>;
        }}
      />
    );
  };

  /**
   * Gets the route for scanning items ('/scan-item').
   */
  private getScanItemRoute = () => {
    const {account} = this.props;
    if (!isEndUser(account!!)) {
      return null;
    }

    return (
      <Route
        exact={true}
        path="/scan-item"
        render={() => <ItemScannerPage/>}/>
    );
  };

  /**
   * Gets the route for adding items ('/add-item/:itemCode').
   */
  private getAddItemRoute = () => {
    const {account} = this.props;
    if (!isEndUser(account!!)) {
      return null;
    }

    return (
      <Route
        exact={true}
        path="/add-item/:itemCode"
        render={props => {
          // On iOS devices redirect to app store
          // If the App is already installed, this URL will already have been redirected to the app using universal links
          if (userAgentDetect.isIOS && branding.APPLE_APP_STORE_URL) {
            console.info(`Detected add-item URL on iOS device, redirecting to app store`);
            window.location.replace(branding.APPLE_APP_STORE_URL);
          }
          const itemCode = props.match.params.itemCode;
          return <AddItemPage itemCode={itemCode}/>;
        }}
      />
    );
  };

  /**
   * Gets the route for calls ('/calls/:callId').
   */
  private getCallRoute = () => {
    const {account} = this.props;

    if (isTenantAdmin(account!!)) {
      return null;
    }

    return (
      <Route
        exact={true}
        path="/calls/:callId"
        render={props => {
          const callId = props.match.params.callId;
          return (
            <ThemeProvider theme={callTheme}>
              {/* Actually CssBaseline should set the default color to white,
              however nested CssBaselines don't see to work.
              So we use <Typography component="div" color="textPrimary"> to provide
              the default text color for this dark theme. */}
              <CssBaseline>
                <Typography component="div" color="textPrimary">
                  <LocalStreamSupplier
                    render={props => (
                      <NotificationHandlerContext.Consumer>
                        {notificationHandler => <CallPage
                          {...props}
                          notificationHandler={notificationHandler}
                          account={account!!}
                          callId={callId}
                        />}
                      </NotificationHandlerContext.Consumer>
                    )}
                  />
                </Typography>
              </CssBaseline>
            </ThemeProvider>
          );
        }}/>
    );
  };

  /**
   * Gets the route for checking call requirements ('/check').
   */
  private getCheckRoute = () => {
    return (
      <Route
        exact={true}
        path="/check"
        render={() => <CheckPage/>}/>
    );
  };

}
