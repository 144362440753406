import * as React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";

type Props = {
  open: boolean,
  handleDialogClose?(): void,
  withoutOkButton?: boolean
};

export class NoCameraWarning extends React.Component<Props> {

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleDialogClose}>
        <DialogTitle>Failed to access camera or microphone!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If your device has a camera and microphone,
            make sure to allow the website to access it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleDialogClose}
            color="primary"
            component="a"
            href="https://support.google.com/chrome/answer/2693767"
            target="_blank"
          >
            Help
          </Button>
          {!this.props.withoutOkButton && (
            <Button onClick={this.props.handleDialogClose} color="primary">
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default NoCameraWarning;
