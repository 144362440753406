import {myAxios} from '../util/myAxios';

/** Mirrors [com.kurzdigital.remotexpert.image.ImageDto] */
export interface Image {
  id: number;
  _links: {
    self: string;
    image: string;
  };
}

export function uploadImage(file: File): Promise<Image> {
  return myAxios()
    .then(axios => {
      const form = new FormData();
      form.append('file', file, file.name || '');
      return axios.post(`/api/images`, form);
    })
    .then(response => response.data);
}
