import * as React from 'react';
import {observer} from 'mobx-react';
import {IObservableValue, observable} from 'mobx';
import {ButtonProps} from '@material-ui/core/Button';
import Button from '@material-ui/core/Button/Button';

export interface Props {
  renderDialog: (dialogOpen: IObservableValue<boolean>) => JSX.Element;
}

/**
 * A button that will open a [Dialog] when clicked.
 * The rendering of the dialog can be defined using the render prop `renderDialog`.
 * See also https://reactjs.org/docs/render-props.html
 */
@observer
export class ButtonWithDialog extends React.Component<ButtonProps & Props> {
  private dialogOpen: IObservableValue<boolean> = observable.box(false);

  render() {
    const {renderDialog, ...elemProps} = this.props;

    return (
      <>
        {renderDialog(this.dialogOpen)}
        <Button
          onClick={() => {
            this.dialogOpen.set(true);
          }}
          {...elemProps}
        >
          {this.props.children}
        </Button>
      </>);
  }
}
