import * as React from 'react';
import {WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {Client} from '../../../api/clientTypes';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import {IObservableObject, IObservableValue, observable} from 'mobx';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import * as clientApi from '../../../api/clientApi';
import {isValidationErrors, ValidationErrors} from '../../../api/restApi';
import {observer} from 'mobx-react';
import ClientForm from './ClientForm';
import {DialogWithDirtyWarning} from '../../../components/dialogs/DialogWithDirtyWarning';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {MyDialogStyles, myDialogStyles} from '../../../styles/myDialogStyles';
import {ClientIcon} from '../../../styles/icons';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Typography from '@material-ui/core/Typography/Typography';
import {SingleClickButton} from '../../../components/SingleClickButton';
import {ButtonWithDialog} from '../../../components/ButtonWithDialog';
import {ConfirmDeleteDialog} from '../../../components/dialogs/ConfirmDeleteDialog';

interface Props {
  open: boolean;
  client: Client & IObservableObject;
  onClose: () => void;
}

type PropsWithStyle = Props & WithStyles<MyDialogStyles>;

@observer
export class EditClientDialog extends React.Component<PropsWithStyle> {

  dirtyState: IObservableValue<boolean> = observable.box(false);
  inProgressCounter: IObservableValue<number> = observable.box(0);
  @observable validationErrors: ValidationErrors | undefined = undefined;

  constructor(props: PropsWithStyle) {
    super(props);
    this.init = this.init.bind(this);
  }

  /**
   * This Dialog component needs to remain mounted for nicer animations, so
   * we use this function triggered by `Dialog.onRendered` instead of `componentDidMount`
   * for initialization.
   */
  init() {
    this.validationErrors = undefined;
    this.dirtyState.set(false);
  }

  render() {
    return (
      <DialogWithDirtyWarning
        id="editClientDialog"
        dirtyState={this.dirtyState}
        open={this.props.open}
        onClose={this.props.onClose}
        fullWidth={true}
        maxWidth="md"
        onRendered={this.init}
        render={closeHandler => (
          <>
            <DialogTitle disableTypography={true}>
              <ClientIcon className={this.props.classes.titleIcon}/>
              <Typography variant="h6">{this.props.client.name}</Typography>
              <Typography variant="caption">Client</Typography>
              <IconButton
                onClick={closeHandler}
                className={this.props.classes.closeButton}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ClientForm
                classes={this.props.classes}
                client={this.props.client}
                existingClient={this.props.client}
                dirtyState={this.dirtyState}
                inProgressCounter={this.inProgressCounter}
                fieldErrors={this.validationErrors ? this.validationErrors.fieldErrors : []}
              />
            </DialogContent>
            <DialogActions>
              <ButtonWithDialog
                id="editClientDialog_button_delete"
                color="default"
                variant="contained"
                renderDialog={(dialogOpen) =>
                  <ConfirmDeleteDialog
                    text="You are about to delete a client. All items of this client will also be deleted. Are you sure?"
                    open={dialogOpen.get()}
                    onOk={() => {
                      clientApi.deleteClient(this.props.client.id).then(() => {
                        this.props.onClose();
                      });
                    }}
                    onDismiss={() => dialogOpen.set(false)}
                  />
                }
              >
                Delete
              </ButtonWithDialog>
              <SingleClickButton
                buttonProps={{
                  id: "editClientDialog_button_save",
                  color:"primary",
                  variant:"contained",
                  disabled: this.inProgressCounter.get() > 0,
                }}
                onSingleClick={() =>
                  clientApi.updateClient(this.props.client).then(data => {
                    if (isValidationErrors(data)) {
                      this.validationErrors = data;
                    } else {
                      this.validationErrors = undefined;
                      this.props.onClose();
                    }
                  })
                }
              >
                Save
              </SingleClickButton>
            </DialogActions>
          </>
        )}
      />
    );
  }
}

export default withStyles(myDialogStyles)(EditClientDialog);
