import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {CallStateContainer, isFirstNewer} from "./state/CallStateContainer";
import {Subscribe} from "unstated";
import {makeStyles} from "@material-ui/styles";
import {FittingCanvas} from "../../../components/drawing/FittingCanvas";
import {useFingerOrMouseMoveEffect} from "../../../util/hooks/useFingerOrMouseMoveEffect";
import Immutable from "immutable";
import {DrawingAndMarkerStateContainer} from "./state/DrawingAndMarkerStateContainer";

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    zIndex: 6,
  },
  hiddenImage: {
    zIndex: -100,
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }
});

type LinesMap = Immutable.Map<string, { // The key is the line id
  points: Array<{ x: number, y: number }>,
  color: string,
  id: string
}>;

export function DrawingCanvas(props: {
  imageDataUri: string,
  drawingColor: string,
  takeDrawingScreenshotRef(ref: null | (() => Promise<File | null>)): void
}) {
  const classes = useStyles();
  const [canvasRef, setCanvasRef] = useState(null as HTMLCanvasElement | null);
  const drawingAndMarkerStateContainerRef = useRef(null as DrawingAndMarkerStateContainer | null);
  const callStateContainerRef = useRef(null as CallStateContainer | null);
  const [image, setImage] = useState(null as HTMLImageElement | null);

  const currentLineIdRef = useRef(getRandomUuidV4());
  const alreadyDrawnLines = useRef(Immutable.Map() as LinesMap);

  function getVisibleLines(): LinesMap {
    if (callStateContainerRef.current && drawingAndMarkerStateContainerRef.current) {
      return drawingAndMarkerStateContainerRef.current.state.lines
        .filter(line => line.timestamp > callStateContainerRef.current!!.state.drawing.timestamp!!)
        .filter(line => {
          const visibility = callStateContainerRef.current!!.state.visibility.get(line.id);
          return !visibility || visibility.visible;
        })
        .sort((a, b) => isFirstNewer(a, b) ? 1 : -1);
    }
    return Immutable.Map();
  }

  useEffect(() => {
    alreadyDrawnLines.current = Immutable.Map();
    if (canvasRef && image) {
      const lines = getVisibleLines();
      drawLines(canvasRef, getVisibleLines(), Immutable.Map(), image);
      alreadyDrawnLines.current = lines;
    }
  }, [image, canvasRef]);

  useEffect(() => {
    props.takeDrawingScreenshotRef(async () => {
      if (!canvasRef) {
        return null;
      }
      return await takeScreenshot(canvasRef);
    })
  }, [canvasRef]);

  useFingerOrMouseMoveEffect({
    element: canvasRef,
    onStartNew: () => {
      currentLineIdRef.current = getRandomUuidV4();
    },
    onMove: (x, y) => {
      if (callStateContainerRef.current && drawingAndMarkerStateContainerRef.current && canvasRef) {
        const oldLines = drawingAndMarkerStateContainerRef.current.state.lines.get(currentLineIdRef.current);
        if (oldLines) {
          callStateContainerRef.current.drawLine({
            id: oldLines.id,
            color: oldLines.color,
            points: [...oldLines.points, {x: x / canvasRef.width, y: y / canvasRef.height}]
          })
        } else {
          callStateContainerRef.current.drawLine({
            id: currentLineIdRef.current,
            color: props.drawingColor,
            points: [{x: x / canvasRef.width, y: y / canvasRef.height}]
          })
        }
      }
    }
  }, [canvasRef, callStateContainerRef.current, currentLineIdRef, props.drawingColor]);

  return (
    <>
      <Subscribe to={[CallStateContainer, DrawingAndMarkerStateContainer]}>
        {(callStateContainer: CallStateContainer, drawingAndMarkerStateContainer: DrawingAndMarkerStateContainer) => {
          callStateContainerRef.current = callStateContainer;
          drawingAndMarkerStateContainerRef.current = drawingAndMarkerStateContainer;
          if (canvasRef && image) {
            const lines = getVisibleLines();
            drawLines(canvasRef, getVisibleLines(), alreadyDrawnLines.current, image);
            alreadyDrawnLines.current = lines;
          }
          return (
            <div className={classes.root}>
              <img
                alt="Hello dear friend, who uses a video conferencing tool with a screen reader (why?)! This image just exists for internal reasons and is not visible on the site. However, IntelliJ likes to complain about the missing 'alt' attribute, so here it is."
                src={props.imageDataUri}
                className={classes.hiddenImage}
                ref={setImage}
              />
              <FittingCanvas
                videoOrImage={() => image}
                canvasRef={setCanvasRef}
                onCanvasResize={() => {
                  if (canvasRef && image) {
                    const lines = getVisibleLines();
                    // We have to redraw all lines in case of a resize, because the old lines got removed
                    drawLines(canvasRef, lines, Immutable.Map(), image);
                    alreadyDrawnLines.current = lines;
                  }
                }}
                mirrored={false}
              />
            </div>
          );
        }}
      </Subscribe>
    </>
  );
}


/**
 * Draws the lines inside the given canvas.
 */
function drawLines(
  canvas: HTMLCanvasElement,
  lines: LinesMap,
  // We don't want to redraw all lines with every new render, but only the ones that are new.
  // Se we need to know the lines that are already drawn
  alreadyDrawnLines: LinesMap,
  image: HTMLImageElement
) {
  const context = canvas.getContext('2d')!!;

  let alreadyDrawnLinesContainsLinesThatAreNotInLines = alreadyDrawnLines
    .filter(line => !lines.has(line.id))
    .size > 0;

  if (alreadyDrawnLines.size <= 0 || alreadyDrawnLinesContainsLinesThatAreNotInLines) {
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    alreadyDrawnLines = Immutable.Map();
  }

  lines.forEach(line => {

    const alreadyDrawnLine = alreadyDrawnLines.get(line.id);

    if (alreadyDrawnLine && line.points.length <= alreadyDrawnLine.points.length) {
      // We don't have to draw this line again, because it is already completely drawn
      return;
    }

    let offset = alreadyDrawnLine ? alreadyDrawnLine.points.length : 1;
    if (offset > 1) {
      // This is not necessary, but it makes the lines appear smoother. Otherwise, we end with some small space
      // between the previously drawn line piece and the new one. Now we also draw the last bit of previous
      // line, which fixes this "problem"
      offset--;
    }

    context.beginPath();
    context.lineWidth = 5;
    context.strokeStyle = line.color;

    if (line.points.length >= offset) {
      context.moveTo(line.points[offset - 1].x * canvas.width, line.points[offset - 1].y * canvas.height);
    }
    for (let i = offset; i < line.points.length; i++) {
      const currentPoint = line.points[i];
      context.lineTo(currentPoint.x * canvas.width, currentPoint.y * canvas.height);
    }

    context.stroke();
  });
}

/**
 * Generates a random uuid. Might not be secure, but in our use case, this does not matter.
 */
function getRandomUuidV4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/**
 * Takes a screenshot of the current drawing.
 */
function takeScreenshot(canvas: HTMLCanvasElement): Promise<File> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob === null) {
        return reject('blob is null');
      }
      resolve(new File([blob], ''));
    });
  });
}
