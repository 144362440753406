import * as React from 'react';
import {AccountDto, User} from '../../../api/userTypes';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ContactList from "./ContactList";
import * as callApi from "../../../api/callApi";
import NoCameraWarning from "../../../components/NoCameraWarning";
import UserNotReachableDialog from "../../../components/dialogs/UserNotReachableDialog";
import {RouteComponentProps, withRouter} from "react-router";
import {declineInvite} from "../../../api/inviteApi";
import Typography from "@material-ui/core/Typography/Typography";
import {routes} from "../../../router/MyRouter";
import {checkIfCameraAndAudioIsAvailable} from "../../../util/webrtc/webrtcUtil";

const BOTTOM_NAVIGATION_HEIGHT = 56;

type Props = {
  account: AccountDto;
} & RouteComponentProps & WithStyles<LocalStyles>;

type LocalStyles = 'contactListContainer' ;

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = theme => ({
  contactListContainer: {
    margin: 'auto',
    padding: theme.spacing(2),
    paddingBottom: BOTTOM_NAVIGATION_HEIGHT,
  },
});

type State = {
  noCameraWarningDialogOpen: boolean
  userNotReachableDialogOpen: boolean
}

export class ContactsPage extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      noCameraWarningDialogOpen: false,
      userNotReachableDialogOpen: false,
    };
  }

  render() {
    const {classes} = this.props;
    return (
      <>
        <div className={classes.contactListContainer}>
          <Typography variant="h5">
            Contacts
          </Typography>
          <ContactList account={this.props.account} onClick={this.handleSupportAgentClick}
                       userIdsToHide={[this.props.account.id]}/>
        </div>

        <NoCameraWarning
          open={this.state.noCameraWarningDialogOpen}
          handleDialogClose={this.handleDialogClose}
        />
        <UserNotReachableDialog
          open={this.state.userNotReachableDialogOpen}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }

  private handleSupportAgentClick = async (user: User) => {
    const cameraAvailable = await checkIfCameraAndAudioIsAvailable();
    if (cameraAvailable) {
      try {
        const call = await callApi.createCall({calleeId: user.id});
        setTimeout(() => {
          // Decline the invite after 60 seconds. If the user accepted it in the meanwhile we get a 400,
          // but this is no problem and we can just ignore it :-)
          declineInvite(call.invites[0].id).catch(() => null);
        }, 60 * 1000);
        this.props.history.push(routes.call(call.id));
      } catch (error) {
        if (error.response.status === /* Sorry, I cannot brew coffee, because */ 418 /* I'm a teapot */) {
          this.setState({userNotReachableDialogOpen: true});
          return;
        }
      }
    } else {
      this.setState({noCameraWarningDialogOpen: true});
    }
  };

  private handleDialogClose = () => {
    this.setState({noCameraWarningDialogOpen: false, userNotReachableDialogOpen: false});
  };
}

export default withRouter(withStyles(localStyles)(ContactsPage));
