import * as React from 'react';
import {CSSProperties, MouseEventHandler} from 'react';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import {observer} from 'mobx-react';
import {ImageContainer} from './ImageContainer';
import {observable} from 'mobx';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import {HiddenInput} from "./HiddenInput";
import {createStyles} from "@material-ui/styles";

interface Props {
  onUpload: (file: File) => Promise<any>;
  onDelete: () => void;
  allowDelete?: boolean;
  imageHref: string | null;
  style?: CSSProperties;
  className?: string;
  width?: number | string;
  height?: number | string;
}

export type ImageInputStyles = 'root' | 'imageContainer' | 'editOverlay';

type PropsWithStyle = Props & WithStyles<ImageInputStyles>;

export const imageInputStyles: StyleRulesCallback<Theme, {}, ImageInputStyles> = theme => createStyles({
  root: {},
  imageContainer: {
    '&:hover': {
      cursor: 'pointer',
      '& $editOverlay': {
        display: 'block',
      },
    },
  },
  editOverlay: {
    display: 'none',
    color: '#fff',
    position: 'absolute',
    width: '100%',
    padding: theme.spacing(),
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
  },
});

/**
 * Displays a given image or a placeholder if absent.
 * Provides the interaction to upload and delete the image.
 *
 * Note: We use a label element which opens the file upload dialog when clicked
 */
@observer
class ImageInput extends React.Component<PropsWithStyle> {
  @observable menuAnchorEl?: HTMLElement = undefined;

  render() {
    let {className, style, onUpload, imageHref, classes, allowDelete} = this.props;
    if (allowDelete === undefined) {
      allowDelete = true;
    }
    return (
      <div
        className={classes.root + (className ? (' ' + className) : '')}
        style={style}
      >
        <HiddenInput id="imageInput" accept=".png, .jpg, .jpeg, .gif" onUpload={onUpload}/>
        {imageHref && allowDelete && (
          <>
            {this.getMenu()}
            {this.getImageContainer('Edit Image', this.onImageContainerClick)}
          </>
        )}
        {imageHref && !allowDelete && (
          <label htmlFor="imageInput">
            {this.getImageContainer('Change Image')}
          </label>
        )}
        {!imageHref && (
          <label htmlFor="imageInput">
            {this.getImageContainer('Add Image')}
          </label>
        )}
        {this.props.children}
      </div>
    );
  }

  private getImageContainer = (text: string, onClick?: MouseEventHandler<HTMLElement>) => {
    const {imageHref, classes, width, height} = this.props;
    return (
      <ImageContainer
        imageHref={imageHref}
        classes={{
          root: classes.imageContainer,
        }}
        onClick={onClick}
        width={width}
        height={height}
      >
        <div style={{position: 'relative', maxWidth: '100%'}}>
          <div className={classes.editOverlay} style={{bottom: imageHref ? 4 : 0}}>
            <Typography variant="body2" color="inherit">
              {text}
            </Typography>
          </div>
        </div>
      </ImageContainer>
    );
  };

  private getMenu = () => {
    const {onDelete} = this.props;
    return (
      <Menu
        open={Boolean(this.menuAnchorEl)}
        anchorEl={this.menuAnchorEl}
        onClose={() => this.menuAnchorEl = undefined}
      >
        <label htmlFor="imageInput">
          <MenuItem>Upload image</MenuItem>
        </label>
        <MenuItem
          onClick={() => {
            onDelete();
            this.menuAnchorEl = undefined;
          }}
        >
          Delete image
        </MenuItem>
      </Menu>
    );
  };

  private onImageContainerClick: MouseEventHandler<HTMLElement> = ev => this.menuAnchorEl = ev.currentTarget;

}

export default withStyles(imageInputStyles)(ImageInput);
