import * as React from 'react';
import {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import EmbeddedPage from '../../../../components/EmbeddedPage';
import {LandingPageAppBar} from '../../LandingPageAppBar';
import {AppBarTopPadding} from '../../../../components/AppBarTopPadding';
import {createStyles, makeStyles} from '@material-ui/styles';
import {checkIfCameraAndAudioIsAvailable} from '../../../../util/webrtc/webrtcUtil';
import {declineInvite} from '../../../../api/inviteApi';
import * as callApi from '../../../../api/callApi';
import {useRouter} from '../../../../util/hooks/useRouterHook';
import * as H from 'history';
import {routes} from '../../../../router/MyRouter';
import Spinner from '../../../../spinner/Spinner';
import {Theme, Typography} from '@material-ui/core';
import {animated} from 'react-spring';
import InfoIcon from '@material-ui/icons/Info';
import {getCallsForSupportCase, getSupportCase} from '../../../../api/supportCaseApi';
import {SupportCase} from '../../../../api/supportCaseTypes';
import UserNotReachableDialog from '../../../../components/dialogs/UserNotReachableDialog';
import NoCameraWarning from '../../../../components/NoCameraWarning';
import Button from '@material-ui/core/Button';
import {Call, Screenshot} from '../../../../api/callTypes';
import {CallList} from './CallList';
import {useCurrentAccountHasRole} from '../../../../util/hooks/useCurrentAccount';
import {useMainLoadTransition} from '../../../../util/hooks/useMainLoadTransition';
import {ScreenshotDialog} from './ScreenshotDialog';
import {ChatDialog} from './ChatDialog';
import {SupportCaseInfoDialog} from './SupportCaseInfoDialog';
import {Item} from '../../../../api/itemTypes';
import {loadItem} from '../../../../api/itemApi';

const useStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    position: 'fixed',
  },
  button: {
    width: '100%',
    maxWidth: 400,
    height: 44,
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  callListContainer: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
}));

export function SupportCaseDetailsPage(props: {
  supportCaseId: number,
}) {
  const {history, location} = useRouter();
  const classes = useStyles();
  const [supportCase, setSupportCase] = useState(null as SupportCase | null);
  const [item, setItem] = useState(null as Item | null);
  const [calls, setCalls] = useState(null as Array<Call> | null);
  const [selectedScreenshot, setSelectedScreenshot] = useState(null as Screenshot | null);
  const [selectedChatOfCall, setSelectedChatOfCall] = useState(null as Call | null);
  const [supportCaseInfoDialogOpen, setSupportCaseInfoDialogOpen] = useState(false);
  const loading = supportCase === null || item === null;
  const mainLoadTransition = useMainLoadTransition({loading});
  useEffect(() => {
    getSupportCase(props.supportCaseId)
      .then(supportCase => {
        setSupportCase(supportCase);
        Promise.all([
          loadItem(supportCase.itemId).then(setItem),
          getCallsForSupportCase(props.supportCaseId, {
            page: 0,
            size: 99999,
            sort: {field: 'id', order: 'desc'},
          })
            .then(page => page.content)
            .then(setCalls)
        ]);
      })
      .catch(() => {
        history.push(routes.home + location.search);
      });
  }, []);
  return (
    <EmbeddedPage>
      <LandingPageAppBar
        title={<animated.span style={mainLoadTransition}>{supportCase && supportCase.name}</animated.span>}
        previousLocation={(supportCase ? routes.itemDetails(supportCase.itemId) : '/') + location.search}
        customRightIcon={<InfoIcon color="inherit"/>}
        onCustomRightButtonClick={() => setSupportCaseInfoDialogOpen(true)}
      />
      <AppBarTopPadding>
        <div style={{padding: 16}}>

          {loading && <Spinner/>}

          <animated.div style={mainLoadTransition}>
            <Grid container={true} spacing={2} direction="row-reverse">
              <Grid item={true} xs={12}>
                {supportCase && <SupportButton supportCase={supportCase}/>}
              </Grid>
              <Grid item={true} xs={12}>
                <Typography variant="h5">Support Case History</Typography>
                {calls && supportCase && <CallList
                  className={classes.callListContainer}
                  supportCase={supportCase}
                  calls={calls}
                  onChatClick={setSelectedChatOfCall}
                  onScreenshotClick={setSelectedScreenshot}
                />}
              </Grid>
            </Grid>
          </animated.div>
        </div>
      </AppBarTopPadding>
      <ScreenshotDialog
        screenshot={selectedScreenshot}
        onClose={() => setSelectedScreenshot(null)}
      />
      <ChatDialog
        supportCase={supportCase}
        call={selectedChatOfCall}
        onClose={() => setSelectedChatOfCall(null)}
      />
      {supportCase && item &&
      <SupportCaseInfoDialog
        open={supportCaseInfoDialogOpen}
        item={item}
        supportCase={supportCase}
        onUpdate={setSupportCase}
        onClose={() => setSupportCaseInfoDialogOpen(false)}
      />}
    </EmbeddedPage>
  );
}

function SupportButton(props: { supportCase: SupportCase }) {
  const [noCameraWarningDialogOpen, setNoCameraWarningDialogOpen] = useState(false);
  const [userNotReachableDialogOpen, setUserNotReachableDialogOpen] = useState(false);
  const isEnduser = useCurrentAccountHasRole('END_USER') === true;
  const classes = useStyles();
  const {history} = useRouter();
  return (
    <div style={{width: '100%'}}>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={() => handleGetSupport(history, props.supportCase, setUserNotReachableDialogOpen, setNoCameraWarningDialogOpen)}
      >
        {isEnduser ? 'Get Support' : 'Call Client'}
      </Button>
      <NoCameraWarning
        open={noCameraWarningDialogOpen}
        handleDialogClose={() => setNoCameraWarningDialogOpen(false)}
      />
      <UserNotReachableDialog
        open={userNotReachableDialogOpen}
        onClose={() => setUserNotReachableDialogOpen(false)}
      />
    </div>
  );
}

async function handleGetSupport(
  history: H.History,
  supportCase: SupportCase,
  setUserNotReachableDialogOpen: (open: boolean) => void,
  setNoCameraWarningDialogOpen: (open: boolean) => void
) {
  const cameraAvailable = await checkIfCameraAndAudioIsAvailable();
  if (cameraAvailable) {
    try {
      const call = await callApi.createCall({supportCaseId: supportCase.id});
      setTimeout(() => {
        // Decline the invite after 60 seconds. If the user accepted it in the meanwhile we get a 400,
        // but this is no problem and we can just ignore it :-)
        declineInvite(call.invites[0].id).catch(() => null);
      }, 60 * 1000);
      history.push(routes.call(call.id));
    } catch (error) {
      if (error.response.status === /* Sorry, I cannot brew coffee, because */ 418 /* I'm a teapot */) {
        setUserNotReachableDialogOpen(true);
        return;
      }
    }
  } else {
    setNoCameraWarningDialogOpen(true);
  }
}
