import {AxiosResponse} from 'axios';

export interface EntityRef {
  id: number;
}

/** Mirrors [com.kurzdigital.remotexpert.rest.HasLinks] */
export interface HasLinks {
  _links: {
    self: string;
  };
}

export interface FieldError {
  field: string;
  code: string;
  defaultMessage: string;
}

export interface ValidationErrors {
  fieldErrors: FieldError[];
}

export interface ValidationErrorResponse extends AxiosResponse {
  data: ValidationErrors;
}

export function isValidationErrorResponse(response: AxiosResponse): response is ValidationErrorResponse {
  return response !== undefined && response.status === 422;
}

export function isValidationErrors(data: object | ValidationErrors): data is ValidationErrors {
  return (data as ValidationErrors).fieldErrors !== undefined;
}

export function emptyValidationErrors(): ValidationErrors {
  return {
    fieldErrors: [],
  };
}

export function appendParametersToImageUrl(
  url: string,
  params: {
    mode?: 'fill' | 'fit',
    width?: number,
    height?: number
  } = {}): string {
  const arr = [];
  if (params.mode) arr.push(`mode=${params.mode}`);
  if (params.width) arr.push(`width=${params.width}`);
  if (params.height) arr.push(`height=${params.height}`);
  if (arr.length == 0) return url;
  const separator = url.indexOf('?') === -1 ? '?' : '&';
  return `${url}${separator}${arr.join('&')}`;
}
