import {checkIfLoggedIn, keycloak} from '../keycloak';
import axios, {AxiosInstance} from 'axios';
import * as Cookies from "js-cookie";

/**
 * @returns {Promise<AxiosInstance>} that resolves to a axios instance that sets the appropriate Authentication headers.
 * Refreshes the access token when necessary.
 */
export async function myAxios(): Promise<AxiosInstance> {
  const instance = axios.create();
  const loggedIn = await checkIfLoggedIn();

  if (!loggedIn) {
    let clientToken = Cookies.get('client-token');
    if (!clientToken) {
      const response = await instance.post('/api/client_auth_token');
      clientToken = response.data.token;
      Cookies.set('client-token', clientToken!!, {
        expires: 3650, // 10 years
        secure: process.env.NODE_ENV === 'production' // do not use secure mode during development
      });
    }
    instance.defaults.headers.common['Client-Auth-Token'] = clientToken;
  } else {
    instance.defaults.headers.common['Authorization'] = await new Promise(resolve => {
      keycloak.updateToken(5)
        .success(() => {
          resolve(`Bearer ${keycloak.token}`);
        })
        .error(() => {
          alert('Your session expired, you will be logged out.');
          keycloak.clearToken();
          keycloak.logout();
        })
    });
  }

  return instance;
}
