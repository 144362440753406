import * as React from 'react';
import {CSSProperties} from 'react';
import {TransitionState} from '../../../components/transitions';
import {Client} from '../../../api/clientTypes';
import {WithStyles} from '@material-ui/core';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import {ImageContainer} from '../../../components/ImageContainer';
import Typography from '@material-ui/core/Typography/Typography';
import {Clearfix} from '../../../components/Clearfix';
import Divider from '@material-ui/core/Divider/Divider';
import {applyCardImageParametersToImageUrl, DashboardStyles} from '../dashboard/DashboardPage';

export const ClientCard = (props: {
  style?: CSSProperties;
  transitionState: TransitionState;
  client: Client;
  onClick: () => void;
} & WithStyles<DashboardStyles>): JSX.Element => (
  <Card
    style={props.style}
    className={`${props.classes.card} client-card transition-${props.transitionState}`}
    onClick={props.onClick}
  >
    <CardContent className={props.classes.cardContent}>
      <ImageContainer
        classes={{
          root: props.classes.cardImageContainer,
        }}
        imageHref={applyCardImageParametersToImageUrl(props.client._links.image)}
      />
      <Typography variant="body1" align="right">
        {props.client.contactName}
      </Typography>
      <br/>
      <Typography variant="body1" align="right">
        {props.client.contactEmail}
      </Typography>
      <Clearfix/>
    </CardContent>
    <Divider/>
    <CardContent className={props.classes.cardContent}>
      <Typography variant="body2">
        {props.client.name}
      </Typography>
    </CardContent>
  </Card>);
