import * as React from 'react';
import {useEffect, useState} from 'react';
import {Dialog, MuiThemeProvider, Theme, useMediaQuery} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import {useCurrentAccountHasRole} from '../../../../util/hooks/useCurrentAccount';
import List from '@material-ui/core/List';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {makeStyles, useTheme} from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {greenButtonTheme, redButtonTheme} from '../../../../styles/muiTheme';
import {useSaveButtonState} from '../../../../util/hooks/useSaveButtonStates';
import {updateSupportCase} from '../../../../api/supportCaseApi';
import {isValidationErrors} from '../../../../api/restApi';
import {getHelperText, hasErrorInHelperTextProps} from '../../../../util/validation/helperTextUtil';
import {maxLengthValidation, notBlankValidation} from '../../../../util/validation/helperTextSuppliers';
import {SupportCase, SupportCaseStatus} from '../../../../api/supportCaseTypes';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Item} from '../../../../api/itemTypes';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(),
  }
}));

export function SupportCaseInfoDialog(props: {
  open: boolean,
  item: Item,
  supportCase: SupportCase,
  onClose(): void,
  onUpdate(supportCase: SupportCase): void
}) {
  const [name, setName] = useState(props.supportCase.name);
  const [status, setStatus] = useState(props.supportCase.status);
  const [description, setDescription] = useState(props.supportCase.description);
  const [reasonOfMalfunction, setReasonOfMalfunction] = useState(props.supportCase.reasonOfMalfunction || '');
  const [internalNote, setInternalNote] = useState(props.supportCase.internalNote || '');
  const classes = useStyles();

  const theme: Theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const deps = [name, status, description, reasonOfMalfunction, internalNote];
  const saveButtonState = useSaveButtonState(JSON.stringify(deps));
  useEffect(
    () => {
      saveButtonState.onChange(JSON.stringify(deps))
    },
    deps
  );

  let isEnduser = useCurrentAccountHasRole('END_USER');
  isEnduser = (isEnduser || isEnduser === null);

  const validations = {
    name: getHelperText(name, notBlankValidation, maxLengthValidation(1000)),
    description: getHelperText(description, maxLengthValidation(1000)),
    reasonOfMalfunction: getHelperText(reasonOfMalfunction, maxLengthValidation(1000)),
    internalNote: getHelperText(internalNote, maxLengthValidation(50000)),
  };

  const disabled = saveButtonState.disabled
    || Object.values(validations).some(hasErrorInHelperTextProps);

  async function onSave() {
    saveButtonState.onSave();
    const response = await updateSupportCase(props.supportCase.id, {
      ...props.supportCase,
      name,
      status,
      description,
      reasonOfMalfunction,
      internalNote,
    });
    saveButtonState.onSaveFinished();
    if (isValidationErrors(response)) {
      alert('Unexpected validation error: ' + JSON.stringify(response));
      console.error(response);
    } else {
      props.onUpdate(response);
    }
  }

  return (
    <Dialog fullScreen={isSmOrDown} open={props.open} onClose={saveButtonState.allowEdits ? props.onClose : undefined}
            maxWidth="md" fullWidth={true}>
      <DialogTitle>Case Details</DialogTitle>
      <DialogContent>
        <List style={{width: '100%'}}>
          <ListItem>
            <ListItemText primary="Item name" secondary={props.item.name}/>
          </ListItem>
          <ListItem>
            {isEnduser ?
              <ListItemText primary="Status" secondary={props.supportCase.status}/>
              :
              <div style={{width: '100%'}}>
                <ListItemText primary="Status"/>
                <TextField
                  value={status}
                  select={true}
                  fullWidth={true}
                  onChange={event => setStatus(event.target.value as SupportCaseStatus)}>
                  <MenuItem value="OPEN">OPEN</MenuItem>
                  <MenuItem value="CLOSED">CLOSED</MenuItem>
                </TextField>
              </div>
            }

          </ListItem>
          {isEnduser ?
            (props.supportCase.reasonOfMalfunction && <ListItem>
              <ListItemText primary="Reason of malfunction" secondary={props.supportCase.reasonOfMalfunction}/>
            </ListItem>)
            :
            <ListItem>
              <div style={{width: '100%'}}>
                <ListItemText primary="Reason of malfunction"/>
                <TextField
                  value={reasonOfMalfunction}
                  fullWidth={true}
                  {...validations.reasonOfMalfunction}
                  onChange={event => setReasonOfMalfunction(event.target.value)}>
                </TextField>
              </div>
            </ListItem>
          }
          <ListItem>
            {isEnduser ?
              <ListItemText primary="Support Case Name" secondary={props.supportCase.name}/>
              :
              <div style={{width: '100%'}}>
                <ListItemText primary="Support Case Name"/>
                <TextField
                  value={name}
                  fullWidth={true}
                  {...validations.name}
                  onChange={event => setName(event.target.value)}
                />
              </div>
            }
          </ListItem>
          <ListItem>
            {isEnduser ?
              (props.supportCase.description &&
                <ListItemText primary="Description" secondary={props.supportCase.description}/>)
              :
              <div style={{width: '100%'}}>
                <ListItemText primary="Description"/>
                <TextField
                  value={description}
                  fullWidth={true}
                  multiline={true}
                  {...validations.description}
                  onChange={event => setDescription(event.target.value)}>
                </TextField>
              </div>
            }
          </ListItem>
          {!isEnduser &&
          <ListItem>
            <div style={{width: '100%'}}>
              <ListItemText
                primary={
                  <span>Internal note <Typography
                    variant="body2"
                    style={{color: 'red', display: 'inline'}}>(only visible for support agents)
                  </Typography>
                  </span>}
              />
              <TextField
                value={internalNote}
                fullWidth={true}
                multiline={true}
                {...validations.internalNote}
                onChange={event => setInternalNote(event.target.value)}>
              </TextField>
            </div>
          </ListItem>}
        </List>
      </DialogContent>
      <DialogActions>
        <MuiThemeProvider theme={redButtonTheme}>
          <Button onClick={props.onClose} color="primary" disabled={!saveButtonState.allowEdits}
                  style={{marginRight: 4}}>
            <ClearIcon className={classes.buttonIcon}/>
            Close
          </Button>
        </MuiThemeProvider>
        {!isEnduser && (
          <MuiThemeProvider theme={greenButtonTheme}>
            <Button onClick={onSave} color="primary" disabled={disabled} style={{marginRight: 4}}>
              {saveButtonState.icon}
              Save
            </Button>
          </MuiThemeProvider>
        )}
      </DialogActions>
    </Dialog>
  );
}
