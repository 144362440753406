import {FileDto} from "../api/fileApi";
import * as React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import {saveAs} from 'file-saver';
import {toObjectUrl} from "../util/hooks/useObjectUrl";

export function Attachment(props: {
  attachment: FileDto,
  withDeleteButton?: boolean,
  onDelete?(): void
}): JSX.Element {
  return (
    <div style={{marginTop: 8}}>
      <Button
        variant="outlined"
        size="large"
        onClick={async () => {
          const objectUrl = await toObjectUrl(props.attachment._links.data);
          saveAs(objectUrl, props.attachment.name);
        }}
      >
        <GetAppIcon style={{marginRight: 12, marginLeft: -12}}/>
        {props.attachment.name}
      </Button>
      {(props.withDeleteButton || props.withDeleteButton == null) && (
        <IconButton style={{marginLeft: 8}} onClick={props.onDelete}><DeleteIcon/></IconButton>
      )}
    </div>
  );
}
