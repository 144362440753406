import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import {IObservableObject, IObservableValue} from 'mobx';
import {observer} from 'mobx-react';
import {Address, Client, ClientRequest} from '../../../api/clientTypes';
import AddressForm from './AddressForm';
import Grid from '@material-ui/core/Grid/Grid';
import {FieldError} from '../../../api/restApi';
import {createPicker} from '../../../util/picker';
import {renderRemainingErrors, textFieldErrorProps} from '../../../util/formUtils';
import ImageInputForSubject from '../../../components/ImageInputForSubject';
import {MyDialogStyles} from '../../../styles/myDialogStyles';
import {WithStyles} from '@material-ui/core';

type Props = {
  client: ClientRequest & IObservableObject;
  /** The existing client to be edited. Is undefined when creating a new one. */
  existingClient?: Client;
  dirtyState: IObservableValue<boolean>;
  inProgressCounter: IObservableValue<number>;
  fieldErrors: FieldError[];
} & WithStyles<MyDialogStyles>;

@observer
export class ClientForm extends React.Component<Props> {

  render() {
    const errorPicker = createPicker(this.props.fieldErrors);
    return (
      <div id="clientForm">
        <ImageInputForSubject
          classes={{root: this.props.classes.imageInput}}
          dirtyState={this.props.dirtyState}
          inProgressCounter={this.props.inProgressCounter}
          newSubject={this.props.client}
          existingSubject={this.props.existingClient}
        />
        <div className={this.props.classes.nextToImageInput}>
          <TextField
            id="clientForm_input_name"
            className={this.props.classes.noMarginTop}
            label="Client name"
            value={this.props.client.name}
            onChange={e => {
              this.props.client.name = e.target.value;
              this.props.dirtyState.set(true);
            }}
            margin="dense"
            fullWidth={true}
            required={true}
            {...textFieldErrorProps(errorPicker.pick(e => e.field === 'name'))}
          />
        </div>
        <AddressForm
          address={this.props.client.address as Address & IObservableObject}
          dirtyState={this.props.dirtyState}
          fieldErrorPrefix="address."
          fieldErrors={errorPicker.pick(e => e.field.startsWith('address.'))}
        />
        <TextField
          id="clientForm_input_contactPerson"
          label="Contact person"
          value={this.props.client.contactName}
          onChange={ev => {
            this.props.client.contactName = ev.target.value;
            this.props.dirtyState.set(true);
          }}
          margin="dense"
          fullWidth={true}
          required={true}
          {...textFieldErrorProps(errorPicker.pick(e => e.field === 'contactName'))}
        />
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={5} zeroMinWidth={true}>
            <TextField
              id="clientForm_input_contactPhone"
              label="Phone"
              value={this.props.client.contactPhone}
              onChange={ev => {
                this.props.client.contactPhone = ev.target.value;
                this.props.dirtyState.set(true);
              }}
              margin="dense"
              fullWidth={true}
              {...textFieldErrorProps(errorPicker.pick(e => e.field === 'contactPhone'))}
            />
          </Grid>
          <Grid item={true} xs={true} zeroMinWidth={true}>
            <TextField
              id="clientForm_input_contactEmail"
              label="E-mail"
              // type="email" (Commented out due to REM-641 and REM-642)
              value={this.props.client.contactEmail}
              onChange={ev => {
                this.props.client.contactEmail = ev.target.value;
                this.props.dirtyState.set(true);
              }}
              margin="dense"
              fullWidth={true}
              required={true}
              {...textFieldErrorProps(errorPicker.pick(e => e.field === 'contactEmail'))}
            />
          </Grid>
        </Grid>
        {renderRemainingErrors(errorPicker.remaining())}
      </div>
    );
  }
}

export default ClientForm;
