import * as React from 'react';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import ImageIcon from '@material-ui/icons/Image';

interface Props {
  height: number
}

type LocalStyles = 'image' | 'placeholder';

type PropsWithStyle = Props & WithStyles<LocalStyles>;

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = theme => ({
  image: {
    background: theme.imagePlaceholderBackground,
  },
  placeholder: {
    display: 'block',
    color: '#fff',
    fontSize: 60,
    margin: 'auto',
    height: '100%',
    verticalAlign: 'middle'
  },
});

// If we don't set a src or image tag in CardMedia, Material-UI logs an error
const TRANSPARENT_1x1_PNG = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

class PlaceholderCardMedia extends React.Component<PropsWithStyle> {
  render() {
    const {classes, height} = this.props;
    return (
      <CardMedia
        component="div"
        className={classes.image}
        style={{height}}
        src={TRANSPARENT_1x1_PNG}
      >
        <ImageIcon className={this.props.classes.placeholder}/>
      </CardMedia>
    );
  }
}

export default withStyles(localStyles)(PlaceholderCardMedia);
