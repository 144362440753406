import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import * as callApi from "../../../api/callApi";
import {routes} from "../../../router/MyRouter";
import {declineInvite} from "../../../api/inviteApi";
import {checkIfCameraAndAudioIsAvailable} from "../../../util/webrtc/webrtcUtil";
import NoCameraWarning from "../../../components/NoCameraWarning";
import UserNotReachableDialog from "../../../components/dialogs/UserNotReachableDialog";
import ImageInput from "../../../components/ImageInput";
import {scanItemCode, uploadItemImage} from "../../../api/itemApi";
import {MalformedImageDialog} from "../../../components/dialogs/MalformedImageDialog";
import {ImageTooLargeDialog, MAX_IMAGE_FILE_SIZE} from "../../../components/dialogs/ImageTooLargeDialog";
import EmbeddedPage from "../../../components/EmbeddedPage";
import createStyles from "@material-ui/core/styles/createStyles";
import {RouteComponentProps, withRouter} from "react-router";
import {AppBarTopPadding} from "../../../components/AppBarTopPadding";
import {LandingPageAppBar} from "../LandingPageAppBar";
import {InfoCard} from "../../../components/InfoCard";
import DoneIcon from '@material-ui/icons/Done';
import {Item} from "../../../api/itemTypes";
import Spinner from "../../../spinner/Spinner";

interface Props extends WithStyles<typeof localStyles>, RouteComponentProps {
  itemCode: string;
}

const localStyles = (theme: Theme) => createStyles({
  img: {
    height: '100%',
    marginBottom: -4,
    maxHeight: 110 * 3 + 16 * 2, // The height of the 3 items + the spacing between them
    maxWidth: 110 * 3 + 16 * 2,
    borderRadius: 4
  },
  button: {
    width: '100%',
    marginTop: 8,
    height: 44
  },
});

interface State {
  item?: Item,
  noCameraWarningDialogOpen: boolean
  userNotReachableDialogOpen: boolean
  malformedImageDialogOpen: boolean
  imageTooLargeDialogOpen: boolean
}

class AddItemPage extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      noCameraWarningDialogOpen: false,
      userNotReachableDialogOpen: false,
      malformedImageDialogOpen: false,
      imageTooLargeDialogOpen: false
    }
  }

  async componentDidMount() {
    try {
      const item = await scanItemCode(this.props.itemCode);
      this.setState({item});
    } catch (error) {
      console.log(error);
      this.props.history.push(routes.scanItem);
    }
  }

  render() {
    return (
      <EmbeddedPage>
        <LandingPageAppBar
          title="Add Item"
          customRightIcon={<DoneIcon color="inherit"/>}
          onCustomRightButtonClick={() => this.props.history.push('/')}
        />
        <AppBarTopPadding>
          <div style={{padding: 16}}>

            {this.state.item && (
              <>
                <Grid container={true} spacing={2}>
                  {this.getItemInformation()}
                  {this.getImage()}
                </Grid>
                {this.getSupportButton()}
              </>
            )}

            {!this.state.item && <Spinner/>}

          </div>
        </AppBarTopPadding>
        {this.getDialogs()}
      </EmbeddedPage>
    );
  }

  private getSupportButton = () => {
    const {classes} = this.props;
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} sm={7} md={8}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={this.handleGetSupport}
          >
            Get Support
          </Button>
        </Grid>
      </Grid>
    );
  };

  private getImage = () => {
    const {classes} = this.props;
    const {item} = this.state;
    return (
      <Grid item={true} xs={12} sm={5} md={4}>
        <Typography variant="h5" gutterBottom={true}>
          Add a photo of your item
        </Typography>

        <ImageInput
          onUpload={async file => {
            if (file.size > MAX_IMAGE_FILE_SIZE) {
              this.setState({imageTooLargeDialogOpen: true});
              return;
            }
            try {
              this.setState({item: await uploadItemImage(item!!.id, file)});
            } catch (error) {
              console.log(error);
              this.setState({malformedImageDialogOpen: true});
            }
          }}
          allowDelete={false}
          onDelete={() => null}
          imageHref={item!!._links.image}
          className={classes.img}
          width="100%"
          height="100%"
          style={{height: 99999}}
        />
      </Grid>
    );
  };

  private getItemInformation = () => {
    const {item} = this.state;
    return (
      <Grid item={true} xs={12} sm={7} md={8}>
        <Typography variant="h5" gutterBottom={true}>
          Item Information
        </Typography>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <InfoCard title="Item Id" subtitle={item!!.itemCode} imageUrl="/icons/QRCode.svg"/>
          </Grid>
          <Grid item={true} xs={12}>
            <InfoCard title="Manufacturer" subtitle={item!!.client.name} imageUrl="/icons/manufacturer.svg"/>
          </Grid>
          <Grid item={true} xs={12}>
            <InfoCard title="Item Name" subtitle={item!!.name} imageUrl="/icons/item.svg"/>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  private getDialogs = () => {
    return (
      <>
        <MalformedImageDialog
          open={this.state.malformedImageDialogOpen}
          onClose={() => this.setState({malformedImageDialogOpen: false})}
        />
        <ImageTooLargeDialog
          open={this.state.imageTooLargeDialogOpen}
          onClose={() => this.setState({imageTooLargeDialogOpen: false})}
        />
        <NoCameraWarning
          open={this.state.noCameraWarningDialogOpen}
          handleDialogClose={this.handleDialogClose}
        />
        <UserNotReachableDialog
          open={this.state.userNotReachableDialogOpen}
          onClose={this.handleDialogClose}
        />
      </>
    );
  };

  private handleGetSupport = async () => {
    const cameraAvailable = await checkIfCameraAndAudioIsAvailable();
    if (cameraAvailable) {
      try {
        const call = await callApi.createCall({itemId: this.state.item!!.id});
        setTimeout(() => {
          // Decline the invite after 60 seconds. If the user accepted it in the meanwhile we get a 400,
          // but this is no problem and we can just ignore it :-)
          declineInvite(call.invites[0].id).catch(() => null);
        }, 60 * 1000);
        this.props.history.push(routes.call(call.id));
      } catch (error) {
        if (error.response.status === /* Sorry, I cannot brew coffee, because */ 418 /* I'm a teapot */) {
          this.setState({userNotReachableDialogOpen: true});
          return;
        }
      }
    } else {
      this.setState({noCameraWarningDialogOpen: true});
    }
  };

  private handleDialogClose = () => {
    this.setState({noCameraWarningDialogOpen: false, userNotReachableDialogOpen: false});
  };

}

export default withStyles(localStyles)(withRouter(AddItemPage))
