import {Item, ItemRequest} from './itemTypes';
import {myAxios} from '../util/myAxios';
import {Page, Pageable, toUrlParams} from '../query/pageable';
import {isValidationErrorResponse, ValidationErrors} from './restApi';

export async function loadItem(itemId: number): Promise<Item> {
  return myAxios()
    .then(axios => axios.get(`/api/items/${itemId}`))
    .then(response => response.data);
}

export async function loadItems(
  params: { supportAgentId?: number; searchText?: string },
  pageable: Pageable
): Promise<Page<Item>> {
  // Sort by name per default
  const mergedPageable: Pageable = {
    sort: {
      field: 'name',
    },
    ...pageable,
  };
  const supportAgentParam = params.supportAgentId === undefined ? '' : `support_agent_id=${params.supportAgentId}&`;
  const searchParam = params.searchText === undefined ? '' : `search=${encodeURIComponent(params.searchText)}&`;
  return myAxios()
    .then(axios => axios.get(`/api/items/?${supportAgentParam}${searchParam}${toUrlParams(mergedPageable)}`))
    .then(response => response.data);
}

export async function createItem(item: ItemRequest): Promise<Item | ValidationErrors> {
  return myAxios()
    .then(axios => axios.post(`/api/items`, item))
    .then(
      response => response.data,
      error => {
        const response = error.response;
        if (isValidationErrorResponse(response)) {
          return response.data;
        } else {
          throw error;
        }
      });
}

export async function updateItem(itemId: number, item: ItemRequest): Promise<Item | ValidationErrors> {
  return myAxios()
    .then(axios => axios.put(`/api/items/${itemId}`, item))
    .then(
      response => response.data,
      error => {
        const response = error.response;
        if (isValidationErrorResponse(response)) {
          return response.data;
        } else {
          throw error;
        }
      });
}

export async function uploadItemImage(itemId: number, file: File): Promise<Item> {
  const axios = await myAxios();
  const form = new FormData();
  form.append('file', file, file.name || '');
  const response = await axios.post(`/api/items/${itemId}/image`, form);
  return response.data;
}

export async function deleteItem(itemId: number): Promise<{}> {
  return myAxios()
    .then(axios => axios.delete(`/api/items/${itemId}`));
}

export async function scanItemCode(itemCode: string): Promise<Item> {
  const axios = await myAxios();
  const response = await axios.post(`/api/items/scan`, itemCode, {
    headers: {
      'Content-Type': 'text/plain',
    }
  });
  return response.data;
}
