import * as React from "react";
import {ReactNode, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme: Theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(),
  }
}));

export function useSaveButtonState<T>(initialValue: T): {
  icon: ReactNode,
  disabled: boolean,
  allowEdits: boolean,
  onChange(newValue: T): void,
  onSave(): void,
  onSaveFinished(): void
} {
  const [savedValue, setSavedValue] = useState(initialValue);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [saving, setSaving] = useState(false);
  const [changedSinceLastSaving, setChangedSinceLastSaving] = useState(true);
  const classes = useStyles();

  const saveIcon = <SaveIcon className={classes.buttonIcon}/>;
  const loadingIcon = <CircularProgress size={24} className={classes.buttonIcon}/>;
  const doneIcon = <DoneIcon className={classes.buttonIcon}/>;

  let icon = saveIcon;
  if (saving) {
    icon = loadingIcon;
  } else if (!changedSinceLastSaving) {
    icon = doneIcon;
  }

  return {
    icon,
    disabled: savedValue === currentValue,
    allowEdits: !saving,
    onChange: newValue => {
      setChangedSinceLastSaving(true);
      setCurrentValue(newValue);
    },
    onSave: () => {
      setSaving(true);
    },
    onSaveFinished: () => {
      setSaving(false);
      setChangedSinceLastSaving(false);
      setSavedValue(currentValue);
    }
  };
}
