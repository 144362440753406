import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Call, getColorOfUser} from '../../../api/callTypes';
import {AccountDto, User} from "../../../api/userTypes";
import {CallStateContainer} from "./state/CallStateContainer";
import {LocalStreamConsumerProps} from "./LocalStreamSupplier";
import CallHeader from "./CallHeader";
import VideoContainer from "./VideoContainer";
import ContactsListDialog from "../contacts/ContactsListDialog";
import {NoMoreUsersDialog} from "./NoMoreUsersDialog";
import {inviteUser, uploadScreenshot} from "../../../api/callApi";
import UserNotReachableDialog from "../../../components/dialogs/UserNotReachableDialog";
import {declineInvite} from "../../../api/inviteApi";
import {isEndUser} from "../../../permissions";
import {ChatWindow} from "./chat/ChatWindow";
import {ChatMessage} from "../../../api/chatTypes";
import {DrawingControls} from "./DrawingControls";
import {VideoControls} from "./VideoControls";
import {makeStyles, ThemeProvider} from "@material-ui/styles";
import {useAndroidShown} from "../../../util/hooks/useAndroidShown";
import {defaultTheme} from "../../../styles/muiTheme";
import {Typography} from "@material-ui/core";

type Props = LocalStreamConsumerProps & {
  account: AccountDto;
  remoteUsername: string,
  ownSpreedId?: string,
  connectedUsers: Array<{ userId: number, spreedId: string, stream?: MediaStream }>
  call: Call,
  callStateContainer: CallStateContainer,
  onLeaveCall(): void,
  newMessageHandlerRef: (onNewChatMessage: (message: ChatMessage) => void) => void,
  takeScreenshotHandlerRef?(ref: (() => string) | null): void,
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    backgroundImage: 'url("/images/call-page-background.png")'
  },
}));

export function CallView(props: Props) {
  const classes = useStyles();
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [noMoreUsersDialogOpen, setNoMoreUsersDialogOpen] = useState(false);
  const [userNotReachableDialogOpen, setUserNotReachableDialogOpen] = useState(false);
  const [chatWindowOpen, setChatWindowOpen] = useState(false);
  const [drawingColor, setDrawingColor] = useState(getColorOfUser(props.call, props.account.id) || '#ffffff');
  const [screenshotUploadProgress, setScreenshotUploadProgress] = useState(0);
  const takeScreenshotOfDrawingRef = useRef<null | (() => Promise<File | null>)>(null);
  const [videoEnabled, setVideoEnabled] = useState(props.videoEnabled);
  const androidShown = useAndroidShown();

  useEffect(() => {
    // Set the pages theme-color to a dark color during a call, this may affect toolbar colors, etc.
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    if (metaThemeColor) {
      const oldColor = metaThemeColor.getAttribute('content');
      if (oldColor) {
        metaThemeColor.setAttribute('content', "#252525");
        return () => {
          const metaThemeColor = document.querySelector('meta[name=theme-color]');
          if (metaThemeColor) {
            metaThemeColor.setAttribute('content', oldColor);
          }
        }
      }
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (!chatWindowOpen) {
      props.newMessageHandlerRef(() => {
        setChatWindowOpen(true)
      });
    }
  }, [chatWindowOpen]);

  useEffect(() => {
    const intendedVideoEnabled = videoEnabled && androidShown;
    if (intendedVideoEnabled !== props.videoEnabled) {
      props.setVideoEnabled(intendedVideoEnabled);
      callStateContainer.setStreamStatus(intendedVideoEnabled, audioEnabled);
    }
  }, [videoEnabled, androidShown]);

  const handleAddUserClicked = (callStateContainer: CallStateContainer) => {
    const totalUserCount = props.connectedUsers.length + callStateContainer.state.pendingInvites.size;
    if (totalUserCount >= 4) {
      setNoMoreUsersDialogOpen(true);
      return;
    }
    setAddUserDialogOpen(true);
  };

  const handleUserSelect = async (user: User, callStateContainer: CallStateContainer) => {
    const totalUserCount = props.connectedUsers.length + callStateContainer.state.pendingInvites.size;
    if (totalUserCount >= 4) {
      setAddUserDialogOpen(false);
      setNoMoreUsersDialogOpen(true);
      return;
    }
    setAddUserDialogOpen(false);

    try {
      const invite = await inviteUser(props.call.id, user.id);
      setTimeout(() => {
        // Decline the invite after 60 seconds. If the user accepted it in the meanwhile we get a 400,
        // but this is no problem and we can just ignore it :-)
        declineInvite(invite.id).catch(() => null);
      }, 60 * 1000);
      callStateContainer.addToPendingInvites(invite);
    } catch (error) {
      if (error.response.status === 418) {
        setUserNotReachableDialogOpen(true);
      }
    }
  };

  const {
    connectedUsers, ownSpreedId, remoteUsername, devices, audioEnabled, newMessageHandlerRef,
    switchToNextVideoTrack, setAudioEnabled, account, streamIsFrontFacing, call, takeScreenshotHandlerRef,
    callStateContainer,
  } = props;
  const largeUserStreamStatus = callStateContainer.state.streamStatus.get(callStateContainer.state.largeScreen.userSpreedId);
  return (
    <>
      <CallHeader remoteUsername={remoteUsername} userCount={connectedUsers.length}/>
      <VideoContainer
        connectedUsers={connectedUsers}
        ownSpreedId={ownSpreedId}
        ownStreamIsFrontFacing={streamIsFrontFacing}
        takeScreenshotHandlerRef={takeScreenshotHandlerRef}
        drawingColor={drawingColor}
        takeDrawingScreenshotRef={ref => takeScreenshotOfDrawingRef.current = ref}
      />
      {!callStateContainer.state.drawing.active && (
        <VideoControls
          videoSteamsSize={devices.length}
          videoEnabled={videoEnabled}
          audioEnabled={audioEnabled}
          screenShareEnabled={props.screenShareEnabled}
          showAddUserButton={props.call.invites[0].status == 'ACCEPTED' || props.connectedUsers.length >= 2 && !isEndUser(account)}
          onSwitchVideoEnabled={() => {
            setVideoEnabled(!videoEnabled);
          }}
          onSwitchScreenShareEnabled={() => {
            props.setShareScreenEnabled(!props.screenShareEnabled);
          }}
          onSwitchAudioEnabled={() => {
            setAudioEnabled(!audioEnabled);
            callStateContainer.setStreamStatus(videoEnabled, !audioEnabled);
          }}
          onSwitchToNextVideoTrack={() => switchToNextVideoTrack()}
          onLeaveCall={props.onLeaveCall}
          onAddUser={() => handleAddUserClicked(callStateContainer)}
          chatIsOpen={chatWindowOpen}
          onChatOpen={() => setChatWindowOpen(true)}
          drawingIsPossible={!largeUserStreamStatus || largeUserStreamStatus.videoEnabled}
          onStartDrawing={() => callStateContainer.takeScreenshot()}
        />
      )}

      {callStateContainer.state.drawing.active && (
        <DrawingControls
          onCancel={() => callStateContainer.stopDrawing()}
          onDelete={() => callStateContainer.deleteAllLines()}
          showSaveButton={!!call.supportCaseId}
          onScreenshotSave={async () => {
            if (takeScreenshotOfDrawingRef.current) {
              const file = await takeScreenshotOfDrawingRef.current();
              if (file) {
                await uploadScreenshot(props.call.id, file, (progress) => {
                  setScreenshotUploadProgress(progress);
                });
                callStateContainer.broadcastScreenshotSavedEvent();
                setScreenshotUploadProgress(0);
              }
            }
          }}
          screenshotUploadProgress={screenshotUploadProgress}
          drawingColor={drawingColor}
          onColorSelect={setDrawingColor}
        />
      )}
      <ContactsListDialog
        onClose={() => setAddUserDialogOpen(false)}
        open={addUserDialogOpen}
        account={account}
        onClick={user => handleUserSelect(user, callStateContainer)}
        userIdsToHide={[
          ...connectedUsers.map(user => user.userId),
          ...Array.from(callStateContainer.state.pendingInvites.keys())
        ]}
      />
      <div className={classes.root}/>
      <ThemeProvider theme={defaultTheme}>
        {/* This seems to be a bug that nested themes don't set the text colors correctly*/}
        <Typography component="div" color="textPrimary">
          {chatWindowOpen && <ChatWindow callId={call.id} newMessageHandlerRef={newMessageHandlerRef}
                                         onClose={() => setChatWindowOpen(false)}/>}
        </Typography>
      </ThemeProvider>
      <NoMoreUsersDialog
        open={noMoreUsersDialogOpen}
        onClose={() => setNoMoreUsersDialogOpen(false)}
      />
      <UserNotReachableDialog
        open={userNotReachableDialogOpen}
        onClose={() => setUserNotReachableDialogOpen(false)}
      />
    </>
  );
}
