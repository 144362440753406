import * as React from 'react';
import {ReactNode} from 'react';
import {CONTAINER_MAX_WIDTH} from "../../styles/muiTheme";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Typography from "@material-ui/core/Typography/Typography";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import {AppBarMenu} from "../AppBarMenu";
import {makeStyles} from "@material-ui/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {IconButton} from "@material-ui/core";
import {useRouter} from "../../util/hooks/useRouterHook";

const useStyles = makeStyles({
  root: {
    maxWidth: CONTAINER_MAX_WIDTH,
    right: 'auto',
  },
  headline: {
    flexGrow: 1
  },
});

export function LandingPageAppBar(props: {
  title: ReactNode,
  previousLocation?: string,
  customRightIcon?: ReactNode,
  onCustomRightButtonClick?(): void
}) {
  const classes = useStyles();
  const {history} = useRouter();
  return (
    <AppBar className={classes.root}>
      <Toolbar>
        {props.previousLocation && (
          <IconButton color="inherit" style={{marginRight: 8}} onClick={() => history.push(props.previousLocation!!)}>
            <ArrowBackIcon color="inherit"/>
          </IconButton>
        )}
        <Typography variant="h6" className={classes.headline} color="inherit" noWrap={true}>
          {props.title}
        </Typography>
        <AppBarMenu customRightIcon={props.customRightIcon} onCustomRightButtonClick={props.onCustomRightButtonClick}/>
      </Toolbar>
    </AppBar>
  );
}
