/**
 * Load the promise module of core-js
 * Which provides cross-browser support of `onunhandledrejection`.
 * See also https://github.com/zloirock/core-js#unhandled-rejection-tracking
 * Currently (July 2017) only Chrome supports it out-of-the-box:
 * See also https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onunhandledrejection
 */
require('core-js/fn/promise');

export function addOnErrorListener(listener: OnErrorEventHandlerNonNull) {
  const oldHandler = window.onerror;
  window.onerror = function (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
    listener(event, source, lineno, colno, error);
    if (oldHandler) {
      oldHandler.call(this, event, source, lineno, colno, error);
    }
  };
}

export function addUnhandledRejectionListener(listener: (e: PromiseRejectionEvent) => void) {
  const oldRejectionHandler: ((this: WindowEventHandlers, ev: PromiseRejectionEvent) => any) | null = window.onunhandledrejection;
  window.onunhandledrejection = function (this: WindowEventHandlers, e: PromiseRejectionEvent) {
    listener(e);
    if (oldRejectionHandler) {
      oldRejectionHandler.call(this, e);
    }
  };
}
