import {closeStream} from "./MediaStreamUtils";

/**
 * Checks if the user allowed us to use the camera and microphone.
 */
export async function checkIfCameraAndAudioIsAvailable(): Promise<boolean> {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
    closeStream(stream);
    return true;
  } catch (e) {
    console.warn("cam or mic unavailable", e);
    return false;
  }
}
