import * as React from 'react';
import {observer} from 'mobx-react';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import AppBar from '@material-ui/core/AppBar/AppBar';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {AccountDto} from '../../api/userTypes';
import Input from '@material-ui/core/Input/Input';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Paper from '@material-ui/core/Paper/Paper';
import {PLACEHOLDER_GREY} from '../../styles/muiTheme';
import {debounce} from 'lodash';
import {AppBarMenu} from "../AppBarMenu";

interface Props {
  logo: React.ComponentType;
  rightLogo?: React.ComponentType;
  account: AccountDto;
  onSearch?: (search: string) => void;
}

interface State {
}

type LocalStyles = 'root' | 'flex' | 'searchInput';

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = theme => ({
  root: {},
  flex: {
    flex: 1,
  },
  searchInput: {
    width: '20em',
  }
});

type PropsWithStyle = Props & WithStyles<LocalStyles>;

@observer
export class MyAppBar extends React.Component<PropsWithStyle, State> {

  search = debounce(
    (text: string) => {
      if (this.props.onSearch) {
        this.props.onSearch(text);
      }
    },
    500);

  constructor(props: PropsWithStyle) {
    super(props);
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <AppBar id="appbar" position="fixed">
          <Toolbar>
            <this.props.logo/>
            <div
              className={this.props.classes.flex}
            />
            <Paper
              style={{
                padding: '0 8px 0 16px',
                marginRight: 8,
                borderRadius: 16,
              }}
            >
              <Input
                id="input_search"
                className={this.props.classes.searchInput}
                placeholder="Search"
                type="search"
                disableUnderline={true}
                onChange={e => this.search(e.target.value)}
                endAdornment={<InputAdornment position="end">
                  <SearchIcon
                    style={{
                      color: PLACEHOLDER_GREY,
                    }}
                  />
                </InputAdornment>}
              />
            </Paper>
            <AppBarMenu/>
            {this.props.rightLogo && <this.props.rightLogo/>}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(localStyles)(MyAppBar);
