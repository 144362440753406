/* tslint:disable */
/**
 * Taken from
 * https://github.com/mui-org/material-ui/blob/57e8a88faeebba4d67aaaf6c5b178ebfe97264a6/packages/material-ui-docs/src/svgIcons/FileDownload.js
 *
 * Because the FileDownload icon was removed from default Google Material icons...
 */

import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function FileDownload(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
        </SvgIcon>
    );
}

// @ts-ignore
FileDownload.muiName = 'SvgIcon';

export default FileDownload;
