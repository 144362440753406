import * as React from "react";
import {useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
  canvas: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  }
});

/**
 * A canvas that uses the size of the given video or image element.
 */
export function FittingCanvas(props: {
  videoOrImage(): HTMLVideoElement | HTMLImageElement | null,
  canvasRef?(ref: HTMLCanvasElement | null): void,
  onCanvasResize(): void,
  mirrored: boolean
}) {
  const classes = useStyles();
  const canvasRef = useRef(null as HTMLCanvasElement | null);

  useEffect(() => {
    adjustCanvasSize(canvasRef.current, props.videoOrImage());
  });

  // We need to update the canvas size whenever its size changes.
  // However, there is no simple event for that.
  // `window.onresize` works in many cases.
  useEffect(() => {
    const eventHandler = () => adjustCanvasSizeAndCallCallback(canvasRef.current, props.videoOrImage(), props.onCanvasResize);
    window.addEventListener('resize', eventHandler);
    return () => window.removeEventListener('resize', eventHandler);
  });
  // However, I don't know how to catch the video starting or the resolution changing
  // (the canvas depends on the aspect ratio of the video, so that's important).
  // So, we use both `window.onresize` and simple `window.setInterval` polling.
  useEffect(() => {
    const interval = window.setInterval(() => adjustCanvasSizeAndCallCallback(canvasRef.current, props.videoOrImage(), props.onCanvasResize), 50);
    return () => clearInterval(interval);
  }, [canvasRef.current, props.onCanvasResize, props.videoOrImage]);

  return (
    <div>
      <canvas
        style={props.mirrored ? {transform: 'rotateY(180deg)'} : undefined}
        className={classes.canvas}
        ref={ref => {
          canvasRef.current = ref;
          if (props.canvasRef) {
            props.canvasRef(ref);
          }
        }}
      />
    </div>
  );
}

/**
 * Calls #adjustCanvasSize and calls #onCanvasResize if the canvas size changed.
 */
function adjustCanvasSizeAndCallCallback(
  canvas: HTMLCanvasElement | null,
  ref: HTMLVideoElement | HTMLImageElement | null,
  onCanvasResize: () => void
) {
  if (adjustCanvasSize(canvas, ref)) {
    onCanvasResize();
  }
}

/**
 * Adjusts the size of the given canvas to the size of the given ref.
 */
function adjustCanvasSize(
  canvas: HTMLCanvasElement | null,
  ref: HTMLVideoElement | HTMLImageElement | null
) {
  if (!canvas || !ref) {
    return false;
  }

  const refWidth = (ref as HTMLVideoElement).videoWidth || (ref as HTMLImageElement).naturalWidth;
  const refHeight = (ref as HTMLVideoElement).videoHeight || (ref as HTMLImageElement).naturalHeight;

  // Scale the canvas as big as possible while keeping the aspect ratio.
  const scaleX = ref.clientWidth / refWidth;
  const scaleY = ref.clientHeight / refHeight;
  const scale = Math.min(scaleX, scaleY);

  const newWidth = Math.ceil(refWidth * scale);
  const newHeight = Math.ceil(refHeight * scale);

  if (newWidth === canvas.width && newHeight === canvas.height) {
    return false;
  }

  canvas.width = newWidth;
  canvas.height = newHeight;
  return true;

}
