import * as React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';

export interface Props {
  text: string;
  open: boolean;
  onOk: React.ReactEventHandler<{}>;
  onDismiss: React.ReactEventHandler<{}>;
}

export class ConfirmDeleteDialog extends React.Component<Props> {

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onDismiss}
      >
        <DialogContent>
          <DialogContentText>
            {this.props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="button_cancel_delete"
            color="default"
            variant="contained"
            onClick={this.props.onDismiss}
          >
            Cancel
          </Button>
          <Button
            id="button_confirm_delete"
            color="primary"
            variant="contained"
            onClick={this.props.onOk}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
