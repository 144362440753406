import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import {Fab, WithStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import AppBar from '@material-ui/core/AppBar/AppBar';
import {fullPageable} from '../../../query/pageable';
import * as supportAgentApi from '../../../api/supportAgentApi';
import {IObservableObject, IObservableValue, observable, observe} from 'mobx';
import {User} from '../../../api/userTypes';
import {observer} from 'mobx-react';
import EditSupportAgentDialog from './EditSupportAgentDialog';
import {History} from 'history';
import {DashboardStyles} from '../dashboard/DashboardPage';
import NewSupportAgentDialog from './NewSupportAgentDialog';
import {SupportAgentIcon} from '../../../styles/icons';
import {cardAnimationProps, withTransition} from '../../../components/transitions';
import {TransitionGroup} from 'react-transition-group';
import {SupportAgentCard} from './SupportAgentCard';
import {AppBarTopPadding} from "../../../components/AppBarTopPadding";

interface Props {
  history: History;
  searchText: IObservableValue<string>;
  supportAgentUpdateCounter: IObservableValue<number>;
}

type PropsWithStyle = Props & WithStyles<DashboardStyles>;

@observer
export class SupportAgentDashboardColumn extends React.Component<PropsWithStyle> {

  @observable users: User[] = [];
  @observable editDialogOpen: IObservableValue<boolean> = observable.box(false);
  @observable newDialogOpen: IObservableValue<boolean> = observable.box(false);
  @observable user: User & IObservableObject | undefined = undefined;

  private closeActions: (Array<() => void>) = [];

  componentDidMount() {
    this.closeActions.push(
      observe(
        this.props.searchText,
        () => this.refreshItems(),
        true));
  }

  componentWillUnmount() {
    this.closeActions.forEach(f => f());
  }

  refreshItems(): Promise<void> {
    return supportAgentApi.getAll({searchText: this.props.searchText.get()}, fullPageable()).then(page => {
      this.users = page.content;
    });
  }

  render() {
    return (
      <div className={this.props.classes.fabContainer}>
        {this.user &&
        <EditSupportAgentDialog
          open={this.editDialogOpen.get()}
          user={this.user}
          onClose={() => {
            this.editDialogOpen.set(false);
            this.refreshItems();
            this.props.supportAgentUpdateCounter.set(this.props.supportAgentUpdateCounter.get() + 1);
          }}
        />
        }
        <NewSupportAgentDialog
          open={this.newDialogOpen.get()}
          onClose={() => {
            this.newDialogOpen.set(false);
            this.refreshItems();
          }}
        />
        <AppBar
          position="absolute"
          color="default"
        >
          <Toolbar>
            <Typography variant="h6">
              <SupportAgentIcon className={this.props.classes.toolbarIcon}/>
              Support Agents
            </Typography>
          </Toolbar>
          <Fab
            id="button_add_supportAgent"
            className={this.props.classes.fabButton}
            color="secondary"
            onClick={() => this.newDialogOpen.set(true)}
          >
            <AddIcon color="action"/>
          </Fab>
        </AppBar>
        <AppBarTopPadding style={{height: '100%'}}>
          <div className={this.props.classes.scrollContainer}>
            <TransitionGroup>
              {this.users.map(user =>
                withTransition({
                  key: user.id,
                  ...cardAnimationProps(190),
                  children: (style, transitionState) =>
                    <SupportAgentCard
                      style={style}
                      transitionState={transitionState}
                      user={user}
                      onClick={() => {
                        this.user = observable({...user});
                        this.editDialogOpen.set(true);
                      }}
                      classes={this.props.classes}
                    />
                })
              )}
            </TransitionGroup>
          </div>
        </AppBarTopPadding>
      </div>
    );
  }
}
