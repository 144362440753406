// Polyfills for IE11
import 'core-js/fn/object/assign';
import 'core-js/fn/number/parse-int';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'typeface-roboto';
import {initUncaughtRejectionListener} from './router/uncaughtErrorListener';
import {initSentry} from './util/sentry';
import {ErrorBoundary} from './router/ErrorBoundary';
import log from './util/myLog';
import {SnackbarProvider} from "notistack";
import {MyRouter} from "./router/MyRouter";
import {checkIfLoggedIn} from "./keycloak";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import {defaultTheme} from "./styles/muiTheme";
import {ServiceWorkerMessageHandler} from "./components/ServiceWorkerMessageHandler";
import {AccountContext, AccountContextProvider} from "./components/AccountContext";
import {ShowNotifcationOnIncomingCallHandler} from "./components/ShowNotifcationOnIncomingCallHandler";
import {WebpushContextProvider} from "./components/WebpushContext";
import {PRODUCT_NAME} from "./branding/general";
import {polyfillTextEncoder} from "./polyfills";
import * as webappUpdateChecker from './util/webappUpdateChecker';
import * as androidApi from './androidApi';
import * as notificationTokenApi from "./api/notificationTokenApi";
import {ServiceWorkerContextProvider} from "./components/ServiceWorkerContext";
import {ThemeProvider} from '@material-ui/styles';
import {AndroidNotificationHandler} from "./components/AndroidNotificationHandler";

require('webrtc-adapter');

polyfillTextEncoder();

registerPolyfillIE11StartsWith();

log.info(`${PRODUCT_NAME}`
  + ` GIT_TAG: ${process.env.REACT_APP_GIT_TAG},`
  + ` GIT_COMMIT: ${process.env.REACT_APP_GIT_COMMIT}`);

initSentry();
initUncaughtRejectionListener();

checkIfLoggedIn().then(initApp);

async function initAndroid() {
  const android = androidApi.getAndroidApi();
  if (android) {
    await notificationTokenApi.registerFirebase(android.getPushToken());
    await webappUpdateChecker.init();
    androidApi.onStartHandler.on(async () => {
      if (await webappUpdateChecker.needsUpdate()) {
        window.location.reload(true);
      }
    });
  }
}

async function initApp() {
  await initAndroid();
  ReactDOM.render(
    <ErrorBoundary>
      <AccountContextProvider>
        <AndroidNotificationHandler/>
        <ServiceWorkerContextProvider>
          <WebpushContextProvider>
            <ServiceWorkerMessageHandler/>
            <ShowNotifcationOnIncomingCallHandler/>
            <ThemeProvider theme={defaultTheme}>
              <CssBaseline>
                <SnackbarProvider maxSnack={100}>
                  <AccountContext.Consumer>
                    {account => <MyRouter account={account!!}/>}
                  </AccountContext.Consumer>
                </SnackbarProvider>
              </CssBaseline>
            </ThemeProvider>
          </WebpushContextProvider>
        </ServiceWorkerContextProvider>
      </AccountContextProvider>
    </ErrorBoundary>,
    document.getElementById('root') as HTMLElement
  );
}

/**
 * Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
 */
function registerPolyfillIE11StartsWith() {
  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (search: string, pos?: number) {
      return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
    };
  }
}
