import * as React from "react";
import {useEffect, useState} from "react";
import {CheckItem, CheckResult} from "./CheckItem";
import {Button, CircularProgress} from "@material-ui/core";
import {closeStream} from "../../../util/webrtc/MediaStreamUtils";
import {RunCheckFunc} from "./CheckPage";

export function CameraCheck(props: {
  setRunCheckFunc: (f: RunCheckFunc) => void,
}) {
  const [pending, setPending] = useState(false);
  const [camOk, setCamOk] = useState<null | true | string>(null);
  const runCheck: RunCheckFunc = async () => {
    setPending(true);
    setCamOk(null);
    const result = await checkCamera();
    setCamOk(result === true ? true : result.toString());
    setPending(false);
  }
  useEffect(() => {
    props.setRunCheckFunc(runCheck);
  }, []);
  return (<CheckItem
      title="Camera"
    >
      <Button
        variant="outlined"
        onClick={runCheck}
      >
        Check
      </Button>
      {pending && <CircularProgress/>}
      <CheckResult result={camOk}/>
    </CheckItem>
  );
}

async function checkCamera(): Promise<true | Error> {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({video: true});
    closeStream(stream);
    return true;
  } catch (e) {
    return e;
  }
}
