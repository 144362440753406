import * as React from 'react';

/**
 * Renders the given text with <br/> for every linebreak (\n).
 */
export function MultilineText(props: {
  text?: string
}) {
  if (!props.text) {
    return null;
  }

  const lines = props.text.split('\n');

  return (
    <React.Fragment>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < lines.length - 1 ? <br/> : undefined}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
