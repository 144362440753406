import {ShowLargeScreenEvent} from "../../../../events/ShowLargeScreenEvent";
import {WelcomeEvent} from "../../../../events/WelcomeEvent";
import {TakeScreenshotEvent} from "../../../../events/TakeScreenshotEvent";
import {StartDrawingEvent} from "../../../../events/StartDrawingEvent";
import {StopDrawingEvent} from "../../../../events/StopDrawingEvent";
import {DrawLineEvent} from "../../../../events/DrawLineEvent";
import {SetLinesVisibilityEvent} from "../../../../events/SetLinesVisibilityEvent";
import {ShowMarkerEvent} from "../../../../events/ShowMarkerEvent";
import {StreamStatusUpdateEvent} from "../../../../events/StreamStatusUpdateEvent";
import {ChatMessageEvent} from "../../../../events/ChatMessageEvent";
import {InvitedUserEvent} from "../../../../events/InvitedUserEvent";
import Immutable from 'immutable';
import {RemovePendingInvite, RemovePendingInviteReason} from "../../../../events/RemovePendingInvite";
import {HelloEvent} from "../../../../events/HelloEvent";
import {Container} from "unstated";
import {BaseEvent} from "../../../../events/BaseEvent";
import {SpreedRoom} from "../../../../util/webrtc/SpreedRoom";
import {BaseNotification} from "../../../../notifications/BaseNotification";
import {InviteeDeclinedNotification} from "../../../../notifications/InviteeDeclinedNotification";
import {Invite} from "../../../../api/inviteTypes";
import {ChatMessage} from "../../../../api/chatTypes";
import {ScreenshotSavedEvent} from "../../../../events/ScreenshotSavedEvent";
import {DrawingAndMarkerStateContainer} from "./DrawingAndMarkerStateContainer";

/*
 * Some important notes about this class:
 *
 * - The structure of CallState is a little bit different from the WELCOME payload.
 *   * It uses some more efficient datastructures (e.g. an immutable map instead of an array) for quick access
 *   * Time-related fields use a timestamp instead of a duration in ms
 *   * Some fields were added/removed that are not part of the WELCOME payload
 * - Never forget, that react state should be immutable. That's why values are never mutated directly, but instead
 *   use the spread-operator (the three points "...") and immutable maps from Facebook's immutable.js library
 * - setState() is async in React and unstated, that's why you must use "prevState" instead of "this.state"
 * - The lines for drawing and the markers are stored in the DrawingAndMarkerStateContainer for performance reasons
 *   Otherwise, other components that are not interested in the marker and drawing state get state updated (and thus
 *   re-renders) nevertheless which costs a lot of performance for stuff that updates that frequently.
 *
 * Take a look at the wiki article about RemoteXpert's protocol:
 * https://kurzdigital.atlassian.net/wiki/spaces/KDS/pages/802226218/Multicall-Compatible+Peer-to-Peer+Communication
 */

type CallState = {
  callStartTimestamp: number,
  knownUsers: Immutable.Map<string, { // The key is the spreedId
    spreedId: string,
    userId: number,
    name?: {
      first: string,
      last: string
    },
    color: string
  }>,
  largeScreen: {
    senderSpreedId: string,
    timestamp: number,
    userSpreedId: string
  },
  drawing: {
    senderSpreedId?: string,
    timestamp?: number,
    active: boolean,
    image?: string,
    // lines are stored in the DrawingAndMarkerStateContainer for performance reasons
  },
  visibility: Immutable.Map<string, { // The key is the id
    senderSpreedId: string,
    timestamp: number,
    visible: boolean,
    id: string
  }>,
  pendingInvites: Immutable.Map<number, { // The key is the inviteId
    invite: Invite,
    expiresTimestamp: number
  }>,
  streamStatus: Immutable.Map<string, { // The key is the userSpreedId
    senderSpreedId: string,
    timestamp: number,
    userSpreedId: string,
    videoEnabled: boolean,
    audioEnabled: boolean
  }>,
}

interface User {
  readonly userId: number,
  readonly spreedId: string
}

interface Name {
  readonly first: string,
  readonly last: string
}

export class CallStateContainer extends Container<CallState> {

  public readonly spreedRoom: SpreedRoom;

  private _localClock = 0;
  public get localClock() {
    return this._localClock;
  }

  private readonly ownUser: User;
  private readonly ownColor: string;
  private readonly ownName?: Name;

  public readonly drawingAndMarkerStateContainer: DrawingAndMarkerStateContainer;

  private readonly onLargeScreenSwitch: (spreedUserId: string) => void;
  private readonly onHello: (name?: Name) => void;
  private readonly onRemovePendingInvite: (invite: Invite, reason: RemovePendingInviteReason) => void;
  private readonly onChatMessage: (message: ChatMessage) => void;
  private readonly onTakeScreenshot: () => string | null;
  private readonly onScreenshotSaved: (user: User, name?: Name) => void;

  constructor(
    spreedRoom: SpreedRoom,
    ownUser: User,
    drawingAndMarkerStateContainer: DrawingAndMarkerStateContainer,
    onLargeScreenSwitch: (spreedUserId: string) => void,
    onHello: (name?: Name) => void,
    onRemovePendingInvite: (invite: Invite, reason: RemovePendingInviteReason) => void,
    onChatMessage: (message: ChatMessage) => void,
    onTakeScreenshot: () => string | null,
    onScreenshotSaved: (user: User, name?: Name) => void,
    ownColor: string,
    ownName?: Name,
  ) {
    super();
    this.spreedRoom = spreedRoom;
    this.ownUser = ownUser;
    this.drawingAndMarkerStateContainer = drawingAndMarkerStateContainer;
    this.onLargeScreenSwitch = onLargeScreenSwitch;
    this.onHello = onHello;
    this.onRemovePendingInvite = onRemovePendingInvite;
    this.onChatMessage = onChatMessage;
    this.onTakeScreenshot = onTakeScreenshot;
    this.onScreenshotSaved = onScreenshotSaved;
    this.ownColor = ownColor;
    this.ownName = ownName;
    this.state = {
      callStartTimestamp: Date.now(),
      knownUsers: Immutable.Map(),
      largeScreen: {
        userSpreedId: ownUser.spreedId,
        timestamp: -1,
        senderSpreedId: ownUser.spreedId
      },
      drawing: {
        active: false,
      },
      visibility: Immutable.Map(),
      pendingInvites: Immutable.Map(),
      streamStatus: Immutable.Map(),
    };

    // Add our own user to the list with known users
    this.state.knownUsers = this.state.knownUsers.set(ownUser.spreedId, {
      spreedId: ownUser.spreedId,
      userId: ownUser.userId,
      name: ownName,
      color: ownColor
    });
  }

  /**
   * Handles the given event.
   *
   * @param sender The sender of the event.
   * @param event The event to handle.
   * @param fake If the given event is a fake event (it's not received from another user but for example from a WELCOME event).
   */
  public async handleEvent(sender: User, event: BaseEvent, fake: boolean) {
    this._localClock = Math.max(this._localClock, event.timestamp);
    switch (event.type) {
      case 'SHOW_LARGE_SCREEN':
        return await this.handleShowLargeScreenEvent(sender.spreedId, event as ShowLargeScreenEvent, fake);
      case 'TAKE_SCREENSHOT':
        return await this.handleTakeScreenshotEvent(sender.spreedId, event as TakeScreenshotEvent, fake);
      case 'SCREENSHOT_SAVED':
        return await this.handleScreenshotSavedEvent(sender, event as ScreenshotSavedEvent, fake);
      case 'START_DRAWING':
        return await this.handleStartDrawingEvent(sender.spreedId, event as StartDrawingEvent, fake);
      case 'STOP_DRAWING':
        return await this.handleStopDrawingEvent(sender.spreedId, event as StopDrawingEvent, fake);
      case 'DRAW_LINE':
        return await this.handleDrawLineEvent(sender.spreedId, event as DrawLineEvent, fake);
      case 'SET_LINES_VISIBILITY':
        return await this.handleSetLinesVisibility(sender.spreedId, event as SetLinesVisibilityEvent, fake);
      case 'SHOW_MARKER':
        return await this.handleShowMarkerEvent(sender.spreedId, event as ShowMarkerEvent, fake);
      case 'STREAM_STATUS_UPDATE':
        return await this.handleStreamStatusUpdateEvent(sender.spreedId, event as StreamStatusUpdateEvent, fake);
      case 'CHAT_MESSAGE':
        return await this.handleChatMessageEvent(sender.spreedId, event as ChatMessageEvent, fake);
      case 'INVITED_USER':
        return await this.handleInvitedUserEvent(sender.spreedId, event as InvitedUserEvent, fake);
      case 'REMOVE_PENDING_INVITE':
        return await this.handleRemovePendingInvite(sender.spreedId, event as RemovePendingInvite, fake);
      case 'HELLO':
        return await this.handleHelloEvent(sender, event as HelloEvent, fake);
      case 'WELCOME':
        return await this.handleWelcomeEvent(sender.spreedId, event as WelcomeEvent, fake);
    }
  }

  /**
   * Handles the given notification.
   */
  public async handleNotification(notification: BaseNotification) {
    switch (notification.type) {
      case 'INVITEE_DECLINED':
        return await this.handleInviteeDeclinedNotification(notification as InviteeDeclinedNotification);
    }
  }

  /**
   * Sends the HELLO event to the given user which should be answered with WELCOME.
   */
  public async sendHelloToUser(spreedId: string) {
    const event: HelloEvent = {
      type: 'HELLO',
      timestamp: this._localClock,
      payload: {
        name: this.ownName,
        userId: this.ownUser.userId,
        color: this.ownColor
      }
    };
    this.spreedRoom.dispatchEvent(spreedId, event);
  }

  /**
   * Broadcasts a `SCREENSHOT_SAVED` event to all other users.
   */
  public async broadcastScreenshotSavedEvent() {
    const event: ScreenshotSavedEvent = {
      type: 'SCREENSHOT_SAVED',
      timestamp: this._localClock,
      payload: null
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, true);
  }

  /**
   * Sets the own marker and broadcasts this information to all other users.
   */
  public async setMarker(x: number, y: number) {
    const event: ShowMarkerEvent = {
      type: 'SHOW_MARKER',
      timestamp: this._localClock,
      payload: {x: x, y: y, color: this.ownColor}
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, true);
  }

  /**
   * Sets who should be displayed large and broadcasts this information to all other users.
   */
  public async setLargeScreen(spreedId: string) {
    const event: ShowLargeScreenEvent = {
      type: 'SHOW_LARGE_SCREEN',
      timestamp: ++this._localClock,
      payload: spreedId
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, false);
  }

  /**
   * Draws a line and broadcasts it to all other users.
   */
  public async drawLine(data: {
    id: string,
    color: string,
    points: Array<{ x: number, y: number }>
  }) {
    let incrementLocalClock = !this.drawingAndMarkerStateContainer.state.lines.has(data.id); // TODO: This might be a problem, because the state does not have to be up2date
    const event: DrawLineEvent = {
      type: 'DRAW_LINE',
      timestamp: incrementLocalClock ? ++this._localClock : this._localClock,
      payload: data
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, false);
  }

  /**
   * Broadcasts the given chat message to all other users.
   */
  public async broadcastChatMessage(message: ChatMessage) {
    const event: ChatMessageEvent = {
      type: 'CHAT_MESSAGE',
      timestamp: this._localClock,
      payload: message
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, true);
  }

  /**
   * Sets the local stream status and broadcasts this information to all other users.
   */
  public async setStreamStatus(videoEnabled: boolean, audioEnabled: boolean) {
    const event: StreamStatusUpdateEvent = {
      type: 'STREAM_STATUS_UPDATE',
      timestamp: ++this._localClock,
      payload: {videoEnabled, audioEnabled}
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, false);
  }

  /**
   * Adds the given user to the list of pending invites and broadcasts this information to all other users.
   */
  public async addToPendingInvites(invite: Invite) {
    const event: InvitedUserEvent = {
      type: 'INVITED_USER',
      timestamp: this.localClock,
      payload: invite
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, false);
  }

  /**
   * Requests the user, that is currently large, to take a screenshot.
   */
  public async takeScreenshot() {
    const event: TakeScreenshotEvent = {
      type: 'TAKE_SCREENSHOT',
      timestamp: this.localClock,
      payload: null
    };
    if (this.state.largeScreen) {
      if (this.state.largeScreen.userSpreedId === this.ownUser.spreedId) {
        await this.handleEvent(this.ownUser, event, false);
      } else {
        this.spreedRoom.dispatchEvent(this.state.largeScreen.userSpreedId, event);
      }
    }
  }

  /**
   * Stops the current drawing.
   */
  public async stopDrawing() {
    const event: StopDrawingEvent = {
      type: 'STOP_DRAWING',
      timestamp: ++this._localClock,
      payload: null
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, false);
  }

  /**
   * Sets the visibility of all lines to false.
   */
  public async deleteAllLines() {
    const event: SetLinesVisibilityEvent = {
      type: 'SET_LINES_VISIBILITY',
      timestamp: ++this._localClock,
      payload: Array.from(this.drawingAndMarkerStateContainer.state.lines.map(line => ({
        id: line.id,
        visible: false
      })).values())
    };
    this.spreedRoom.broadcastEvent(event);
    await this.handleEvent(this.ownUser, event, false);
  }

  /**
   * Removes a user from the list of pending invites. Does not broadcast.
   */
  public async removeFromPendingInvitesByUserId(userId: number, reason: RemovePendingInviteReason) {
    const invitesWithUser = this.state.pendingInvites
      .filter(pendingInvite => pendingInvite.invite.invitee.id == userId)
      .map(pendingInvite => pendingInvite.invite)
      .values();
    for (let invite of Array.from(invitesWithUser)) {
      const event: RemovePendingInvite = {
        type: 'REMOVE_PENDING_INVITE',
        timestamp: this.localClock,
        payload: {invite, reason}
      };
      await this.handleEvent(this.ownUser, event, true);
    }
  }

  /**
   * Removes a user from the list of pending invites with the option to broadcast the message to all other users.
   */
  public async removeFromPendingInvites(invite: Invite, reason: RemovePendingInviteReason, broadcast: boolean) {
    const event: RemovePendingInvite = {
      type: 'REMOVE_PENDING_INVITE',
      timestamp: this.localClock,
      payload: {invite, reason}
    };
    if (broadcast) {
      this.spreedRoom.broadcastEvent(event);
    }
    await this.handleEvent(this.ownUser, event, false);
  }

  /**
   * Removes all pending invites that are expired..
   */
  public async cleanupPendingInvites() {
    await this.setState(prevState => ({
      pendingInvites: prevState.pendingInvites.filter(invite => invite.expiresTimestamp > Date.now())
    }));
  }

  /**
   * Removes all pending invites that are expired..
   */
  public async cleanupMarkers() {
    await this.drawingAndMarkerStateContainer.setState(prevState => ({
      markers: prevState.markers.filter(marker => marker.expiresTimestamp > Date.now())
    }));
  }

  private async handleInviteeDeclinedNotification(notification: InviteeDeclinedNotification) {
    return this.removeFromPendingInvites(notification.payload.invite, 'MISSED', true);
  }

  private async handleShowLargeScreenEvent(senderSpreedId: string, event: ShowLargeScreenEvent, fake: boolean) {
    await this.setState(prevState => {
      if (isFirstNewer({senderSpreedId, timestamp: event.timestamp}, prevState.largeScreen)) {
        if (!fake && (!prevState.largeScreen || prevState.largeScreen.userSpreedId !== event.payload)) {
          this.onLargeScreenSwitch(event.payload);
        }
        return {
          largeScreen: {
            senderSpreedId,
            timestamp: event.timestamp,
            userSpreedId: event.payload
          }
        };
      }
      return null;
    });
  }

  private async handleTakeScreenshotEvent(senderSpreedId: string, event: TakeScreenshotEvent, fake: boolean) {
    const screenshotDataUri = this.onTakeScreenshot();
    if (screenshotDataUri) {
      const event: StartDrawingEvent = {
        type: 'START_DRAWING',
        timestamp: ++this._localClock,
        payload: {
          image: screenshotDataUri
        }
      };
      this.spreedRoom.broadcastEvent(event);
      await this.handleEvent(this.ownUser, event, true);
    }
  }

  private async handleScreenshotSavedEvent(user: User, event: ScreenshotSavedEvent, fake: boolean) {
    if (this.state.knownUsers.get(user.spreedId)) {
      this.onScreenshotSaved(user, this.state.knownUsers.get(user.spreedId)!!.name);
    }
  }

  private async handleStartDrawingEvent(senderSpreedId: string, event: StartDrawingEvent, fake: boolean) {
    await this.setState(prevState => {
      if (isFirstNewer({senderSpreedId, timestamp: event.timestamp}, prevState.drawing)) {
        return {
          drawing: {
            senderSpreedId,
            timestamp: event.timestamp,
            active: true,
            image: event.payload.image
          }
        };
      }
      return null;
    });
  }

  private async handleStopDrawingEvent(senderSpreedId: string, event: StopDrawingEvent, fake: boolean) {
    await this.setState(prevState => {
      if (isFirstNewer({senderSpreedId, timestamp: event.timestamp}, prevState.drawing)) {
        return {
          drawing: {
            senderSpreedId,
            timestamp: event.timestamp,
            active: false
          }
        };
      }
      return null;
    });
  }

  private async handleDrawLineEvent(senderSpreedId: string, event: DrawLineEvent, fake: boolean) {
    await this.drawingAndMarkerStateContainer.setState(prevState => {
      let affectedLine = prevState.lines.get(event.payload.id);
      // The DRAW_LINE event is special. We don't need the isFirstNewer(...) check here
      if (affectedLine == null || event.payload.points.length > affectedLine.points.length) {
        return {
          lines: prevState.lines.set(event.payload.id, {
            senderSpreedId,
            timestamp: event.timestamp,
            ...event.payload
          })
        };
      }

      // We can ignore the event because we have newer line data available
      // This should only happen if it was a "fake" event that was created from a WELCOME event
      return null;
    });
  }

  private async handleSetLinesVisibility(senderSpreedId: string, event: SetLinesVisibilityEvent, fake: boolean) {
    await this.setState(prevState => {
      let visibility = prevState.visibility;

      for (let eventElement of event.payload) {
        let currentElement = visibility.get(eventElement.id);
        if (currentElement == null || isFirstNewer({senderSpreedId, timestamp: event.timestamp}, currentElement)) {
          visibility = visibility.set(eventElement.id, {
            senderSpreedId,
            timestamp: event.timestamp,
            ...eventElement
          });
        }
      }

      return {visibility};
    });
  }

  private async handleShowMarkerEvent(senderSpreedId: string, event: ShowMarkerEvent, fake: boolean) {
    await this.drawingAndMarkerStateContainer.setState(prevState => {
      return {
        markers: prevState.markers.set(senderSpreedId, {
          senderSpreedId,
          x: event.payload.x,
          y: event.payload.y,
          color: event.payload.color,
          expiresTimestamp: Date.now() + 1950,
        })
      };
    });
    setTimeout(() => this.cleanupMarkers(), 2000);
  }

  private async handleStreamStatusUpdateEvent(senderSpreedId: string, event: StreamStatusUpdateEvent, fake: boolean) {
    await this.setState(prevState => {
      let affectedStatus = prevState.streamStatus.get(senderSpreedId);
      if (affectedStatus == null || isFirstNewer({senderSpreedId, timestamp: event.timestamp}, affectedStatus)) {
        return {
          streamStatus: prevState.streamStatus.set(senderSpreedId, {
            senderSpreedId,
            timestamp: event.timestamp,
            userSpreedId: senderSpreedId,
            ...event.payload
          })
        };
      }

      return null;
    });
  }

  private async handleChatMessageEvent(senderSpreedId: string, event: ChatMessageEvent, fake: boolean) {
    this.onChatMessage(event.payload);
  }

  private async handleInvitedUserEvent(senderSpreedId: string, event: InvitedUserEvent, fake: boolean) {
    await this.setState(prevState => {
      return {
        pendingInvites: prevState.pendingInvites.set(event.payload.id, {
          invite: event.payload,
          expiresTimestamp: Date.now() + 60000
        })
      };
    });
  }

  private async handleRemovePendingInvite(senderSpreedId: string, event: RemovePendingInvite, fake: boolean) {
    if (!fake) {
      this.onRemovePendingInvite(event.payload.invite, event.payload.reason);
    }
    await this.setState(prevState => {
      return {
        pendingInvites: prevState.pendingInvites.delete(event.payload.invite.id)
      };
    });
  }

  private async handleHelloEvent(sender: User, event: HelloEvent, fake: boolean) {
    await this.setState(prevState => {
      const welcomeEvent: WelcomeEvent = {
        type: 'WELCOME',
        timestamp: this._localClock,
        payload: {
          callDuration: Date.now() - prevState.callStartTimestamp,
          knownUsers: Array.from(prevState.knownUsers.values()),
          largeScreen: prevState.largeScreen,
          drawing: {
            ...prevState.drawing,
            lines: Array.from(this.drawingAndMarkerStateContainer.state.lines.values()),
            visibility: Array.from(prevState.visibility.values()),
          },
          pendingInvites: Array.from(prevState.pendingInvites.values()).map(value => ({
            invite: value.invite,
            remaining: value.expiresTimestamp - Date.now()
          })),
          streamStatus: Array.from(prevState.streamStatus.values())
        }
      };

      this.spreedRoom.dispatchEvent(sender.spreedId, welcomeEvent);
      if (event.payload.name) {
        this.onHello(event.payload.name);
        return {
          knownUsers: prevState.knownUsers.set(sender.spreedId, {
            spreedId: sender.spreedId,
            userId: sender.userId,
            name: event.payload.name,
            color: event.payload.color
          })
        };
      }
      return null;
    });
  }

  private async handleWelcomeEvent(senderSpreedId: string, event: WelcomeEvent, fake: boolean) {
    await this.setState(prevState => {
      let knownUsers = prevState.knownUsers;
      event.payload.knownUsers.forEach(user => knownUsers = knownUsers.set(user.spreedId, user));

      let pendingInvites = prevState.pendingInvites;
      event.payload.pendingInvites.forEach(pendingInvite => {
        const stateInvite = pendingInvites.get(pendingInvite.invite.id);
        // Set the pending invite if it does not exist or the current one expires earlier
        if (!stateInvite || (stateInvite && stateInvite.expiresTimestamp < pendingInvite.remaining + Date.now())) {
          pendingInvites = pendingInvites.set(pendingInvite.invite.id, {
            invite: pendingInvite.invite,
            expiresTimestamp: pendingInvite.remaining + Date.now()
          });
        }
      });

      return {
        callStartTimestamp: Math.min(prevState.callStartTimestamp, Date.now() - event.payload.callDuration),
        knownUsers,
        pendingInvites
      }
    });

    // For the rest we can just create some "fake" events and handle them instead

    if (event.payload.largeScreen) {
      await this.handleShowLargeScreenEvent(event.payload.largeScreen.senderSpreedId, {
        type: 'SHOW_LARGE_SCREEN',
        timestamp: event.payload.largeScreen.timestamp,
        payload: event.payload.largeScreen.userSpreedId
      }, true);
    }

    if (event.payload.drawing.active) {
      await this.handleStartDrawingEvent(event.payload.drawing.senderSpreedId || '', {
        type: 'START_DRAWING',
        timestamp: event.payload.drawing.timestamp || -2,
        payload: {
          image: event.payload.drawing.image!!
        }
      }, true);
    } else {
      await this.handleStopDrawingEvent(event.payload.drawing.senderSpreedId || '', {
        type: 'STOP_DRAWING',
        timestamp: event.payload.drawing.timestamp || -2,
        payload: null
      }, true);
    }

    for (let line of event.payload.drawing.lines) {
      await this.handleDrawLineEvent(line.senderSpreedId, {
        type: 'DRAW_LINE',
        timestamp: line.timestamp,
        payload: {
          points: line.points,
          color: line.color,
          id: line.id
        }
      }, true);
    }

    for (let visibility of event.payload.drawing.visibility) {
      await this.handleSetLinesVisibility(visibility.senderSpreedId, {
        type: 'SET_LINES_VISIBILITY',
        timestamp: visibility.timestamp,
        payload: [{
          visible: visibility.visible,
          id: visibility.id
        }]
      }, true);
    }

    for (let streamStatus of event.payload.streamStatus) {
      await this.handleStreamStatusUpdateEvent(streamStatus.senderSpreedId, {
        type: 'STREAM_STATUS_UPDATE',
        timestamp: streamStatus.timestamp,
        payload: {
          videoEnabled: streamStatus.videoEnabled,
          audioEnabled: streamStatus.audioEnabled
        }
      }, true);
    }
  }

}

/**
 * Checks if the first parameter is "newer/larger/..." than the second parameter.
 */
export function isFirstNewer(first: { senderSpreedId?: string, timestamp?: number }, second?: { senderSpreedId?: string, timestamp?: number }) {
  if (second === undefined || second === null) {
    return true;
  }
  if ((first.timestamp || -1) > (second.timestamp || -1)) {
    return true;
  }
  if ((first.timestamp || -1) < (second.timestamp || -1)) {
    return false;
  }
  return (first.senderSpreedId || '') > (second.senderSpreedId || '');
}
