import {useSpring} from 'react-spring';

/**
 * Hook that returns a `react-spring` animation that makes pages appear smoothly.
 */
export function useMainLoadTransition(props: { loading: boolean }) {
  return useSpring({
    opacity: props.loading ? 0 : 1, transform: props.loading ? 'translate3d(0%,10%,0)' : 'translate3d(0%,0,0)',
    from: {opacity: 0, transform: 'translate3d(0,10%,0)'}
  });
}
