import * as React from 'react';
import {useMemo} from 'react';
import {Theme} from '@material-ui/core';
import {grey, red} from '@material-ui/core/colors';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CallEndIcon from '@material-ui/icons/CallEnd';
import ChatIcon from '@material-ui/icons/Chat';
import BrushIcon from '@material-ui/icons/Brush';
import IconButton from '@material-ui/core/IconButton/IconButton';
import {SingleClickIconButton} from '../../../components/SingleClickButton';
import {SingleClickButtonWithSubmenu} from "../../../components/SingleClickButtonWithSubmenu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {createStyles, makeStyles} from "@material-ui/styles";

/**
 * This is `theme.palette.background.default` with additional alpha-value.
 * Using `opacity` has the problem that its content and children are also affected.
 * So we hard-code this color here, theme background does not change so often, I reckon.
 */
const OVERLAY_BACKGROUND_COLOR = '#30303099';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: OVERLAY_BACKGROUND_COLOR,
    zIndex: 20,
  },
  button: {
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    '&:hover': {
      backgroundColor: grey[800],
    },
    margin: `${theme.spacing()}px ${theme.spacing(0.5)}px`,
  },
  endCallButton: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    '&:hover': {
      backgroundColor: red[800],
    },
  },
}));

export function VideoControls(props: {
  videoEnabled: boolean,
  screenShareEnabled: boolean,
  audioEnabled: boolean,
  videoSteamsSize: number,
  showAddUserButton: boolean,
  chatIsOpen: boolean,
  drawingIsPossible: boolean,
  onSwitchToNextVideoTrack(): void,
  onSwitchAudioEnabled(): void,
  onSwitchScreenShareEnabled(): void,
  onSwitchVideoEnabled(): void,
  onAddUser(): void,
  onLeaveCall(): void,
  onChatOpen(): void,
  onStartDrawing(): void
}) {
  const classes = useStyles();
  const useMoreButton = useMediaQuery(`(max-width:${300 + 55 * (props.videoSteamsSize > 1 ? 1 : 0) + 55 * (props.showAddUserButton ? 1 : 0)}px)`);

  const leaveCallButton = useMemo(() => (
    <SingleClickIconButton
      buttonProps={{
        className: `${classes.button} ${classes.endCallButton}`,
        title: 'Leave call',

      }}
      onSingleClick={async () => props.onLeaveCall()}
    >
      <CallEndIcon/>
    </SingleClickIconButton>
  ), [props.onLeaveCall]);

  const muteUnmuteButton = useMemo(() => (
    <IconButton
      className={classes.button}
      onClick={props.onSwitchAudioEnabled}
      title={props.audioEnabled ? 'Mute' : 'Unmute'}
    >
      {props.audioEnabled ? <MicIcon/> : <MicOffIcon/>}
    </IconButton>
  ), [props.audioEnabled, props.onSwitchAudioEnabled]);

  const switchCameraButton = useMemo(() => (
    <IconButton
      disabled={props.screenShareEnabled}
      className={classes.button}
      onClick={props.onSwitchToNextVideoTrack}
      title={'Switch camera'}
    >
      <SwitchVideoIcon/>
    </IconButton>
  ), [props.onSwitchToNextVideoTrack]);

  const disableOrActivateCameraButton = useMemo(() => (
    <IconButton
      className={classes.button}
      onClick={props.onSwitchVideoEnabled}
      title={props.videoEnabled ? 'Disable camera' : 'Activate camera'}
    >
      {props.videoEnabled ? <VideocamIcon/> : <VideocamOffIcon/>}
    </IconButton>
  ), [props.onSwitchVideoEnabled, props.videoEnabled]);

  const disableOrActivateScreenShareButton = useMemo(() => (
    <IconButton
      className={classes.button}
      onClick={props.onSwitchScreenShareEnabled}
      title={props.screenShareEnabled ? 'Disable screen share' : 'Share screen'}
    >
      {props.screenShareEnabled ? <StopScreenShareIcon/> : <ScreenShareIcon/>}
    </IconButton>
  ), [props.onSwitchScreenShareEnabled, props.screenShareEnabled]);

  const addUserButton = useMemo(() => (
    <IconButton
      className={classes.button}
      onClick={props.onAddUser}
      title={'Add user to call'}
    >
      <PersonAddIcon/>
    </IconButton>
  ), [props.onAddUser]);

  const openChatButton = useMemo(() => (
    <SingleClickIconButton
      buttonProps={{
        disabled: props.chatIsOpen,
        className: classes.button,
        title: "Open Chat",
      }}
      onSingleClick={async () => props.onChatOpen()}
    >
      <ChatIcon/>
    </SingleClickIconButton>
  ), [props.chatIsOpen, props.onChatOpen]);

  const startDrawingButton = useMemo(() => (
    <SingleClickIconButton
      buttonProps={{
        disabled: !props.drawingIsPossible,
        className: classes.button,
        title: "Start Drawing",
      }}
      onSingleClick={async () => props.onStartDrawing()}
    >
      <BrushIcon/>
    </SingleClickIconButton>
  ), [props.drawingIsPossible, props.onStartDrawing]);

  return (
    <div className={classes.root}>
      {leaveCallButton}
      {muteUnmuteButton}
      {props.videoSteamsSize > 1 && switchCameraButton}
      {disableOrActivateCameraButton}
      {disableOrActivateScreenShareButton}
      {!useMoreButton && props.showAddUserButton && addUserButton}
      {!useMoreButton && openChatButton}
      {!useMoreButton && startDrawingButton}
      {useMoreButton && (
        <SingleClickButtonWithSubmenu>
          {props.showAddUserButton && addUserButton}
          {openChatButton}
          {startDrawingButton}
        </SingleClickButtonWithSubmenu>
      )}
    </div>
  );
}
