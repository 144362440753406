import * as React from 'react';
import {useEffect, useState} from 'react';
import Spinner from "../../../spinner/Spinner";
import {grey} from '@material-ui/core/colors';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import {createStyles, makeStyles} from "@material-ui/styles";
import {Video} from "../../../components/Video";
import {MarkerCanvas} from "./MarkerCanvas";
import {DrawingCanvas} from "./DrawingCanvas";
import {CallStateContainer} from "./state/CallStateContainer";
import {Subscribe} from "unstated";

const useStyles = makeStyles((theme) => createStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    zIndex: 5,
  },
  centered: {
    position: 'fixed',
    margin: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  videoCamIcon: {
    height: '2em',
    width: '2em',
    fontSize: 36
  }
}));

export function LargeVideo(props: {
  stream?: MediaStream,
  audioEnabled: boolean,
  videoEnabled: boolean,
  ownStreamIsFrontFacing: boolean,
  name: { first: string, last: string } | null,
  isSelf: boolean,
  ref?(ref: HTMLVideoElement | null): void,
  takeScreenshotHandlerRef?(ref: (() => string) | null): void,
  drawingColor: string,
  takeDrawingScreenshotRef(ref: null | (() => Promise<File | null>)): void
}) {
  const classes = useStyles();
  const {stream, audioEnabled, videoEnabled, isSelf, ownStreamIsFrontFacing} = props;
  const [videoRef, setVideoRef] = useState(null as HTMLVideoElement | null);

  const mirrored = isSelf && ownStreamIsFrontFacing;

  useEffect(() => {
    if (props.takeScreenshotHandlerRef) {
      if (!videoRef) {
        props.takeScreenshotHandlerRef(null);
        return;
      }
      props.takeScreenshotHandlerRef(() => {
        return takeScreenshot(videoRef);
      });
    }
  }, [videoRef, props.takeScreenshotHandlerRef]);

  return (
    <>
      <Video
        stream={stream}
        mirrored={mirrored}
        audioEnabled={audioEnabled}
        videoEnabled={videoEnabled}
        className={classes.root}
        videoRef={setVideoRef}
      />
      {!stream && (
        <div className={classes.root}>
          <Spinner classNameOuter={classes.centered} color={grey[200]}/>
        </div>
      )}
      {stream && !videoEnabled && (
        <div className={classes.root}>
          <div className={classes.centered}>
            <VideocamOffIcon className={classes.videoCamIcon} color="secondary"/>
          </div>
        </div>
      )}
      <Subscribe to={[CallStateContainer]}>
        {(callStateContainer: CallStateContainer) => (
          <>
            {!callStateContainer.state.drawing.image && <MarkerCanvas video={videoRef} mirrored={mirrored}/>}
            {callStateContainer.state.drawing.image &&
            <DrawingCanvas imageDataUri={callStateContainer.state.drawing.image} drawingColor={props.drawingColor}
                           takeDrawingScreenshotRef={props.takeDrawingScreenshotRef}/>}
          </>
        )}
      </Subscribe>

    </>
  );
}

/**
 * Creates a temporary canvas to draw the current video image and gets the image data url from it.
 */
function takeScreenshot(video: HTMLVideoElement) {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const ctx = canvas.getContext('2d')!!;
  video.pause();
  ctx.drawImage(video, 0, 0);
  video.play();
  return canvas.toDataURL('image/jpeg');
}
