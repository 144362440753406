/**
 * This module applies some `loglevel`-configuration.
 * Clients should use this module to enforce that this module was loaded and the configuration has really been applied.
 */
import * as log from 'loglevel';
import {devModeEnabled} from './devMode';

fixConsoleDebugLogging();

log.setDefaultLevel(devModeEnabled ? 'debug' : 'info');

/**
 * Make `log.debug` use `console.debug` (not `console.log`), so
 * we can use Chrome's filtering functions.
 * See: https://github.com/pimterry/loglevel/issues/126#issuecomment-433131539
 */
function fixConsoleDebugLogging() {
  const originalFactory = log.methodFactory;
  // @ts-ignore
  log.methodFactory = (methodName, logLevel, loggerName) => {
    if (methodName !== 'debug') {
      return originalFactory(methodName, logLevel, loggerName);
    } else {
      // tslint:disable
      return console.debug || originalFactory(methodName, logLevel, loggerName);
    }
  };
  log.setLevel(log.getLevel(), false); // Be sure to call setLevel method in order to apply plugin
}

export default log;
