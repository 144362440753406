/**
 * Validates if the given string is a valid email address.
 *
 * @param email The email to validate.
 */
export function isValidEmail(email?: string) {
  if (!email) {
    return false;
  }
  // From https://www.regular-expressions.info/email.html with modifications to allow some special characters
  return /^[A-Z0-9._%+ÄäÖöÜü-]+@[A-Z0-9.ÄäÖöÜü-]+\.[A-Z]{2,}$/i.test(email)
}

/**
 * Validates if the given string is a valid internet address.
 *
 * @param url The internet address to validate.
 */
export function isValidUrl(url?: string) {
  if (!url) {
    return false;
  }
  // From https://www.quora.com/What-is-the-best-way-to-validate-for-a-URL-in-JavaScript
  const pattern = /^(?:(?:https?|ftp):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return pattern.test(url);
}

/**
 * Validates if the given string is within the given length (or 250 by default).
 *
 * @param str The string to validate.
 * @param maxLength The maximum length of the string.
 */
export function isWithinMaxLength(str?: string, maxLength?: number) {
  if (!str) {
    return false;
  }
  maxLength = maxLength || 250;
  return str.length <= maxLength;
}

/**
 * Checks if the given number is in the given range.
 *
 * @param num The number to test.
 * @param min The minimum number (inclusive).
 * @param max The maximum number (inclusive).
 */
export function isInRange(num?: number, min?: number, max?: number) {
  if (num === undefined || num === null || min === undefined || min === null || max === undefined || max === null) {
    return false;
  }
  return num >= min && num <= max;
}
