import {addOnErrorListener, addUnhandledRejectionListener} from '../util/errorListenerUtils';
import {errorStore} from './ErrorStore';

export function initUncaughtRejectionListener() {
  addOnErrorListener((m: Event | string) => {
    const message = `Uncaught error: ${m}`;
    // tslint:disable-next-line no-console
    console.error(message);
    errorStore.addError(message);
  });

  addUnhandledRejectionListener((e: PromiseRejectionEvent) => {
      const message = `Uncaught promise rejection: ${e.reason.toString()}`;
      // tslint:disable-next-line no-console
      console.error(message);
      errorStore.addError(message);
    }
  );
}
