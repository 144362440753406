import * as React from 'react';
import {useEffect, useState} from 'react';
import {AccountDto} from '../../../api/userTypes';
import {Theme, useMediaQuery} from '@material-ui/core';
import ItemList from "./ItemList";
import {loadItems} from "../../../api/itemApi";
import {fullPageable} from "../../../query/pageable";
import {Item} from "../../../api/itemTypes";
import SupportCaseScroller from "../support-case/SupportCaseScroller";
import {SupportCase} from "../../../api/supportCaseTypes";
import {getSupportCases} from "../../../api/supportCaseApi";
import {routes} from "../../../router/MyRouter";
import EmbeddedPage from "../../../components/EmbeddedPage";
import Fab from "@material-ui/core/Fab/Fab";
import AddIcon from "@material-ui/icons/Add";
import {CONTAINER_MAX_WIDTH} from "../../../styles/muiTheme";
import {AppBarTopPadding} from "../../../components/AppBarTopPadding";
import {LandingPageAppBar} from "../LandingPageAppBar";
import {animated, useSpring} from "react-spring";
import {createStyles, makeStyles, useTheme} from "@material-ui/styles";
import {useRouter} from "../../../util/hooks/useRouterHook";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
  fab: {
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    position: 'fixed',
    [`@media (min-width: ${CONTAINER_MAX_WIDTH}px)`]: {
      right: `calc(50% - ${CONTAINER_MAX_WIDTH / 2 - theme.spacing(2)}px)`
    },
  },
  extendedIcon: {
    [theme.breakpoints.up('sm')]: {
      // Some padding for the fab
      marginRight: theme.spacing()
    }
  },
}));

export function EndUserItemsPage(props: {
  account: AccountDto
}) {
  const [items, setItems] = useState(null as Array<Item> | null);
  const [supportCases, setSupportCases] = useState(null as Array<SupportCase> | null);
  const mountTransition = useSpring({
    opacity: 1, transform: 'translate3d(0,0,0)',
    from: {opacity: 0, transform: 'translate3d(0,10%,0)'}
  });
  const {history} = useRouter();
  const classes = useStyles();
  const theme: Theme = useTheme();
  const useExtendedFab = !useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    (async () => {
      const itemPage = await loadItems({}, fullPageable());
      const supportCasePage = await getSupportCases({}, {
        page: 0,
        size: 20,
        sort: {field: 'lastActivity', order: 'desc'}
      });
      setItems(itemPage.content);
      setSupportCases(supportCasePage.content);
    })()
  }, []);

  // If there are no items, the user has to scan one first
  if (items && items.length <= 0) {
    history.push(routes.scanItem);
    return null;
  }

  return (
    <EmbeddedPage>
      <LandingPageAppBar title="Home"/>

      <AppBarTopPadding>
        <animated.div style={mountTransition}>
          <SupportCaseScroller supportCases={supportCases} history={history}/>

          <div className={classes.root}>
            <ItemList items={items} headline="Your items" noItemsText="There are no items"/>
          </div>
        </animated.div>

        <Fab
          variant={useExtendedFab ? 'extended' : 'round'}
          color="secondary"
          className={classes.fab}
          onClick={() => history.push(routes.scanItem)}
        >
          <AddIcon className={classes.extendedIcon}/>
          {useExtendedFab && 'Scan New Item'}
        </Fab>
      </AppBarTopPadding>

    </EmbeddedPage>
  );
}
