import * as React from 'react';
import {useState} from 'react';
import {Theme} from '@material-ui/core';
import {green, grey} from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import {SingleClickIconButton} from '../../../components/SingleClickButton';
import makeStyles from "@material-ui/styles/makeStyles";
import {DrawingColorPicker} from "./DrawingColorPicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createStyles} from "@material-ui/styles";

/**
 * This is `theme.palette.background.default` with additional alpha-value.
 * Using `opacity` has the problem that its content and children are also affected.
 * So we hard-code this color here, theme background does not change so often, I reckon.
 */
const OVERLAY_BACKGROUND_COLOR = '#30303099';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: OVERLAY_BACKGROUND_COLOR,
    zIndex: 20
  },
  button: {
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    '&:hover': {
      backgroundColor: grey[800],
    },
    margin: `${theme.spacing()}px ${theme.spacing(0.5)}px`,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -24,
    marginLeft: -24,
  }
}));

export function DrawingControls(props: {
  onCancel(): void,
  onDelete(): void,
  showSaveButton: boolean,
  onScreenshotSave(): Promise<unknown>,
  screenshotUploadProgress: number,
  drawingColor: string,
  onColorSelect(color: string): void
}) {
  const classes = useStyles();
  const [saveClickable, setSaveClickable] = useState(true);
  return (
    <div className={classes.root}>
      <SingleClickIconButton
        buttonProps={{
          className: classes.button,
          title: 'Cancel drawing',
        }}
        onSingleClick={async () => props.onCancel()}
      >
        <CloseIcon/>
      </SingleClickIconButton>
      <SingleClickIconButton
        buttonProps={{
          className: classes.button,
          title: 'Delete current drawing',
        }}
        onSingleClick={async () => props.onDelete()}
      >
        <DeleteIcon/>
      </SingleClickIconButton>
      {props.showSaveButton && (
        <SingleClickIconButton
          buttonProps={{
            className: classes.button,
            disabled: !saveClickable,
            title: 'Save current drawing',
          }}
          onSingleClick={async () => {
            if (!saveClickable) {
              // Prevent users from accidentally spam-clicking the button
              return;
            }
            setSaveClickable(false);
            await props.onScreenshotSave();
            setSaveClickable(true)
          }}
        >
          <SaveIcon/>
          {!saveClickable &&
          <CircularProgress size={48} variant="static" value={Math.round(props.screenshotUploadProgress * 100)}
                            className={classes.buttonProgress}/>}
        </SingleClickIconButton>
      )}
      <DrawingColorPicker onSelect={props.onColorSelect} currentColor={props.drawingColor}/>
    </div>
  );
}
