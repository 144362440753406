import {myAxios} from '../util/myAxios';
import {WebpushSubscription} from "./notificationTokenTypes";

export async function registerWebpush(subscription: WebpushSubscription): Promise<{}> {
  const axios = await myAxios();
  return axios.post(`/api/notification_tokens/webpush`, subscription)
}

export async function unregisterWebpush(subscription: WebpushSubscription): Promise<{}> {
  const axios = await myAxios();
  return axios.delete(`/api/notification_tokens/webpush`, {data: subscription})
}

export async function registerFirebase(token: string): Promise<{}> {
  return (await myAxios()).post(`/api/notification_tokens/firebase`, token, {
    headers: {
      'Content-Type': 'text/plain',
    }
  });
}
