import * as React from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Call, Screenshot} from '../../../../api/callTypes';
import moment from 'moment';
import {ChatBubble} from '../../call/chat/ChatBubble';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {displayName} from '../../../../api/userTypes';
import {Divider, Theme} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {HorizontalScroller} from '../../../../components/HorizontalScroller';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import {SupportCase} from '../../../../api/supportCaseTypes';
import {useCurrentAccountHasRole} from '../../../../util/hooks/useCurrentAccount';
import {useObjectUrl} from "../../../../util/hooks/useObjectUrl";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(),
    backgroundColor: theme.palette.background.paper,
  },
  screenshotContainer: {
    flex: '0 0 auto',
    padding: 2 * theme.spacing(),
    width: 'auto',
  },
  screenshotImage: {
    width: '300px',
    height: '140px',
  }
}));

export function CallCard(props: {
  call: Call;
  onChatClick: () => void;
  onScreenshotClick: (screenshot: Screenshot) => void;
}) {
  const {call} = props;
  const classes = useStyles();
  const bestEffortUsername = call.invites[0].invitee.name || call.invites[0].inviter.name;
  return (
    <Card className={classes.root}>
      <CardHeader
        title={bestEffortUsername && displayName(bestEffortUsername)}
        titleTypographyProps={{variant: 'h6'}}
        subheader={displayDate(call.invites[0].creationTime)}
      />
      {call.screenshots.length > 0 && <HorizontalScroller>
        {call.screenshots.map(screenshot =>
          <ScreenshotItem
            key={screenshot.id}
            screenshot={screenshot}
            onClick={() => props.onScreenshotClick(screenshot)}
          />
        )}
      </HorizontalScroller>}
      {call.lastChatMessage && <>
          <Divider variant='middle'/>
          <CardActionArea
              onClick={props.onChatClick}
          >
              <Typography variant='h6' style={{margin: 16, marginBottom: 0}}>
                  Chat
              </Typography>
              <ChatBubble
                  style={{marginLeft: 16, marginBottom: 16}}
                  position='left'
                  message={call.lastChatMessage}
              />
          </CardActionArea>
      </>}
    </Card>
  );
}

export function ScreenshotItem(props: {
  screenshot: Screenshot,
  onClick: () => void
}) {
  const classes = useStyles();
  const imageObjectUrl = useObjectUrl(props.screenshot._links.image);
  return (
    <CardActionArea
      key={props.screenshot.id}
      className={classes.screenshotContainer}
      onClick={props.onClick}
    >
      <CardMedia
        className={classes.screenshotImage}
        image={imageObjectUrl}
      />
    </CardActionArea>
  );
}

/**
 * Renders a card showing information about your missed calls.
 * Renders nothing when there are no missed calls.
 */
export function MissedCallsCard(props: { missedCallRecord: MissedCallsRecord }) {
  const classes = useStyles();
  const m = props.missedCallRecord;
  return <Card className={classes.root}>
    <CardHeader
      title={<span style={{color: 'red'}}>{m.count} missed call{m.count > 1 && 's'}</span>}
      titleTypographyProps={{variant: 'h6'}}
      subheader={displayDate(m.time)}
    />
  </Card>;
}

function displayDate(dateString: string): string {
  let t = moment(dateString);
  if (moment().diff(t, 'days') >= 1) {
    return t.format('L, LT')
  } else {
    return t.fromNow();
  }
}

export function useMissedCallsRecord(supportCase: SupportCase): MissedCallsRecord | null {
  const isEnduser = useCurrentAccountHasRole('END_USER') === true;
  const count: number = supportCase && (isEnduser ? supportCase.missedSupportAgentToClientCallCount : supportCase.missedClientToSupportAgentCallCount);
  const time: string | null = supportCase && (isEnduser ? supportCase.lastMissedSupportAgentToClientCallTime : supportCase.lastMissedClientToSupportAgentCallTime);
  if (count < 1 || !time) return null;
  return {
    count,
    time,
  };
}

export interface MissedCallsRecord {
  count: number;
  time: string;
}
