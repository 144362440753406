import * as React from 'react';
import {useEffect, useState} from 'react';
import {Item} from "../../../../api/itemTypes";
import {Dialog, MuiThemeProvider, Theme, useMediaQuery} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {useCurrentAccountHasRole} from "../../../../util/hooks/useCurrentAccount";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles, useTheme} from "@material-ui/styles";
import ClearIcon from '@material-ui/icons/Clear';
import {greenButtonTheme, redButtonTheme} from "../../../../styles/muiTheme";
import {useSaveButtonState} from "../../../../util/hooks/useSaveButtonStates";
import {MultilineText} from "../../../../components/MultilineText";
import {FileDto} from "../../../../api/fileApi";
import {Attachment} from "../../../../components/Attachment";
import {updateItem} from "../../../../api/itemApi";
import {isValidationErrors} from "../../../../api/restApi";
import {getHelperText, hasError} from "../../../../util/validation/helperTextUtil";
import {maxLengthValidation, notBlankValidation} from "../../../../util/validation/helperTextSuppliers";
import {userToString} from "../../../../components/Username";
import * as supportAgentApi from "../../../../api/supportAgentApi";
import {fullPageable} from "../../../../query/pageable";
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(),
  }
}));

export function ItemInfoDialog(props: {
  item: Item,
  onClose(): void,
  onUpdate(item: Item): void
}) {
  const [itemName, setItemName] = useState(props.item.name);
  const [itemDescription, setItemDescription] = useState(props.item.description);
  const [supportAgentId, setSupportAgentId] = useState(props.item.supportAgent.id);
  const [internalNote, setInternalNote] = useState(props.item.internalNote || '');
  const classes = useStyles();

  const theme: Theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const saveButtonState = useSaveButtonState(JSON.stringify([itemName, itemDescription, supportAgentId, internalNote]));
  useEffect(
    () => {
      saveButtonState.onChange(JSON.stringify([itemName, itemDescription, supportAgentId, internalNote]))
    },
    [itemName, itemDescription, supportAgentId, internalNote]
  );

  let isEnduser = useCurrentAccountHasRole('END_USER');
  isEnduser = (isEnduser || isEnduser === null);

  const [supportAgents, setSupportAgents] = useState([props.item.supportAgent]);
  useEffect(() => {
    if (!isEnduser) {
      supportAgentApi.getAll({}, fullPageable())
        .then(page => page.content)
        .then(setSupportAgents);
    }
  }, [props.item, isEnduser]);

  const disabled = saveButtonState.disabled
    || hasError(itemName, notBlankValidation, maxLengthValidation(1000))
    || hasError(itemDescription, maxLengthValidation(50000));

  async function onSave() {
    saveButtonState.onSave();
    const response = await updateItem(props.item.id, {
      ...props.item,
      name: itemName,
      description: itemDescription,
      supportAgent: {
        id: supportAgentId
      },
      internalNote,
    });
    saveButtonState.onSaveFinished();
    if (isValidationErrors(response)) {
      alert('Unexpected validation error: ' + JSON.stringify(response));
      console.error(response);
    } else {
      props.onUpdate(response);
    }
  }

  return (
    <Dialog fullScreen={isSmOrDown} open={true} onClose={saveButtonState.allowEdits ? props.onClose : undefined}
            maxWidth="md" fullWidth={true}>
      <DialogTitle>Item Info</DialogTitle>
      <DialogContent>
        <List style={{width: '100%'}}>
          <ListItem>
            <ListItemText primary="Item Id" secondary={props.item.itemCode}/>
          </ListItem>
          <ListItem>
            {isEnduser && <ListItemText primary="Name" secondary={props.item.name}/>}
            {!isEnduser && (
              <div style={{width: '100%'}}>
                <ListItemText primary="Name"/>
                <TextField
                  value={itemName}
                  onChange={event => setItemName(event.target.value)}
                  margin="none"
                  fullWidth={true}
                  disabled={!saveButtonState.allowEdits}
                  {...getHelperText(itemName, notBlankValidation, maxLengthValidation(1000))}
                />
              </div>
            )}
          </ListItem>
          <ListItem>
            <ListItemText primary="Client" secondary={props.item.client.name}/>
          </ListItem>
          <ListItem>
            {isEnduser && <ListItemText primary="Support Agent"
                                        secondary={`${props.item.supportAgent.firstName} ${props.item.supportAgent.lastName}`}/>}
            {!isEnduser && (
              <div style={{width: '100%'}}>
                <ListItemText primary="Support Agent"/>
                <TextField
                  value={supportAgentId}
                  onChange={event => setSupportAgentId(Number.parseInt(event.target.value, 10))}
                  margin="none"
                  select={true}
                  fullWidth={true}
                  disabled={!saveButtonState.allowEdits}
                >
                  {supportAgents.map(supportAgent => (
                    <MenuItem key={supportAgent.id} value={supportAgent.id}>
                      {userToString(supportAgent)}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
          </ListItem>
          <ItemDescription
            isEnduser={isEnduser}
            itemDescription={itemDescription}
            onChange={setItemDescription}
            disabled={!saveButtonState.allowEdits}
          />

          {!isEnduser &&
          <ListItem>
            <div style={{width: '100%'}}>
              <ListItemText
                primary={
                  <span>Internal note <Typography
                    variant="body2"
                    style={{color: 'red', display: 'inline'}}>(only visible for support agents)
                  </Typography>
                  </span>}
              />
              <TextField
                value={internalNote}
                fullWidth={true}
                multiline={true}
                {...getHelperText(internalNote, maxLengthValidation(50000))}
                onChange={event => setInternalNote(event.target.value)}>
              </TextField>
            </div>
          </ListItem>}

          <AttachmentList files={props.item.attachments}/>
        </List>
      </DialogContent>
      <DialogActions>
        <MuiThemeProvider theme={redButtonTheme}>
          <Button onClick={props.onClose} color="primary" disabled={!saveButtonState.allowEdits}
                  style={{marginRight: 4}}>
            <ClearIcon className={classes.buttonIcon}/>
            Close
          </Button>
        </MuiThemeProvider>
        {!isEnduser && (
          <MuiThemeProvider theme={greenButtonTheme}>
            <Button onClick={onSave} color="primary" disabled={disabled} style={{marginRight: 4}}>
              {saveButtonState.icon}
              Save
            </Button>
          </MuiThemeProvider>
        )}
      </DialogActions>
    </Dialog>
  );
}

function ItemDescription(props: {
  isEnduser: boolean,
  itemDescription: string,
  onChange(value: string): void,
  disabled: boolean
}) {
  if (props.isEnduser && !props.itemDescription) {
    return null;
  }
  return (
    <ListItem>
      {props.isEnduser &&
      <ListItemText primary="Description" secondary={<MultilineText text={props.itemDescription}/>}/>}
      {!props.isEnduser && (
        <div style={{width: '100%'}}>
          <ListItemText primary="Description"/>
          <TextField
            value={props.itemDescription}
            onChange={event => props.onChange(event.target.value)}
            margin="none"
            fullWidth={true}
            disabled={props.disabled}
            multiline={true}
            {...getHelperText(props.itemDescription, maxLengthValidation(50000))}
          />
        </div>
      )}
    </ListItem>
  );
}

function AttachmentList(props: {
  files: FileDto[]
}) {
  if (props.files.length <= 0) {
    return null;
  }

  return (
    <ListItem>
      <div>
        <ListItemText primary="Attachments"/>
        {props.files.map(file =>
          <Attachment
            key={file.id}
            attachment={file}
            withDeleteButton={false}
          />
        )}
      </div>
    </ListItem>
  );
}
