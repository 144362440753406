import * as React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';

export interface Props {
  open: boolean;
  onOk: React.ReactEventHandler<{}>;
  onDismiss: React.ReactEventHandler<{}>;
};

export class DirtyWarningDialog extends React.Component<Props> {

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onDismiss}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to discard your changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={this.props.onDismiss}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.props.onOk}
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
