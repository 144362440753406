import {myAxios} from '../util/myAxios';
import {SupportCase, SupportCaseRequest} from './supportCaseTypes';
import {Page, Pageable, toUrlParams} from '../query/pageable';
import {Call} from './callTypes';
import {isValidationErrorResponse, ValidationErrors} from './restApi';

export async function getSupportCase(supportCaseId: number): Promise<SupportCase> {
  const axios = await myAxios();
  const response = await axios.get(`/api/support_cases/${supportCaseId}`);
  return response.data;
}

export async function getSupportCases(
  params: { itemId?: number, supportAgentId?: number },
  pageable: Pageable
): Promise<Page<SupportCase>> {
  const itemIdParam = params.itemId ? `item_id=${params.itemId}&` : '';
  const supportAgentIdParam = params.supportAgentId ? `support_agent_id=${params.supportAgentId}&` : '';
  const pageableParam = toUrlParams(pageable);

  const requestParams = itemIdParam + supportAgentIdParam + pageableParam;

  const axios = await myAxios();
  const response = await axios.get(`/api/support_cases/?${requestParams}`);
  return response.data;
}

export async function getCallsForSupportCase(
  supportCaseId: number,
  pageable: Pageable
): Promise<Page<Call>> {
  const axios = await myAxios();
  const response = await axios.get(`/api/support_cases/${supportCaseId}/calls?${toUrlParams(pageable)}`);
  return response.data;
}

export function updateSupportCase(supportCaseId: number, supportCase: SupportCaseRequest): Promise<SupportCase | ValidationErrors> {
  return myAxios()
    .then(axios => axios.put(`/api/support_cases/${supportCaseId}`, supportCase))
    .then(
      response => response.data,
      error => {
        const response = error.response;
        if (isValidationErrorResponse(response)) {
          return response.data;
        } else {
          throw error;
        }
      });
}
