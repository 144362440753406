import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import {Theme} from '@material-ui/core';

/**
 * We need define all elements of the style type attribute in its own variable since typescript could not infer it
 * from "keyof typeof styles"
 */
export type CommonStyles =
  'padded'
  | 'withMargin'
  | 'withMobileBottomMargin'
  ;

export const commonStyles = (theme: Theme) => createStyles({
  padded: {
    padding: theme.spacing(),
  },
  withMargin: {
    margin: theme.spacing(),
  },
  withMobileBottomMargin: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 42
    },
  }
});

export const withCommonStyles = withStyles(commonStyles, {withTheme: true});
