import * as React from 'react';
import {ItemScanner} from "./ItemScanner";
import Typography from "@material-ui/core/Typography/Typography";
import Hidden from "@material-ui/core/Hidden/Hidden";
import EmbeddedPage from "../../../components/EmbeddedPage";
import {AppBarTopPadding} from "../../../components/AppBarTopPadding";
import {LandingPageAppBar} from "../LandingPageAppBar";
import {createStyles, makeStyles} from "@material-ui/styles";
import {useHasScannedItems} from "../../../util/hooks/useHasScannedItems";
import {useRouter} from "../../../util/hooks/useRouterHook";
import {routes} from "../../../router/MyRouter";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  headline: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    }
  },
  scanner: {
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    }
  }
}));

export function ItemScannerPage() {
  const {headline, scanner} = useStyles();
  const {history} = useRouter();

  const hasScannedItems = useHasScannedItems() === true;

  return (
    <EmbeddedPage>
      <LandingPageAppBar title="Scan code" previousLocation={hasScannedItems ? '/' : undefined}/>
      <AppBarTopPadding/>

      <Typography variant="h4" align="center" className={headline}>
        Scan QR-Code or enter Item-Id
      </Typography>

      {/* Should be above the scanner on large screens and under the scanner on small screens */}
      <Hidden smDown={true}>
        <InfoTextSubtitle/>
      </Hidden>

      <ItemScanner className={scanner} onItemScanned={item => history.push(routes.addItem(item.itemCode))}/>

      <Hidden mdUp={true}>
        <InfoTextSubtitle/>
      </Hidden>

      &nbsp;
    </EmbeddedPage>
  );
}

function InfoTextSubtitle() {
  return (
    <Typography variant="h5" align="center">
      The item will be added to your Home Screen
    </Typography>
  );
}
