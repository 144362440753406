import * as React from 'react';
import {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Call} from '../../../../api/callTypes';
import {Theme, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/styles';
import {getChatMessages} from '../../../../api/chatApi';
import {ChatMessage} from '../../../../api/chatTypes';
import {fullPageable} from '../../../../query/pageable';
import Spinner from '../../../../spinner/Spinner';
import {ChatContent} from '../../call/chat/ChatContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SupportCase} from '../../../../api/supportCaseTypes';
import DialogContent from '@material-ui/core/DialogContent';

export function ChatDialog(props: {
  supportCase: SupportCase | null,
  call: Call | null,
  onClose: () => void,
}) {
  const theme: Theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [chatMessages, setChatMessages] = useState(null as ChatMessage[] | null);
  useEffect(() => {
    if (props.call === null) {
      // Don't clear the `chatMessages` yet so they still show while the dialog fades out
      return;
    }
    setChatMessages(null);
    getChatMessages(props.call.id, {...fullPageable(), sort: {field: 'id', order: 'asc'}})
      .then(page => page.content)
      .then(setChatMessages);
  }, [props.call]);
  return <Dialog
    open={props.call !== null}
    maxWidth="sm"
    fullWidth={true}
    fullScreen={isSmOrDown}
    onClose={props.onClose}
  >
    {props.supportCase &&
    <DialogTitle>Chat of {props.supportCase.name}</DialogTitle>
    }
    <DialogContent
      onClick={props.onClose}
    >
      {chatMessages ?
        <ChatContent messages={chatMessages}/>
        : <Spinner/>
      }
    </DialogContent>
  </Dialog>;
}
