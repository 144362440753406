import * as React from "react";
import {ReactElement, useContext, useEffect} from "react";
import {NotificationHandlerContext} from "./NotificationHandler";

/** Listens for service worker messages and delegates them to [NotificationHandler] */
export const ServiceWorkerMessageHandler: React.FC = (): ReactElement | null => {
  const notificationHandler = useContext(NotificationHandlerContext);
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const listener = (event: MessageEvent) => {
        notificationHandler.onReceiveNotification(event.data, 'webpush');
      };
      navigator.serviceWorker.addEventListener('message', listener);
      return () => navigator.serviceWorker.removeEventListener('message', listener);
    }
    return undefined;
  }, []);
  return null;
};
