import * as React from 'react';
import {WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {ClientRequest, emptyClientRequest} from '../../../api/clientTypes';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import {IObservableObject, IObservableValue, observable} from 'mobx';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import * as clientApi from '../../../api/clientApi';
import {isValidationErrors, ValidationErrors} from '../../../api/restApi';
import {observer} from 'mobx-react';
import ClientForm from './ClientForm';
import {DialogWithDirtyWarning} from '../../../components/dialogs/DialogWithDirtyWarning';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {MyDialogStyles, myDialogStyles} from '../../../styles/myDialogStyles';
import {ClientIcon} from '../../../styles/icons';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Typography from '@material-ui/core/Typography/Typography';
import {SingleClickButton} from '../../../components/SingleClickButton';

interface Props {
  open: boolean;
  onClose: () => void;
}

type PropsWithStyle = Props & WithStyles<MyDialogStyles>;

@observer
export class NewClientDialog extends React.Component<PropsWithStyle> {

  @observable client: ClientRequest & IObservableObject = observable(emptyClientRequest());
  dirtyState: IObservableValue<boolean> = observable.box(false);
  inProgressCounter: IObservableValue<number> = observable.box(0);
  @observable validationErrors: ValidationErrors | undefined = undefined;

  constructor(props: PropsWithStyle) {
    super(props);
    this.init = this.init.bind(this);
  }

  /**
   * This Dialog component needs to remain mounted for nicer animations, so
   * we use this function triggered by `Dialog.onRendered` instead of `componentDidMount`
   * for initialization.
   */
  init() {
    this.client = observable(emptyClientRequest());
    this.dirtyState.set(false);
    this.validationErrors = undefined;
  }

  render() {
    return (
      <DialogWithDirtyWarning
        id="newClientDialog"
        open={this.props.open}
        onClose={this.props.onClose}
        dirtyState={this.dirtyState}
        fullWidth={true}
        maxWidth="md"
        onRendered={this.init}
        render={closeHandler => (
          <>
            <DialogTitle disableTypography={true}>
              <ClientIcon className={this.props.classes.titleIcon}/>
              <Typography variant="h6">New Client</Typography>
              <IconButton
                onClick={closeHandler}
                className={this.props.classes.closeButton}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ClientForm
                classes={this.props.classes}
                client={this.client}
                dirtyState={this.dirtyState}
                inProgressCounter={this.inProgressCounter}
                fieldErrors={this.validationErrors ? this.validationErrors.fieldErrors : []}
              />
            </DialogContent>
            <DialogActions>
              <SingleClickButton
                buttonProps={{
                  id: "newClientDialog_button_save",
                  color: "primary",
                  variant: "contained",
                  disabled: this.inProgressCounter.get() > 0,
                }}
                onSingleClick={() =>
                  clientApi.createClient(this.client).then(data => {
                    if (isValidationErrors(data)) {
                      this.validationErrors = data;
                    } else {
                      this.validationErrors = undefined;
                      this.props.onClose();
                    }
                  })
                }
              >
                Create
              </SingleClickButton>
            </DialogActions>
          </>
        )}
      />
    );
  }
}

export default withStyles(myDialogStyles)(NewClientDialog);
