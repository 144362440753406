import * as React from 'react';
import {CSSProperties, ReactNode} from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
}));

/**
 * Creates a horizontal scroll area for the given `children`.
 * The child elements need to use the style: `flex: '0 0 auto'`.
 * Uses the solution described here:
 * https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6
 */
export function HorizontalScroller(props: {
  children: ReactNode,
  className?: string,
  style?: CSSProperties,
}) {
  const classes = useStyles();
  return <div className={`${props.className || ''} ${classes.root}`} style={props.style}>
    {props.children}
  </div>;
}
