import * as React from "react";
import {ReactElement, ReactNode, useEffect, useState} from "react";
import {AccountDto} from "../api/userTypes";
import {getCurrentAccount} from "../keycloak";
import Spinner from "../spinner/Spinner";

export const AccountContext = React.createContext<AccountDto | null>(null);

export const AccountContextProvider: React.FC = (props: { children?: ReactNode }): ReactElement | null => {
  const [account, setAccount] = useState<AccountDto | null>(null);
  useEffect(() => {
    getCurrentAccount().then(setAccount);
  }, []);
  if (account == null) return <Spinner/>;
  else return (
    <AccountContext.Provider value={account}>
      {props.children}
    </AccountContext.Provider>);
};
