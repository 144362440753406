import * as React from 'react';
import {CSSProperties, MutableRefObject, useEffect, useMemo, useRef} from 'react';
import {usePrevious} from "../util/hooks/usePrevious";

export function Video(props: {
  stream?: MediaStream,
  audioEnabled: boolean,
  videoEnabled: boolean,
  className?: string,
  mirrored?: boolean,
  style?: CSSProperties,
  onClick?(): void,
  videoRef?(videoElement: HTMLVideoElement | null): void
}) {

  const {stream, audioEnabled, videoEnabled, className, onClick, mirrored} = props;
  const videoElement = useRef(null as HTMLVideoElement | null);
  const preStream = usePrevious(props.stream);
  useEffect(() => {
    if (videoElement != null && preStream != props.stream) {
      setSrcObjectWithChromeBugfix(videoElement.current, props.stream);
    }
  });

  const refCallback = useMemo(() => ((ref: HTMLVideoElement) => {
    setVideoElement(videoElement, ref, stream);
    if (props.videoRef) {
      props.videoRef(ref);
    }
  }), [props.videoRef, stream]);

  let style: CSSProperties = {};
  if (mirrored) {
    style = {
      transform: 'rotateY(180deg)'
    };
  }
  if (!(stream && videoEnabled)) {
    style = {display: 'none'};
  }
  return (
    <video
      style={{...style, ...(props.style || {})}}
      className={className}
      ref={refCallback}
      autoPlay={true}
      muted={!audioEnabled}
      onClick={onClick}
    />
  );
}

function setSrcObjectWithChromeBugfix(videoElement: HTMLVideoElement | null, stream?: MediaStream) {
  if (stream && videoElement) {
    videoElement.srcObject = stream || null;
    // Fix for Chrome-Bug
    // Sometimes, the video track switches from "live" to "ended" and then to "live" again.
    // This must not happen according to the documentation of readyState, but it does happen nevertheless.
    // This weird behavior causes the video to stay black, even though the track became "live" again.
    //
    // In case the video track ends, we set it again after 250ms. This fixed the black screen.
    if (stream.getTracks().length > 0) {
      stream.getTracks()[0].onended = () => {
        setTimeout(() => {
          if (videoElement) {
            // noinspection SillyAssignmentJS
            videoElement.srcObject = videoElement.srcObject;
          }
        }, 250);
      }
    }
  }
}

function setVideoElement(
  ref: MutableRefObject<HTMLVideoElement | null>,
  videoElement: HTMLVideoElement | null,
  stream?: MediaStream
) {
  ref.current = videoElement;
  setSrcObjectWithChromeBugfix(videoElement, stream);
}
