import * as React from 'react';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {CONTAINER_MAX_WIDTH} from '../../../styles/muiTheme';
import Typography from "@material-ui/core/Typography/Typography";
import {Subscribe} from "unstated";
import {CallStateContainer} from "./state/CallStateContainer";
import CountingClock from "../../../components/CountingClock";

type Props = {
  remoteUsername: string,
  userCount: number
};

type PropsWithStyle = Props & WithStyles<LocalStyles>;

export type LocalStyles = 'root' | 'content';

/**
 * This is `theme.palette.background.default` with additional alpha-value.
 * Using `opacity` has the problem that its content and children are also affected.
 * So we hard-code this color here, theme background does not change so often, I reckon.
 */
const OVERLAY_BACKGROUND_COLOR = '#30303099';

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = () => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: OVERLAY_BACKGROUND_COLOR,
    zIndex: 20,
  },
  content: {
    maxWidth: CONTAINER_MAX_WIDTH,
    margin: 'auto',
    position: 'relative',
    marginTop: '-1px',
    paddingTop: '1px',
    width: '100%'
  },
});

class CallHeader extends React.PureComponent<PropsWithStyle> {

  render() {
    const {classes, userCount, remoteUsername} = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {userCount <= 2 && <Typography style={({margin: 8})} variant="h5">{remoteUsername}</Typography>}
          {userCount <= 1 && <Typography style={({margin: 8})} variant="body1">Calling...</Typography>}
          {userCount > 1 && (
            <Subscribe to={[CallStateContainer]}>
              {(callStateContainer: CallStateContainer) => (
                <Typography style={({margin: 8})} variant="body1">
                  <CountingClock startTimestamp={callStateContainer.state.callStartTimestamp}/>
                </Typography>
              )}
            </Subscribe>
          )}
        </div>
      </div>
    );
  }

}

export default withStyles(localStyles)(CallHeader);
