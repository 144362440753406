import * as React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

export const MAX_IMAGE_FILE_SIZE = 5 * 1024 * 1024;

export function ImageTooLargeDialog(props: {
  open: boolean;
  onClose(): void;
}) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Image too large</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The image must not be bigger than 5 MB!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
