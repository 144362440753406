import {Config, getConfig} from "../api/configApi";
import * as notificationTokenApi from "../api/notificationTokenApi";
import {WebpushSubscription} from "../api/notificationTokenTypes";

/**
 * Tries to register webpush for this browser.
 * @returns A subscription when successful or `null` otherwise.
 */
export async function registerWebpush(serviceWorkerRegistration: ServiceWorkerRegistration): Promise<WebpushSubscription | null> {
  // Check is push API is supported
  if (!('PushManager' in window)) {
    console.warn('Push messaging isn\'t supported.');
    return null;
  }

  if (!('serviceWorker' in navigator)) {
    console.warn('ServiceWorker isn\'t supported.');
    return null;
  }

  let subscription;
  try {
    subscription = await serviceWorkerRegistration.pushManager.getSubscription();
  } catch (error) {
    console.warn('Error during getSubscription()', error);
    return null;
  }

  const config = await getConfig();

  // If this is the user's first visit we need to set up a subscription to push notifications
  if (!subscription) {
    return subscribe(config);
  }

  // We already have an subscription. Now we must validate, that the subscription can still be used by the server
  // If the server's public key did change, we must create a new subscription

  const currentPublicKey = new Uint8Array(subscription.options.applicationServerKey || new ArrayBuffer(0));
  if (arraysAreEqual(currentPublicKey, urlB64ToUint8Array(config.applicationServerKey))) {
    // The public key didn't change in the meanwhile, so we can just reuse the subscription.
    return sendSubscriptionToServer(subscription);
  }

  // We need a new subscription, because the public key did change
  console.log('Creating a new subscription, because the public key of the server did change!');
  await subscription.unsubscribe();
  return subscribe(config);
}

/**
 * Creates a new subscription by asking the user to allow push notifications.
 */
async function subscribe(config: Config): Promise<WebpushSubscription | null> {
  const registration = await navigator.serviceWorker.ready;

  try {
    const applicationServerKey = urlB64ToUint8Array(config.applicationServerKey);
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    });
    return sendSubscriptionToServer(subscription);
  } catch (error) {
    if (Notification.permission === 'denied') {
      console.warn('Permission for Notifications was denied');
      return null;
    } else {
      throw error;
    }
  }
}

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * Send the subscription data to our backend.
 */
async function sendSubscriptionToServer(subscription: PushSubscription): Promise<WebpushSubscription> {
  // Get public key and user auth from the subscription object
  const key = subscription.getKey ? subscription.getKey('p256dh') : '';
  const auth = subscription.getKey ? subscription.getKey('auth') : '';

  const currentSubscription = {
    endpoint: subscription.endpoint,
    key: key ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(key)))) : '',
    auth: auth ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(auth)))) : '',
  };
  await notificationTokenApi.registerWebpush(currentSubscription);
  return currentSubscription;
}

/**
 * Checks if the given arrays are equal.
 */
function arraysAreEqual(a: Uint8Array, b: Uint8Array) {
  if (a.length != b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] != b[i]) {
      return false;
    }
  }
  return true;
}
