import * as React from 'react';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import {Item} from "../../../api/itemTypes";
import {ItemOutlineIcon} from '../../../styles/icons';
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import {createStyles, makeStyles} from "@material-ui/styles";
import {useRouter} from "../../../util/hooks/useRouterHook";
import {routes} from "../../../router/MyRouter";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(),
    backgroundColor: theme.palette.background.paper,
  },
}));

export function ItemListElement(props: {
  item: Item;
}) {
  const {root} = useStyles();
  const {history, location} = useRouter();
  const onClick = () => history.push(routes.itemDetails(props.item.id) + location.search);
  return (
    <ListItem button={true} onClick={onClick} className={root}>
      <ListItemIcon>
        <ItemOutlineIcon/>
      </ListItemIcon>
      <ListItemText>
        {props.item.name}
      </ListItemText>
    </ListItem>
  );
}
