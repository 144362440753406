import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import {Address} from '../../../api/clientTypes';
import {IObservableObject, IObservableValue} from 'mobx';
import {observer} from 'mobx-react';
import Grid from '@material-ui/core/Grid/Grid';
import {FieldError} from '../../../api/restApi';
import {createPicker} from '../../../util/picker';
import {renderRemainingErrors, textFieldErrorProps} from '../../../util/formUtils';

interface Props {
  address: Address & IObservableObject;
  dirtyState: IObservableValue<boolean>;
  fieldErrorPrefix: string;
  fieldErrors: FieldError[];
}

@observer
export class AddressForm extends React.Component<Props> {
  render() {
    const errorPicker = createPicker(this.props.fieldErrors);
    return (
      <>
        <TextField
          id="addressForm_input_addressLine"
          label="Street name and number"
          value={this.props.address.addressLine}
          onChange={ev => {
            this.props.address.addressLine = ev.target.value;
            this.props.dirtyState.set(true);
          }}
          margin="dense"
          fullWidth={true}
          {...textFieldErrorProps(errorPicker.pick(e => e.field === `${this.props.fieldErrorPrefix}addressLine`))}
        />
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={3} zeroMinWidth={true}>
            <TextField
              id="addressForm_input_postalCode"
              label="Postal code"
              value={this.props.address.postalCode}
              onChange={ev => {
                this.props.address.postalCode = ev.target.value;
                this.props.dirtyState.set(true);
              }}
              margin="dense"
              fullWidth={true}
              {...textFieldErrorProps(errorPicker.pick(e => e.field === `${this.props.fieldErrorPrefix}postalCode`))}
            />
          </Grid>
          <Grid item={true} xs={true} zeroMinWidth={true}>
            <TextField
              id="addressForm_input_city"
              label="City"
              value={this.props.address.city}
              onChange={ev => {
                this.props.address.city = ev.target.value;
                this.props.dirtyState.set(true);
              }}
              margin="dense"
              fullWidth={true}
              {...textFieldErrorProps(errorPicker.pick(e => e.field === `${this.props.fieldErrorPrefix}city`))}
            />
          </Grid>
          <Grid item={true} xs={true} zeroMinWidth={true}>
            <TextField
              id="addressForm_input_country"
              label="Country"
              value={this.props.address.country}
              onChange={ev => {
                this.props.address.country = ev.target.value;
                this.props.dirtyState.set(true);
              }}
              margin="dense"
              fullWidth={true}
              {...textFieldErrorProps(errorPicker.pick(e => e.field === `${this.props.fieldErrorPrefix}country`))}
            />
          </Grid>
        </Grid>
        {renderRemainingErrors(errorPicker.remaining())}
      </>
    );
  }
}

export default AddressForm;
