import * as React from 'react';
import {useState} from 'react';
import {createStyles, makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import {CHAT_WINDOW_INPUT_HEIGHT} from "./ChatWindow";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import {grey} from "@material-ui/core/colors";
import {getHelperText, hasError} from "../../../../util/validation/helperTextUtil";
import {maxLengthValidation, notBlankValidation} from "../../../../util/validation/helperTextSuppliers";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    height: CHAT_WINDOW_INPUT_HEIGHT,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: grey[400],
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    height: 40,
    width: 'calc(100% - 16px - 46px)'
  },
  sendButton: {
    position: 'absolute',
    right: 5,
    bottom: 5,
    padding: 10
  }
}));

const EMOJI_MAPPING = {
  ':-(': '☹️', ':(': '☹️',
  ':-)': '🙂', ':)': '🙂',
  ':-|': '️😐', ':|': '️😐',
  ':-P': '😛', ':P': '😛',
  ';)': '😉', ';-)': '😉',
  ';-P': '😜', ';P': '😜',
  ':D': '😀', ':-D': '😀',
  ':O': '😮', ':-O': '😮', ':o': '😮', ':-o': '😮',
  ';(': '😢', ';-(': '😢',
  'chuck norris': '🤠', 'Chuck Norris': '🤠' // You must not write "chuck norris". It's for your own safety ;-)
};

export function ChatWindowInput(props: {
  onInput(text: string): void
}) {
  const [value, setValue] = useState('');
  const {root, textField, sendButton} = useStyles();

  function handleSend() {
    if (hasError(value, maxLengthValidation(5000), notBlankValidation)) {
      return;
    }
    props.onInput(value);
    setValue('');
  }

  return (
    <div className={root}>
      <TextField
        value={value}
        onChange={event => setValue(replaceEmojis(event.target.value))}
        className={textField}
        margin="dense"
        variant="outlined"
        placeholder="Type a message..."
        onKeyPress={event => {
          if (event.key === 'Enter' && value.length > 0) {
            handleSend();
            event.preventDefault();
          }
        }}
        {...getHelperText(value, maxLengthValidation(5000))}
      />
      <IconButton className={sendButton} onClick={handleSend}>
        <SendIcon/>
      </IconButton>
    </div>
  );
}

function replaceEmojis(text: string): string {
  let textWithEmojis = text;
  Object.keys(EMOJI_MAPPING).forEach(textEmoji => {
    textWithEmojis = textWithEmojis.replace(textEmoji, EMOJI_MAPPING[textEmoji]);
  });
  return textWithEmojis;
}
