import * as React from 'react';
import {ReactNode} from 'react';
import {StandardTextFieldProps} from '@material-ui/core/TextField/TextField';
import {FieldError} from '../api/restApi';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

export function renderErrors(errors: FieldError[]): ReactNode {
  return errors.map((e, i) => <React.Fragment key={i}>{i > 0 && <br/>}{e.defaultMessage}</React.Fragment>);
}

export function renderRemainingErrors(errors: FieldError[]): ReactNode {
  return errors.map((e, i) => (
    <FormHelperText key={i} error={true}>
      {e.field} {e.defaultMessage}
    </FormHelperText>));
}

export function textFieldErrorProps(errors: FieldError[]): Partial<StandardTextFieldProps> {
  return {
    error: errors.length > 0,
    helperText: renderErrors(errors),
  };
}
