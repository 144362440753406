import * as React from 'react';
import {observer} from 'mobx-react';
import {RouteComponentProps, withRouter} from 'react-router';
import {StyleRulesCallback, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import MyAppBar from '../MyAppBar';
import Grid from '@material-ui/core/Grid/Grid';
import {ItemDashboardColumn} from '../item/ItemDashboardColumn';
import {ClientDashboardColumn} from '../client/ClientDashboardColumn';
import {SupportAgentDashboardColumn} from '../supportAgent/SupportAgentDashboardColumn';
import {AccountDto} from '../../../api/userTypes';
import {IObservableValue, observable} from 'mobx';
import {LOGO, LOGO_APP_BAR_RIGHT} from "../../../branding/logo";
import {AppBarTopPadding} from "../../../components/AppBarTopPadding";
import {appendParametersToImageUrl} from "../../../api/restApi";
import {createStyles} from "@material-ui/styles";

type Props = RouteComponentProps<{}> & {
  account: AccountDto;
};

export type DashboardStyles =
  'toolbarIcon' |
  'fabButton' |
  'fabContainer' |
  'scrollContainer' |
  'card' |
  'cardContent' |
  'cardImageContainer' |
  'column' |
  'waves';

export const WAVES_HEIGHT = 85;

const localStyles: StyleRulesCallback<Theme, {}, DashboardStyles> = theme => createStyles({
  toolbarIcon: {
    marginRight: 16,
    fontSize: 40,
    verticalAlign: 'text-top',
  },
  fabContainer: {
    position: 'relative',
    height: '100%',
  },
  fabButton: {
    position: 'absolute',
    bottom: '-28px',
    right: theme.spacing(4),
  },
  scrollContainer: {
    overflowY: 'auto',
    height: '100%',
    padding: theme.spacing(),
    paddingTop: theme.spacing(4),
    '&:after': {
      content: '""',
      height: WAVES_HEIGHT,
      display: 'block',
    },
  },
  card: {
    marginBottom: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardContent: {
    padding: `${theme.spacing(2)}px !important`,
  },
  cardImageContainer: {
    float: 'left',
  },
  column: {
    height: '100%',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  waves: {
    display: theme.showWavesBackground ? 'block' : 'none',
    zIndex: 100,
    width: '100%',
    minWidth: '1000px',
    height: WAVES_HEIGHT,
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'fixed',
    bottom: 0,
    background: theme.showWavesBackground ? 'url(images/BG_waves.svg) no-repeat bottom' : '',
    backgroundSize: '100% 100%', // Needed for FF
  }
});

type PropsWithStyle = Props & WithStyles<DashboardStyles>;

@observer
class DashboardPage extends React.Component<PropsWithStyle> {

  private searchText: IObservableValue<string> = observable.box('');
  private clientUpdateCounter = observable.box(0);
  private supportAgentUpdateCounter = observable.box(0);

  constructor(props: PropsWithStyle) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className={this.props.classes.waves}/>
        <MyAppBar
          account={this.props.account}
          logo={LOGO}
          rightLogo={LOGO_APP_BAR_RIGHT}
          onSearch={(s: string) => this.searchText.set(s)}
        />
        <AppBarTopPadding style={{height: '100vh'}}>
          <Grid
            container={true}
            style={{
              height: '100%',
              paddingTop: 16,
            }}
          >
            <Grid
              className={this.props.classes.column}
              item={true}
              xs={4}
            >
              <ClientDashboardColumn
                classes={this.props.classes}
                history={this.props.history}
                searchText={this.searchText}
                clientUpdateCounter={this.clientUpdateCounter}
              />
            </Grid>
            <Grid
              className={this.props.classes.column}
              item={true}
              xs={4}
            >
              <SupportAgentDashboardColumn
                classes={this.props.classes}
                history={this.props.history}
                searchText={this.searchText}
                supportAgentUpdateCounter={this.supportAgentUpdateCounter}
              />
            </Grid>
            <Grid
              className={this.props.classes.column}
              item={true}
              xs={4}
            >
              <ItemDashboardColumn
                classes={this.props.classes}
                history={this.props.history}
                searchText={this.searchText}
                clientUpdateCounter={this.clientUpdateCounter}
                supportAgentUpdateCounter={this.supportAgentUpdateCounter}
              />
            </Grid>
          </Grid>
        </AppBarTopPadding>
      </div>
    );
  }
}

/**
 * The displayed image size on the cards is 100px x 100px.
 * So, we request 200x200 image size to make hi-res display users happy.
 */
export function applyCardImageParametersToImageUrl(url: string | null): string | null {
  if (url == null) return null;
  return appendParametersToImageUrl(url, {
    mode: 'fill',
    width: 200,
    height: 200,
  });
}

export default withRouter(withStyles(localStyles)(DashboardPage));
