import * as React from 'react';
import './spinner.css';

type Props = {
  classNameOuter?: string
  classNameInner?: string
  color?: string
};

export class Spinner extends React.Component<Props> {

  render() {
    return (
      <div className={`spinner${this.props.classNameOuter ? ` ${this.props.classNameOuter}` : ''}`}>
        <div className={`bounce1${this.props.classNameInner ? ` ${this.props.classNameInner}` : ''}`}
             style={{backgroundColor: this.props.color}}/>
        <div className={`bounce2${this.props.classNameInner ? ` ${this.props.classNameInner}` : ''}`}
             style={{backgroundColor: this.props.color}}/>
        <div className={`bounce3${this.props.classNameInner ? ` ${this.props.classNameInner}` : ''}`}
             style={{backgroundColor: this.props.color}}/>
      </div>
    );
  }
}

export default Spinner;
