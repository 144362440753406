import * as React from "react";
import {useEffect, useState} from "react";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {checkIfLoggedIn, login, logout} from "../keycloak";
import {useRouter} from "../util/hooks/useRouterHook";
import {routes} from "./MyRouter";

export function NotAllowedPage() {
  const [loggedIn, setLoggedIn] = useState(null as boolean | null);
  useEffect(() => {
    checkIfLoggedIn().then(setLoggedIn)
  });

  const {history} = useRouter();

  return (
    <Dialog open={true}>
      <DialogTitle>Not allowed!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are not allowed to access this page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id={'button_home'}
          variant="contained"
          color="primary"
          onClick={() => history.push(routes.home)}
        >
          Home
        </Button>
        <Button
          id={'button_' + (loggedIn ? 'logout' : 'login')}
          variant="contained"
          color="primary"
          onClick={loggedIn ? logout : login}
        >
          {loggedIn ? 'Logout' : 'Login'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
