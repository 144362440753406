import * as React from 'react';
import {WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import {computed, IObservableArray, IObservableObject, IObservableValue, observable} from 'mobx';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import * as supportAgentApi from '../../../api/supportAgentApi';
import {isValidationErrors, ValidationErrors} from '../../../api/restApi';
import {observer} from 'mobx-react';
import {User} from '../../../api/userTypes';
import {userToString} from '../../../components/Username';
import {UserForm} from './UserForm';
import {DialogWithDirtyWarning} from '../../../components/dialogs/DialogWithDirtyWarning';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {MyDialogStyles, myDialogStyles} from '../../../styles/myDialogStyles';
import {SupportAgentIcon} from '../../../styles/icons';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography/Typography';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import * as itemApi from '../../../api/itemApi';
import {fullPageable} from '../../../query/pageable';
import {Item} from '../../../api/itemTypes';
import TableBody from '@material-ui/core/TableBody/TableBody';
import {Spinner} from '../../../spinner/Spinner';
import {SingleClickButton} from '../../../components/SingleClickButton';
import {ButtonWithDialog} from '../../../components/ButtonWithDialog';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Button from '@material-ui/core/Button/Button';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import {ConfirmDeleteDialog} from '../../../components/dialogs/ConfirmDeleteDialog';
import {ReassignItemsDialog} from './ReassignItemsDialog';

interface Props {
  open: boolean;
  user: User & IObservableObject;
  onClose: () => void;
}

type PropsWithStyle = Props & WithStyles<MyDialogStyles>;

@observer
export class EditSupportAgentDialog extends React.Component<PropsWithStyle> {

  dirtyState: IObservableValue<boolean> = observable.box(false);
  @observable loading = true;
  inProgressCounter: IObservableValue<number> = observable.box(0);
  items: IObservableArray<Item> = observable.array();
  @observable validationErrors: ValidationErrors | undefined = undefined;

  constructor(props: PropsWithStyle) {
    super(props);
    this.init = this.init.bind(this);
    this.renderNeedToReassignItemsDialog = this.renderNeedToReassignItemsDialog.bind(this);
    this.renderDeleteConfirmationDialog = this.renderDeleteConfirmationDialog.bind(this);
    this.renderReassignDialog = this.renderReassignDialog.bind(this);
  }

  /**
   * This Dialog component needs to remain mounted for nicer animations, so
   * we use this function triggered by `Dialog.onRendered` instead of `componentDidMount`
   * for initialization.
   */
  init() {
    this.dirtyState.set(false);
    this.loading = true;
    this.items.replace([]);
    this.validationErrors = undefined;
    this.refreshItemsList();
  }

  @computed get visibleItems(): Item[] {
    if (this.props.user.reassignItemsToSupportAgentId) {
      return [];
    }
    return this.items;
  }

  refreshItemsList() {
    this.loading = true;
    itemApi.loadItems({supportAgentId: this.props.user.id}, fullPageable()).then(items => {
      this.items.replace(items.content);
      this.loading = false;
    });
  }

  render() {
    return (
      <DialogWithDirtyWarning
        id="editSupportAgentDialog"
        open={this.props.open}
        onClose={this.props.onClose}
        dirtyState={this.dirtyState}
        fullWidth={true}
        maxWidth="md"
        onRendered={this.init}
        render={closeHandler => (
          <>
            <DialogTitle disableTypography={true}>
              <SupportAgentIcon className={this.props.classes.titleIcon}/>
              <Typography variant="h6">{userToString(this.props.user)}</Typography>
              <Typography variant="caption">Support Agent</Typography>
              <IconButton
                onClick={closeHandler}
                className={this.props.classes.closeButton}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <UserForm
                classes={this.props.classes}
                user={this.props.user}
                existingUser={this.props.user}
                dirtyState={this.dirtyState}
                inProgressCounter={this.inProgressCounter}
                fieldErrors={this.validationErrors ? this.validationErrors.fieldErrors : []}
              />
              <Typography variant="caption">
                Assigned Items
              </Typography>
              {this.loading
                ? <Spinner/>
                : <ItemTable
                  items={this.visibleItems}
                />}
            </DialogContent>
            <DialogActions>
              {this.visibleItems.length > 0 &&
              <ButtonWithDialog
                  color="default"
                  variant="contained"
                  renderDialog={this.renderReassignDialog}
              >
                  Reassign items
              </ButtonWithDialog>
              }
              <ButtonWithDialog
                id="editSupportAgentDialog_button_delete"
                color="default"
                variant="contained"
                renderDialog={this.visibleItems.length === 0
                  ? this.renderDeleteConfirmationDialog
                  : this.renderNeedToReassignItemsDialog}
              >
                Delete
              </ButtonWithDialog>
              <SingleClickButton
                buttonProps={{
                  id: "editSupportAgentDialog_button_save",
                  color: "primary",
                  variant: "contained",
                  disabled: this.inProgressCounter.get() > 0,
                }}
                onSingleClick={() =>
                  supportAgentApi.update(this.props.user).then(data => {
                    if (isValidationErrors(data)) {
                      this.validationErrors = data;
                    } else {
                      this.validationErrors = undefined;
                      this.props.onClose();
                    }
                  })
                }
              >
                Save
              </SingleClickButton>
            </DialogActions>
          </>
        )}
      />
    );
  }

  private renderNeedToReassignItemsDialog(dialogOpen: IObservableValue<boolean>): JSX.Element {
    return (
      <Dialog
        open={dialogOpen.get()}
        onClose={() => dialogOpen.set(false)}
      >
        <DialogContent>
          <DialogContentText>
            You need to reassign the items of this support agent before it may be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => dialogOpen.set(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private renderDeleteConfirmationDialog(dialogOpen: IObservableValue<boolean>): JSX.Element {
    return (
      <ConfirmDeleteDialog
        text="Are you sure you want to delete this agent?"
        open={dialogOpen.get()}
        onOk={() => {
          supportAgentApi.remove(this.props.user.id, {
            reassignItemsToSupportAgentId: this.props.user.reassignItemsToSupportAgentId || undefined,
          }).then(() => {
            this.props.onClose();
          });
        }
        }
        onDismiss={() => dialogOpen.set(false)}
      />
    );
  }

  private renderReassignDialog(dialogOpen: IObservableValue<boolean>): JSX.Element {
    return (
      <ReassignItemsDialog
        currentSupportAgent={this.props.user}
        open={dialogOpen.get()}
        onClose={(reassignItemsToSupportAgentId) => {
          dialogOpen.set(false);
          this.dirtyState.set(true);
          this.props.user.reassignItemsToSupportAgentId = reassignItemsToSupportAgentId;
        }}
        classes={this.props.classes}
      />
    );
  }
}

function ItemTable(props: {
  items: Item[];
}): JSX.Element {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Item ID</TableCell>
          <TableCell>Item Name</TableCell>
          <TableCell>Client Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.items.map(item => (
          <TableRow key={item.id}>
            <TableCell>{item.itemCode}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.client.name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default withStyles(myDialogStyles)(EditSupportAgentDialog);
