import {EventHandler} from './util/typedEvent';
import log from "./util/myLog";
import {BaseNotification} from "./notifications/BaseNotification";

const logger = log.getLogger('AndroidApi');

/**
 * Defines the API provided by the Android app.
 */
interface AndroidApi {
  getPushToken: () => string;
}

/**
 * @returns whether this webapp is running inside the Android App.
 */
export function isAndroid(): boolean {
  return getAndroidApi() !== undefined;
}

/**
 * @returns The provided {AndroidApi} when running in the Android App or `undefined` otherwise.
 */
export function getAndroidApi(): AndroidApi | undefined {
  return (window as any).Android;
}

/**
 * Event handler which will call all registered listeners when the wrapper App is shown.
 */
export const onStartHandler = new EventHandler<{}>();

/**
 * Event handler which will call all registered listeners when the wrapper App is hidden.
 */
export const onStopHandler = new EventHandler<{}>();

/**
 * Event handler which will call all registered listeners when the wrapper App is hidden.
 */
export const onNotification = new EventHandler<BaseNotification>();

export let currentShownState = true;

/**
 * (internal) To be called by the android wrapper App when the App is shown.
 */
(window as any).androidOnShow = function (): void {
  logger.info("AndroidApi: androidOnShow");
  currentShownState = true;
  onStartHandler.emit({});
};

/**
 * (internal) To be called by the android wrapper App when the App is hidden.
 */
(window as any).androidOnHide = function (): void {
  logger.info("AndroidApi: androidOnHide");
  currentShownState = false;
  onStopHandler.emit({});
};

/**
 * (internal) To be called by the android wrapper App when a push notification was received.
 */
(window as any).androidOnNotification = function (notification: BaseNotification): void {
  logger.info(`AndroidApi: androidOnNotifcation, type: ${notification.type}`);
  onNotification.emit(notification);
};
