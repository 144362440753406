/**
 * Implements the Spreed Channeling API
 *
 * https://github.com/strukturag/spreed-webrtc/blob/master/doc/CHANNELING-API.txt
 */

import log from '../myLog';

export const logger = log.getLogger('SpreedChannelingApi');

interface ReceivedDoc<T> {
  From: string;
  To: string;
  Data: T & { Type: string };
}

export type SelfDoc = ReceivedDoc<{
  Type: 'Self';
  Id: string;
  Sid: string;
  Userid: string;
  Suserid: string;
  Token: string;
}>;

export type WelcomeDoc = ReceivedDoc<{
  Type: 'Welcome';
  Room: {
    Name: string;
  };
  Users: UserDoc[];
}>;

export interface UserDoc {
  Id: string;
  Userid: string;
  Ua: string;
}

export type JoinedDoc = ReceivedDoc<{
  Type: 'Joined';
  Id: string;
  Userid: string;
  Ua: string;
}>;

export type LeftDoc = ReceivedDoc<{
  Type: 'Left';
  Id: string;
}>;

export type ByeDoc = ReceivedDoc<{
  Type: 'Bye';
  Bye: {
    Type: 'Bye',
    Bye: {}
  }
}>;

export type OfferDoc = ReceivedDoc<{
  Type: 'Offer',
  Offer: RTCSessionDescription,
}>;

export type AnswerDoc = ReceivedDoc<{
  Type: 'Answer',
  Answer: RTCSessionDescription,
}>;

export type CandidateDoc = ReceivedDoc<{
  Type: 'Candidate',
  Candidate: RTCIceCandidate,
}>;

export interface SpreedListener {
  onSelf(doc: SelfDoc): void;

  onWelcome(doc: WelcomeDoc): void;

  onJoined(doc: JoinedDoc): void;

  onLeft(doc: LeftDoc): void;

  onBye(doc: ByeDoc): void;

  onOffer(doc: OfferDoc): void;

  onAnswer(doc: AnswerDoc): void;

  onCandidate(doc: CandidateDoc): void;
}

export function addSpreedListener(ws: WebSocket, listener: SpreedListener): void {
  ws.addEventListener('message', (message: MessageEvent) => {
      const rawData = JSON.parse(message.data);
      const type: string = rawData.Data.Type;
      logger.debug(`Spreed message received: ${message.data}`);

      if (type === 'Self') {
        listener.onSelf(rawData as SelfDoc);
      } else if (type === 'Welcome') {
        listener.onWelcome(rawData as WelcomeDoc);
      } else if (type === 'Joined') {
        listener.onJoined(rawData as JoinedDoc);
      } else if (type === 'Left') {
        listener.onLeft(rawData as LeftDoc);
      } else if (type === 'Bye') {
        listener.onBye(rawData as ByeDoc);
      } else if (type === 'Offer') {
        listener.onOffer(rawData as OfferDoc);
      } else if (type === 'Answer') {
        listener.onAnswer(rawData as AnswerDoc);
      } else if (type === 'Candidate') {
        listener.onCandidate(rawData as CandidateDoc);
      } else {
        logger.error(`Unknown spreed message: ${message.data}`);
      }
    }
  );
}

export function createHelloRequest(roomName: string, userAgent: string) {
  return JSON.stringify({
    'Type': 'Hello',
    'Hello': {
      'Name': roomName,
      'Ua': userAgent,
    }
  });
}

export function createByeRequest(to: string) {
  return JSON.stringify({
    'Type': 'Bye',
    'Bye': {
      'To': to,
      'Type': 'Bye',
      'Bye': {},
    }
  });
}

export function createAuthenticationRequest(userId: string, nonce: string) {
  return JSON.stringify({
    'Type': 'Authentication',
    'Authentication': {
      'Authentication': {
        'Userid': userId,
        'Nonce': nonce,
      }
    }
  });
}

export function createAnswerRequest(to: string, data: RTCSessionDescriptionInit) {
  return JSON.stringify({
    'Type': 'Answer',
    'Answer': {
      'To': to,
      'Type': 'Answer',
      'Answer': data,
    },
  });
}

export function createOfferRequest(to: string, data: RTCSessionDescriptionInit) {
  return JSON.stringify({
    'Type': 'Offer',
    'Offer': {
      'To': to,
      'Type': 'Offer',
      'Offer': data,
    },
  });
}

export function createCandidateRequest(to: string, data: RTCIceCandidate) {
  return JSON.stringify({
    'Type': 'Candidate',
    'Candidate': {
      'To': to,
      'Type': 'Candidate',
      'Candidate': data,
    },
  });
}
