import * as React from 'react';
import {grey} from "@material-ui/core/colors";
import {StyleRulesCallback, Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {CONTAINER_MAX_WIDTH} from "../styles/muiTheme";
import {InvitationCreatedHandler} from "./InvitationCreatedHandler";

type Props = WithStyles<LocalStyles>;

export type LocalStyles = 'root' | 'content';

const localStyles: StyleRulesCallback<Theme, {}, LocalStyles> = theme => ({
  root: {
    backgroundImage: 'url("/images/landing-page-background.png")',
    backgroundAttachment: 'fixed',
  },
  content: {
    maxWidth: CONTAINER_MAX_WIDTH,
    minHeight: '100vh',
    margin: 'auto',
    backgroundColor: grey[200],
  },
});

class EmbeddedPage extends React.PureComponent<Props> {

  render() {
    const {classes, children} = this.props;
    return (
      <div className={classes.root}>

        {/* Used to display incoming call invitations */}
        <InvitationCreatedHandler/>

        <div className={classes.content}>
          {children}
        </div>
      </div>
    );
  }
}

export default withStyles(localStyles)(EmbeddedPage)
