import * as React from "react";
import {useRef, useState} from "react";
import {CallStateContainer} from "./state/CallStateContainer";
import {Subscribe} from "unstated";
import {FittingCanvas} from "../../../components/drawing/FittingCanvas";
import {makeStyles} from "@material-ui/styles";
import {useFingerOrMouseMoveEffect} from "../../../util/hooks/useFingerOrMouseMoveEffect";
import {DrawingAndMarkerStateContainer} from "./state/DrawingAndMarkerStateContainer";

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    zIndex: 6,
  },
});

export function MarkerCanvas(props: {
  video: HTMLVideoElement | null,
  mirrored: boolean
}) {
  const classes = useStyles();
  const [canvasRef, setCanvasRef] = useState(null as HTMLCanvasElement | null);
  const callStateContainerRef = useRef(null as CallStateContainer | null);
  const drawingAndMarkerStateContainerRef = useRef(null as DrawingAndMarkerStateContainer | null);

  useFingerOrMouseMoveEffect({
    element: canvasRef,
    onMove: (x, y) => {
      if (callStateContainerRef.current && canvasRef) {
        callStateContainerRef.current.setMarker((props.mirrored ? canvasRef.width - x : x) / canvasRef.width, y / canvasRef.height);
      }
    }
  }, [canvasRef, callStateContainerRef.current, drawingAndMarkerStateContainerRef.current, props.mirrored]);

  return (
    <Subscribe to={[CallStateContainer, DrawingAndMarkerStateContainer]}>
      {(callStateContainer: CallStateContainer, drawingAndMarkerStateContainer: DrawingAndMarkerStateContainer) => {
        callStateContainerRef.current = callStateContainer;
        drawingAndMarkerStateContainerRef.current = drawingAndMarkerStateContainer;

        if (props.video && canvasRef) {
          const size = Math.min(props.video.clientHeight, props.video.clientWidth) / 15;
          drawCircles(canvasRef, size, Array.from(drawingAndMarkerStateContainer.state.markers.values()));
        }

        return (
          <div className={classes.root}>
            <FittingCanvas
              mirrored={props.mirrored}
              videoOrImage={() => props.video}
              canvasRef={setCanvasRef}
              onCanvasResize={() => {
                if (props.video && canvasRef) {
                  const size = Math.min(props.video.clientHeight, props.video.clientWidth) / 15;
                  drawCircles(canvasRef, size, Array.from(drawingAndMarkerStateContainer.state.markers.values()));
                }
              }}
            />
          </div>
        );
      }}
    </Subscribe>
  );
}

/**
 * Draws the markers inside the given canvas.
 */
function drawCircles(
  canvas: HTMLCanvasElement,
  size: number,
  markers: Array<{ x: number, y: number, color: string }>
) {
  const context = canvas.getContext('2d')!!;
  context.clearRect(0, 0, canvas.width, canvas.height);

  for (let marker of markers) {
    context.beginPath();
    context.lineWidth = 5;
    context.strokeStyle = marker.color;
    context.arc(Math.round(marker.x * canvas.width), Math.round(marker.y * canvas.height), size, 0, 2 * Math.PI);
    context.stroke();
  }
}
