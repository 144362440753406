import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import {Fab, WithStyles} from '@material-ui/core';
import {Item} from '../../../api/itemTypes';
import Typography from '@material-ui/core/Typography/Typography';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import AppBar from '@material-ui/core/AppBar/AppBar';
import {observer} from 'mobx-react';
import {IObservableValue, observable, observe} from 'mobx';
import * as itemApi from '../../../api/itemApi';
import {Pageable} from '../../../query/pageable';
import {History} from 'history';
import EditItemDialog from './EditItemDialog';
import {DashboardStyles} from '../dashboard/DashboardPage';
import {ItemOutlineIcon} from '../../../styles/icons';
import NewItemDialog from './NewItemDialog';
import {TransitionGroup} from 'react-transition-group';
import {cardAnimationProps, withTransition} from '../../../components/transitions';
import {ItemCard} from './ItemCard';
import {AppBarTopPadding} from "../../../components/AppBarTopPadding";
import Spinner from "../../../spinner/Spinner";
import {Waypoint} from "react-waypoint";

interface Props {
  history: History;
  searchText: IObservableValue<string>;
  clientUpdateCounter: IObservableValue<number>;
  supportAgentUpdateCounter: IObservableValue<number>;
}

type PropsWithStyle = Props & WithStyles<DashboardStyles>;

@observer
export class ItemDashboardColumn extends React.Component<PropsWithStyle> {

  @observable items: Item[] = [];
  @observable editDialogOpen: IObservableValue<boolean> = observable.box(false);
  @observable newDialogOpen: IObservableValue<boolean> = observable.box(false);
  @observable selectedItemId: number = -1;
  @observable lastPage: IObservableValue<boolean> = observable.box(true);
  @observable pendingRequest = false;
  currentPageable = this.initialPageable();
  requestCounter = 0;

  private closeActions: (Array<() => void>) = [];

  componentDidMount() {
    this.closeActions.push(
      observe(
        this.props.searchText,
        () => this.refreshItems()));
    this.closeActions.push(
      observe(
        this.props.clientUpdateCounter,
        () => this.refreshItems()));
    this.closeActions.push(
      observe(
        this.props.supportAgentUpdateCounter,
        () => this.refreshItems()));
    this.refreshItems();
  }

  componentWillUnmount() {
    this.closeActions.forEach(f => f());
  }

  initialPageable(): Pageable {
    return {
      page: 0,
      size: 20,
    };
  }

  refreshItems(): Promise<void> {
    this.pendingRequest = true;
    this.currentPageable = this.initialPageable();
    return itemApi.loadItems({searchText: this.props.searchText.get()}, this.currentPageable).then(page => {
      this.requestCounter += 1;
      this.items = page.content;
      this.lastPage.set(page.last);
      this.pendingRequest = false;
    });
  }

  loadNextPage() {
    this.pendingRequest = true;
    this.currentPageable = {...this.currentPageable, page: this.currentPageable.page!! + 1};
    itemApi.loadItems({searchText: this.props.searchText.get()}, this.currentPageable).then(page => {
      this.requestCounter += 1;
      this.items = this.items.concat(page.content);
      this.lastPage.set(page.last);
      this.pendingRequest = false;
    });
  }

  render() {
    return (
      <div className={this.props.classes.fabContainer}>
        {(this.selectedItemId > 0) &&
        <EditItemDialog
          open={this.editDialogOpen.get()}
          itemId={this.selectedItemId}
          onClose={() => {
            this.editDialogOpen.set(false);
            this.refreshItems();
          }}
        />
        }
        <NewItemDialog
          open={this.newDialogOpen.get()}
          onClose={() => {
            this.newDialogOpen.set(false);
            this.refreshItems();
          }}
        />
        <AppBar
          position="absolute"
          color="default"
        >
          <Toolbar>
            <Typography variant="h6">
              <ItemOutlineIcon className={this.props.classes.toolbarIcon}/>
              Items
            </Typography>
          </Toolbar>
          <Fab
            id="button_add_item"
            className={this.props.classes.fabButton}
            color="secondary"
            onClick={() => this.newDialogOpen.set(true)}
          >
            <AddIcon color="action"/>
          </Fab>
        </AppBar>
        <AppBarTopPadding style={{height: '100%'}}>
          <div className={this.props.classes.scrollContainer}>
            <TransitionGroup>
              {this.items.map((item, itemIndex) =>
                withTransition({
                  key: item.id,
                  ...cardAnimationProps(190),
                  children: (style, transitionState) =>
                    <>
                      <ItemCard
                        item={item}
                        style={style}
                        transitionState={transitionState}
                        classes={this.props.classes}
                        onClick={() => {
                          this.selectedItemId = item.id;
                          this.editDialogOpen.set(true);
                        }}
                      />
                      {transitionState == "entered" && itemIndex === this.items.length - 1 && !this.lastPage.get() && !this.pendingRequest &&
                      <Waypoint
                        key={this.requestCounter}
                        onEnter={() => this.loadNextPage()}
                      />
                      }
                    </>
                }))
              }
            </TransitionGroup>
            {this.pendingRequest && <Spinner/>}
          </div>
        </AppBarTopPadding>
      </div>
    );
  }
}
