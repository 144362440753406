import * as React from 'react';
import {useEffect, useState} from 'react';
import {AccountDto} from "../../api/userTypes";
import ContactsPage from "./contacts/ContactsPage";
import LandingPageBottomNavigation from "./LandingPageBottomNavigation";
import {parse, stringify} from "querystring";
import AllItemsPage from "./items/AllItemsPage";
import AssignedItemsPage from "./items/AssignedItemsPage";
import EmbeddedPage from "../../components/EmbeddedPage";
import {AppBarTopPadding} from "../../components/AppBarTopPadding";
import {LandingPageAppBar} from "./LandingPageAppBar";
import * as H from "history";
import {useRouter} from "../../util/hooks/useRouterHook";
import {animated, useTransition} from 'react-spring'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export enum LandingPageTab {
  AssignedItems = 'assigned-items',
  AllItems = 'all-items',
  Contacts = 'contacts'
}

// We need the order of tabs to calculate the direction of the animation
const tabOrder = [
  LandingPageTab.AssignedItems,
  LandingPageTab.AllItems,
  LandingPageTab.Contacts
];

const upTransform = {
  from: {opacity: 0, transform: 'translate3d(0,10%,0)'},
  enter: {opacity: 1, transform: 'translate3d(0,0,0)'},
  leave: {opacity: 0, transform: 'translate3d(0,-10%,0)'},
};

function getLeftTransform(weak: boolean) {
  return {
    from: {opacity: 0, transform: `translate3d(-${weak ? '2' : '100'}%,0,0)`},
    enter: {opacity: 1, transform: 'translate3d(0%,0,0)'},
    leave: {opacity: 0, transform: `translate3d(${weak ? '2' : '100'}%,0,0)`},
  };
}

function getRightTransform(weak: boolean) {
  return {
    from: {opacity: 0, transform: `translate3d(${weak ? '2' : '100'}%,0,0)`},
    enter: {opacity: 1, transform: 'translate3d(0%,0,0)'},
    leave: {opacity: 0, transform: `translate3d(-${weak ? '2' : '100'}%,0,0)`},
  };
}

export function LandingPage(props: {
  account: AccountDto
}) {
  const [currentTab, setCurrentTab] = useState(null as unknown as LandingPageTab);
  const [direction, setDirection] = useState('up' as 'left' | 'right' | 'up');
  const {location, history} = useRouter();

  const theme: Theme = useTheme();
  const weakTransform = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    let tab = parse(location.search.replace('?', '')).tab;
    tab = Object.keys(LandingPageTab).map(key => LandingPageTab[key]).filter(value => value == tab).pop();

    if (currentTab !== tab && currentTab && tab) {
      const prevPos = tabOrder.indexOf(currentTab);
      const nextPos = tabOrder.indexOf(tab as LandingPageTab);
      setDirection(prevPos > nextPos ? 'left' : 'right');
    }

    setCurrentTab(tab as LandingPageTab || LandingPageTab.AssignedItems);
  });

  let transform = null;
  switch (direction) {
    case 'right':
      transform = getRightTransform(weakTransform);
      break;
    case 'left':
      transform = getLeftTransform(weakTransform);
      break;
    default:
      transform = upTransform;
      break;
  }

  const tabTransitions = useTransition(currentTab, null, transform);

  return (
    <EmbeddedPage>
      <LandingPageAppBar title="Home"/>
      <AppBarTopPadding/>

      {/* We need an absolute div inside of a relative div, otherwise the transition will not work properly
        * as it requires the tabs to be above each other. To not break scrolling, the outer relative div needs
        * a fixed height and not 100%. */}
      <div style={{position: 'relative', width: '100%', height: 'calc(100vh - 64px)'}}>
        {tabTransitions.map(transition => (
          <div key={transition.key} style={{position: 'absolute', width: '100%', height: '100%'}}>
            <animated.div style={{...transition.props, height: '100%', overflowY: 'auto'}}>
              {transition.item == LandingPageTab.AssignedItems && <AssignedItemsPage account={props.account}/>}
              {transition.item == LandingPageTab.AllItems && <AllItemsPage account={props.account}/>}
              {transition.item == LandingPageTab.Contacts && <ContactsPage account={props.account}/>}
            </animated.div>
          </div>
        ))}
      </div>

      <LandingPageBottomNavigation
        currentTab={currentTab}
        onClick={tab => updateUrl(tab, location, history)}
      />
    </EmbeddedPage>
  );
}

function updateUrl(tab: LandingPageTab, location: H.Location<any>, history: H.History<any>) {
  const query = parse(location.search.replace('?', ''));
  query.tab = tab;
  history.push({search: history.location.search = '?' + stringify(query)});
}
