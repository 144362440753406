import {myAxios} from '../util/myAxios';
import {Page, Pageable, toUrlParams} from "../query/pageable";
import {ChatMessage} from "./chatTypes";

export async function getChatMessages(callId: number, pageable: Pageable): Promise<Page<ChatMessage>> {
  const axios = await myAxios();
  const response = await axios.get(`/api/calls/${callId}/chat?${toUrlParams(pageable)}`);
  return response.data;
}

export async function postChatMessage(callId: number, text: string): Promise<ChatMessage> {
  const axios = await myAxios();
  const response = await axios.post(`/api/calls/${callId}/chat`, {body: text});
  return response.data;
}
