import axios from 'axios';

let eTag: string | undefined;

export async function init() {
  eTag = await fetchETag();
}

export async function needsUpdate(): Promise<boolean> {
  if (eTag === undefined) {
    console.warn('No ETag found, must call init on webappUpdateChecker first');
    return Promise.resolve(false);
  }
  return fetchETag().then(newETag => (newETag !== eTag));
}

/** @returns a promise with the ETag of the index resource (should be the index.html file) */
async function fetchETag(): Promise<string | undefined> {
  return axios.head(
    '/',
    {
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    .then(response => response.headers.etag as string)
    .catch(e => {
      console.warn("WebappUpdateChecker failed to fetch index e-tag:", e);
      return undefined;
    });
}
