import * as React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

export interface Props {
  open: boolean;

  onClose(): void;
}

export class MalformedImageDialog extends React.Component<Props> {

  render() {
    const {open, onClose} = this.props;
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Malformed image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your provided image has an invalid format!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
