import * as React from 'react';
import {SupportCase} from "../../../api/supportCaseTypes";
import {useCurrentAccountHasRole} from "../../../util/hooks/useCurrentAccount";
import {ListItem, Theme} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import {createStyles, makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(),
    backgroundColor: theme.palette.background.paper,
  },
}));

export function SupportCaseListItem(props: {
  supportCase: SupportCase;
  onClick(): void;
}) {
  const {supportCase} = props;
  const {root} = useStyles();
  const isEnduser = useCurrentAccountHasRole('END_USER') === true;
  const missedCalls = isEnduser ? supportCase.missedSupportAgentToClientCallCount : supportCase.missedClientToSupportAgentCallCount;

  return (
    <ListItem className={root} button={true} onClick={props.onClick}>
      <ListItemText
        primary={supportCase.name}
        secondary={missedCalls > 0 ? `${missedCalls} missed call${missedCalls > 1 ? 's' : ''}` : supportCase.status}
        secondaryTypographyProps={missedCalls > 0 ? {color: 'error'} : undefined}
      />
    </ListItem>
  );
}
