import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Screenshot} from '../../../../api/callTypes';
import {Theme, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';
import {useObjectUrl} from "../../../../util/hooks/useObjectUrl";

const useStyles = makeStyles({
  image: {
    width: '100%',
    maxHeight: '100vh',
    margin: 'auto',
  },
});

export function ScreenshotDialog(props: {
  screenshot: Screenshot | null,
  onClose: () => void,
}) {
  const theme: Theme = useTheme();
  const classes = useStyles();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down('sm'));
  const screenshotObjectUrl = useObjectUrl(props.screenshot && props.screenshot._links.image);
  return <Dialog
    open={props.screenshot !== null}
    maxWidth="md"
    fullWidth={true}
    fullScreen={isSmOrDown}
    onClose={props.onClose}
  >
    <div>
      {props.screenshot &&
      <img
          alt={`Screenshot ${props.screenshot.id}`}
          className={classes.image}
          src={screenshotObjectUrl}
          onClick={props.onClose}
      />
      }
    </div>
  </Dialog>;
}
