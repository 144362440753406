import * as React from 'react';
import createMuiTheme, {ThemeOptions} from '@material-ui/core/styles/createMuiTheme';
import {green, grey, red} from '@material-ui/core/colors';
import {
  GRADIENT_1,
  GRADIENT_2,
  PRIMARY_COLOR,
  PRIMARY_COLOR_CONTRAST_TEXT,
  SECONDARY_COLOR,
  SECONDARY_COLOR_CONTRAST_TEXT,
  SHOW_WAVES_BACKGROUND,
  TEXT_COLOR
} from "../branding/layout";
import {PaletteColorOptions} from "@material-ui/core/styles/createPalette";

/** Augment Theme with our custom properties as described here:
 * https://material-ui.com/guides/typescript/#customization-of-theme
 */
declare module '@material-ui/core/styles/createMuiTheme' {

  interface Theme {
    showWavesBackground?: boolean,
    imagePlaceholderBackground: React.CSSProperties['background'],
  }

  interface ThemeOptions {
    showWavesBackground?: boolean,
    imagePlaceholderBackground: React.CSSProperties['background'],
  }
}

export const PLACEHOLDER_GREY = grey[500];

export const CONTAINER_MAX_WIDTH = 1280;

/**
 * The theme for all but the admin pages, i.e. the support agent & end user area
 */
export const defaultTheme = createMuiTheme({
  showWavesBackground: false,
  imagePlaceholderBackground: `linear-gradient(${GRADIENT_1}, ${GRADIENT_2})`,
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: PRIMARY_COLOR_CONTRAST_TEXT
    },
    secondary: {
      main: SECONDARY_COLOR,
      contrastText: SECONDARY_COLOR_CONTRAST_TEXT
    },
    background: {
      default: grey[200],
    },
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        '&$selected': {
          color: GRADIENT_2,
        }
      },
    },
  },
});

/** The dark theme used for call pages */
export const callTheme = createMuiTheme({
  imagePlaceholderBackground: `linear-gradient(${GRADIENT_1}, ${GRADIENT_2})`,
  palette: {
    type: 'dark',
  },
});

export const adminThemeOptions: ThemeOptions = {
  showWavesBackground: SHOW_WAVES_BACKGROUND,
  imagePlaceholderBackground: `linear-gradient(${GRADIENT_1}, ${GRADIENT_2})`,
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: PRIMARY_COLOR_CONTRAST_TEXT,
    },
    secondary: {
      main: SECONDARY_COLOR,
      contrastText: SECONDARY_COLOR_CONTRAST_TEXT
    },
    text: {
      primary: TEXT_COLOR,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiDialogActions: {
      root: {
        padding: '0 16px 16px',
        margin: 0,
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: grey[500],
        color: '#fff',
      },
    },
    MuiSvgIcon: {
      colorAction: {
        color: '#fff',
      },
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: PLACEHOLDER_GREY,
          opacity: 1,
        }
      }
    },
  },
};

function createButtonTheme(color: PaletteColorOptions) {
  return createMuiTheme({
    palette: {primary: color},
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: 'white',
        },
      },
    },
    imagePlaceholderBackground: ''
  });
}

export const redButtonTheme = createButtonTheme(red);
export const greenButtonTheme = createButtonTheme(green);

/**
 * The theme for the admin pages
 */
export const adminTheme = createMuiTheme(adminThemeOptions);
