import {StyleRulesCallback, Theme} from '@material-ui/core';
import {createStyles} from "@material-ui/styles";

export type MyDialogStyles =
  'titleIcon'
  | 'closeButton'
  | 'imageInput'
  | 'nextToImageInput'
  | 'itemQrCodeStyle'
  | 'noMarginTop';

export const myDialogStyles: StyleRulesCallback<Theme, {}, MyDialogStyles> = (theme: Theme) => createStyles({
  titleIcon: {
    float: 'left',
    fontSize: 40,
    marginRight: 8,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
  },
  imageInput: {
    float: 'left',
  },
  nextToImageInput: {
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
  },
  itemQrCodeStyle: {
    float: 'right',
    paddingLeft: theme.spacing(2),
  },
  noMarginTop: {
    marginTop: 0,
  },
});
