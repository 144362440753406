import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {createStyles, makeStyles, useTheme} from "@material-ui/styles";
import {ChatWindowHeader} from "./ChatWindowHeader";
import {ChatWindowContent} from "./ChatWindowContent";
import {grey} from "@material-ui/core/colors";
import {ChatWindowInput} from "./ChatWindowInput";
import Draggable from 'react-draggable';
import {getChatMessages, postChatMessage} from "../../../../api/chatApi";
import {fullPageable} from "../../../../query/pageable";
import {ChatMessage} from "../../../../api/chatTypes";
import Spinner from "../../../../spinner/Spinner";
import {CallStateContainer} from "../state/CallStateContainer";
import {Subscribe} from "unstated";
import {Theme, useMediaQuery} from "@material-ui/core";

export const CHAT_WINDOW_HEADER_HEIGHT = 52;
export const CHAT_WINDOW_INPUT_HEIGHT = 56;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    zIndex: 9001,
    bottom: 0,
    right: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('sm') + ' and (min-height: 300px)']: {
      bottom: 32,
      right: 32,
      width: 350,
      height: 600,
      maxHeight: 'calc(100vh - 64px)',
      borderRadius: 5,
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.2)',
      borderColor: grey[300],
    }
  },
}));

export function ChatWindow(props: {
  callId: number,
  newMessageHandlerRef: (onNewChatMessage: (message: ChatMessage) => void) => void,
  onClose(): void
}) {
  const callStateContainerRef = useRef(null as CallStateContainer | null);

  const [messages, setMessages] = useState(null as ChatMessage[] | null);
  useEffect(() => {
    getChatMessages(props.callId, fullPageable())
      .then(page => page.content)
      .then(setMessages);
  }, [props.callId]);

  useEffect(() => {
    props.newMessageHandlerRef(message => setMessages([...messages as ChatMessage[], message]));
  });

  const {root} = useStyles();
  const theme: Theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  async function handleMessageInput(text: string) {
    const chatMessage = await postChatMessage(props.callId, text);
    if (callStateContainerRef.current) {
      callStateContainerRef.current.broadcastChatMessage(chatMessage);
    }
    // setMessages([...messages as ChatMessage[], chatMessage]);
  }

  return (
    <Draggable position={isMobile ? {x: 0, y: 0} : undefined} disabled={isMobile} handle={'.chatHeader'}
               cancel={'.chatCloseButton'} bounds="#root">
      <div className={root}>
        <ChatWindowHeader onClose={props.onClose}/>

        {!messages && <Spinner/>}

        {messages && <ChatWindowContent messages={messages.sort((a, b) => a.id - b.id)}/>}
        {messages && <ChatWindowInput onInput={handleMessageInput}/>}

        <Subscribe to={[CallStateContainer]}>
          {(callStateContainer: CallStateContainer) => {
            callStateContainerRef.current = callStateContainer;
            return null;
          }}
        </Subscribe>

      </div>
    </Draggable>
  );
}
